import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import PasswordField from "../../../components/PasswordField";
import { faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { resetPasswordHandler } from "../../Store/slices/authSlice";
import { SIGNIN } from "../../../common/routeConstants";
import AuthWrapper from "../../../components/AuthWrapper";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  let { token } = useParams();

  //formik starts here
  const initialValues: any = {
    newPassword: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),


    confirmPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).oneOf([Yup.ref('newPassword')], 'Passwords must match')
  });


  const handleRegister = async (formValue: any, { resetForm }: any) => {
    const password = formValue?.newPassword
    try {
      const data = {
        token, password
      }
      await dispatch(resetPasswordHandler(data)).then((result: any) => {
        if (result?.payload?.statusCode === 200) {
          toast.success(result?.payload?.message, {
            position: 'top-right',
            autoClose: 2500,
          });
          setTimeout(() => {
            navigate(SIGNIN)
          }, 1500);
        }
        else if (result?.payload?.statusCode === 401) {
          toast.error(result?.payload?.message, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
        else if (result?.payload?.statusCode === 400) {
          toast.error(result?.payload?.message, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      }).catch((error: any) => {
        console.log(error)
      })
    } catch (error) {
      console.error(error, "error");
    }
  };
  return (
    <AuthWrapper title="Reset Password" icon={faUnlockKeyhole}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleRegister}
      >
        <Form className="vstack gap-4">
          <PasswordField name="newPassword" label="New Password" />
          <PasswordField name="confirmPassword" label="Confirm Password" />

          <div className="text-center mt-4">
            <button className="btn btn-primary rounded-4 px-4 py-2" type="submit">
              {t('common.setNewPassword')}
            </button>
          </div>
        </Form>
      </Formik>
    </AuthWrapper>
  );
}

export default ResetPassword
