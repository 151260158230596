import { Modal } from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import { DUMMY_THUMBNAIL_URL } from '../common/commonConstants';
import { useTranslation } from 'react-i18next';

const ThumbnailCarousalModal = ({ isOpen, onClose, thumbnails }: any) => {
  // single hooks starts here
  const { t } = useTranslation('common');
  // single hooks ends here

  return (
    <>
      <Modal show={isOpen} size="xl" centered>
        <Modal.Header>
          <h5 className="modal-title">
            {t('thumbnails.videoThumbnail')}
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose}
          >
          </button>
        </Modal.Header>
        <AliceCarousel mouseTracking touchTracking autoPlay autoPlayInterval={2000} disableDotsControls autoHeight autoWidth paddingLeft={5} paddingRight={5}
          keyboardNavigation={true}
        >
          {thumbnails.map((thumbnail: any) => {
            return (
              <div key={thumbnail.storageLink}>
                <img className="thumbnails" src={thumbnail?.storageLink} alt='' title="Video Thumbnails"
                  onError={(thumbnail: any) => {
                    thumbnail.target.src = DUMMY_THUMBNAIL_URL;
                  }} />
              </div>
            )
          })}
        </AliceCarousel>
      </Modal>
    </>
  );
};

export default ThumbnailCarousalModal;