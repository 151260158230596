import Card from 'react-bootstrap/Card';
import { Tab, Tabs } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import DataByDealer from './DataByDealer';
import DataByVideo from './DataByVideo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import { getUserDealersHandler } from '../Store/slices/dealerSlice';
import { TailSpin } from 'react-loader-spinner';

const ScoringDashboard = () => {
    const dispatch = useDispatch();

    //single hook start here
    const scoringData = useSelector((state: any) => state?.dashboard?.scoringData);
    //single hook ends here
    const { user } = useSelector((state: RootState) => state.auth);
    const userId = user?.user?.uuid ?? user?.uuid;
    const { selectedBrandId } = useSelector((state: RootState) => state.settings);
    const [key, setKey] = useState(sessionStorage.getItem("selectedTab") || "dealer");
    const [dealers, setDealers] = useState<any>([]);
    const [loader, setLoading] = useState<boolean>(false);

    useEffect(() => {
        sessionStorage.setItem("selectedTab", key);
    }, [key]);
    const getDealers = async () => {
        const dealerData = {
            userId: userId,
            brand: selectedBrandId
        }
        try {
            const result = await dispatch(getUserDealersHandler(dealerData));
            if (result?.type === 'getUserDealers/fulfilled') {
                const dealers = result?.payload?.data || [];
                const obj: any = { "uuid": '', "name": "ALL", "region": "ALL" }
                const updatedDealers = [...dealers, obj];
                updatedDealers.sort((a, b) => {
                    if (a.name === "ALL") return -1;
                    if (b.name === "ALL") return 1;
                    return a.name.localeCompare(b.name);
                });
                setDealers(updatedDealers);
            }
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getDealers();
    }, []);

    return (
        <div className='new-dasbhoard-wrap'>
            {loader && (
                <div className="loader-overlay">
                    <div className="loader-container">
                        <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                </div>
            )}
            <div className="row g-3">
                {[
                    { title: "Average Inspection Score", value: scoringData?.averageInspectionScore ?? "0" },
                    { title: "Average Total Score", value: scoringData?.averageScore ?? "0" },
                    { title: "Average Quality Score", value: scoringData?.averageQualityScore ?? "0" },
                ].map((item, index) => (
                    <div className="col-lg-4" key={index}>
                        <Card className="dashboard-card">
                            <Card.Body>
                                <div className="tile">{item.title}</div>
                                <div className="count">{item.value}</div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <div className="row mt-4 m-2">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k: any) => setKey(k)} className="mb-3">
                    <Tab eventKey="dealer" title="Data By Dealer">
                        {key == "dealer" && <DataByDealer selectedKey={key} dealers={dealers} userId={userId} selectedBrandId={selectedBrandId}/>}
                    </Tab>
                    <Tab eventKey="video" title="Data By Video">
                        {key == "video" && <DataByVideo selectedKey={key} dealers={dealers} userId={userId} selectedBrandId={selectedBrandId}/>}
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default ScoringDashboard;