export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password/:token'
export const DASHBOARD_GRAPH = '/dashboard/graph'
export const DASHBOARD = '/dashboard'
export const VIDEO_DETAIL = '/video-detail/:uuid'
export const ADMIN_DASHBOARD = '/admin/dashboard'
export const VIDEOS = '/videos'
export const SCRATCHDETECT = '/scratch-videos-detect'
export const ADMIN_SIGNIN = '/auth/signin'
export const USER_CREATE = '/admin/create-user'
export const USER_EDIT = '/admin/edit-user'
export const BULK_VIDEOS_UPLOAD = '/bulk-videos-upload'
export const CHANGE_PASSWORD = '/changepassword'
export const SELECT_BRANDS_DEALERS = '/manage/brands-dealers'
export const SELECT_BRAND_DEALERS = '/manage/brands-dealers/:uuid'
export const BRAND_DELAER_MAPPING = '/admin/map-brand-dealer'
export const SELECT_DEALER_BRANDS = '/manage/dealers-brands/:uuid'
export const USER_CREATE_EDIT = '/admin/create-user'
export const SHOW_USER_DETAILS = '/show-user-details/:uuid'
export const VIDEO_SCORING = '/video-scoring/quality-scoring'
export const TIREDETECT = '/tire-detect'
export const OAUTH = '/oauth'
export const WEBHOOKHISTORY = '/oauth/webhookHistory'
export const VIEW_BRAND_DEALERS = '/view_brand_dealers/:uuid'
export const DATA_BY_LOCATION = '/dashbaord/data-by-location'
export const DATA_BY_VIDEO = '/dashbaord/data-by-video'
export const INSCPECT_SCORING = '/video-scoring/inspect-scoring'