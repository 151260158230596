import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import DataTable from '../../components/DataTable';
import VideoUpload from '../../components/VideoUpload';
import { toast } from 'react-toastify';
import { submitVideoUrlsHandler, videoHandler } from '../Store/slices/videoSlice';
import VideoUrlModalComponent from '../../components/videoUrlModal';

const Videos = () => {
  //single hook starts here
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedDealerId, selectedBrandId } = useSelector((state: RootState) => state.settings);
  const userId = user?.user?.uuid ?? user?.uuid;
  //single hook ends here

  //useState starts here
  const [videoUrls, setVideoUrls] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  //useState ends here

  //function starts here
  const isValidUrl = (url: string) => {
    const videoUrlRegex = /^(https?:\/\/)?((www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|embed\/|v\/|.+\?v=)?[a-zA-Z0-9_-]{11}|vimeo\.com\/\d+|([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}\/.*\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv))$/i;
    return videoUrlRegex.test(url);
  };

  const handleCloseVideoModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    const urlsArray = videoUrls.split(',').map(url => url.trim());
    let urlfinalArr = []
    let newurlarr = urlsArray.sort((a, b) => { return (b.length - a.length) })
    for (let url of newurlarr) {
      if (url.length > 0) {
        urlfinalArr.push(url)
      }
    }
    const data = {
      userId: userId,
      urlsArray: urlfinalArr,
      uuid: selectedDealerId,
      brandId: selectedBrandId
    };
    await dispatch(submitVideoUrlsHandler(data)).then(async (res: any) => {
      if (res.type == "submitVideoUrls/fulfilled" && res.payload != "") {
        const data = {
          userid: userId,
          pageNumber: 1,
          uuid: selectedDealerId,
          brandId: selectedBrandId
        };
        await dispatch(videoHandler(data));
        toast.success(t('VideoProcess.videoUrlSuccess'), {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        toast.error(t('VideoProcess.videoUrlFail'), {
          position: "top-right",
          autoClose: 2000,
        });
      }

    }).catch((err: any) => {
      toast.error(t('VideoProcess.videoUrlFail'), {
        position: "top-right",
        autoClose: 2000,
      });
    });
    setVideoUrls("");
  };
  //functions end here

  //useEffect starts here
  useEffect(() => {
    if (videoUrls.trim() === "") {
      setError(false);
      setErrorMsg("");
      return;
    }

    const urlsArray = videoUrls.split(',').map(url => url.trim()).filter(url => url !== "");

    if (urlsArray.length > 10) {
      setError(true);
      setErrorMsg(t('VideoProcess.videoUrlCountValidation'));
    } else if (urlsArray.some(url => !isValidUrl(url))) {
      setError(true);
      setErrorMsg(t('VideoProcess.videoUrlInvalidValidation'));
    }

    else {
      setError(false);
      setErrorMsg("");
    }
  }, [videoUrls]);
  //useEffects ends here

  return (
    <>
      <div className='row gy-4 mb-5'>
        <div className="col-lg-6">
          <div className='upload-area'>
            <VideoUpload />
          </div>
        </div>
        <div className="col-lg-6">
          <form onSubmit={handleSubmit} className="submit-url-area">
            <div className='flex-fill'>
              <textarea
                id="video_urls"
                name="video_urls"
                placeholder={t("Dashboard.enterUrl")}
                className="form-control submit-url-textarea"
                rows={5}
                value={videoUrls}
                onChange={(e) => setVideoUrls(e.target.value)}
              />
              {error && <div className="error-message" style={{ color: 'red' }}>{errorMsg}</div>}
            </div>
            <div className='text-end'>
              <button type='submit' disabled={error || videoUrls.trim() == ""} className='btn btn-primary custom-btn'>Submit Urls</button>
            </div>
          </form>
        </div>
      </div>
      <div className="table-container">
        <DataTable />
      </div>
      <VideoUrlModalComponent
        isOpen={showModal}
        onClose={handleCloseVideoModal}
        modalHeading={"Video Urls"}
        onSubmit={handleConfirm}
      >
        {videoUrls.split(',').map(url => url.trim()).filter(url => url !== "")}
      </VideoUrlModalComponent>
    </>
  );
};

export default Videos;
