import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../pages/Store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ShowUserDetailsModalHandler } from '../../pages/Store/slices/modalSlice';
import { useEffect, useState } from 'react'
import { editUserByIdHandler, getUserDetailsByUuidHandler } from '../../pages/Store/slices/userSlice';
import { ICreateUser, Language } from '../../types/user.type';
import { languages } from '../../common/common';
import { useNavigate } from 'react-router-dom';
import { VIEW_BRAND_DEALERS } from '../../common/routeConstants';

const UserViewModal = () => {
  //useSelector starts here
  const { userDetailsModal } = useSelector((state: RootState) => state.modals);
  const { editUserId } = useSelector((state: RootState) => state.users);
  //useSelector ends here

  //single hooks starts here
  const dispatch: ThunkDispatch<{}, {}, any> = useDispatch();
  const navigate = useNavigate();
  //single hooks ends here

  //useStates starts here
  const [editUserData, setEditUserData] = useState<ICreateUser>();
  //useStates ends here

  //functions starts here
  const getUserByUuids = async () => {
    await dispatch(getUserDetailsByUuidHandler(editUserId)).then((response: any) => {
      if (response.type === 'getUserDetailsByUuid/fulfilled') {
        const { fName, lName, email, lang, contact, status } = response?.payload?.data?.data;
        const language = languages.find((item: Language) => item.value === lang)?.label || ''
        const initialValues: ICreateUser = {
          firstName: fName,
          lastName: lName,
          email: email,
          status: status,
          contact: contact,
          lang: language,
        };
        setEditUserData(initialValues)
      }
    })
  }

  const clearForm = () => {
    dispatch(ShowUserDetailsModalHandler(false))
    dispatch(editUserByIdHandler(''))
  }
  //functions ends here

  //useEffect starts here
  useEffect(() => {
    if (editUserId !== '') {
      getUserByUuids()
    }
  }, [editUserId]);
  //useEffect ends here

  return (
    <>
      <Modal centered backdrop="static" show={userDetailsModal} onHide={clearForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className='fw-semibold modal-title'>{'User Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='table table-borderless table-middle user-detial-table'>
            <tbody className='user-details-tbody'>
              <tr>
                <td className='fw-bold' width={'40%'}>FIRST NAME</td>
                <td className='hstack gap-3'>: <span>{editUserData?.firstName}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>LAST NAME</td>
                <td className='hstack gap-3'>: <span>{editUserData?.lastName ?? '-'}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>EMAIL</td>
                <td className='hstack gap-3'>: <span>{editUserData?.email}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>CONTACT</td>
                <td className='hstack gap-3'>: <span>{editUserData?.contact ? editUserData?.contact : "-"}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>STATUS</td>
                <td className='hstack gap-3'>: <span>{editUserData?.status}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>LANGUAGE</td>
                <td className='hstack gap-3'>: <span>{editUserData?.lang}</span></td>
              </tr>
              <tr>
                <td className='fw-bold'>BRANDS </td>
                <td className='hstack gap-3'>:
                  <button className="btn btn-primary manage-brand-btn" onClick={() => {
                    const path = VIEW_BRAND_DEALERS.replace(':uuid', editUserId);
                    navigate(path);
                    dispatch(ShowUserDetailsModalHandler(false));

                  }}>View Brands and Dealers</button></td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserViewModal