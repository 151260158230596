import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SettingsState {
    selectedBrandId: string
    selectedDealerId: string
}

const initialState: SettingsState = {
    selectedBrandId: '',
    selectedDealerId: ''
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSelectedBrandDealerHandler: (state, { payload }: PayloadAction<{ brandUuid: SettingsState['selectedBrandId'], dealerUuid: SettingsState['selectedDealerId'] }>) => {
            state.selectedBrandId = payload.brandUuid;
            state.selectedDealerId = payload.dealerUuid;
        },
        userSelectedBrandHandler: (state, { payload }: PayloadAction<string>) => {
            state.selectedBrandId = payload;
        },
        userSelectedDealerHandler: (state, { payload }: PayloadAction<string>) => {
            state.selectedDealerId = payload;
        },
    }
})
export const { updateSelectedBrandDealerHandler, userSelectedBrandHandler, userSelectedDealerHandler } = settingsSlice.actions
export default settingsSlice.reducer