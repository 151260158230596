import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {  getDataByDealer, getDataByVideo, getVideoDetails } from '../../../services/dashboard.service';
import { GET_DATA_BY_DEALER, GET_DATA_BY_VIDEO } from '../actions';

interface UserState {
    loading: boolean;
    error: string | null;
    totalUsersRecord: number
    totalPage: number;
    scoringData: {
        averageInspectionScore: number;
        averageQualityScore: number;
        averageScore: number;
    };
}

const initialState: UserState = {
    loading: false,
    error: null,
    totalUsersRecord: 0,
    totalPage: 0,
    scoringData: {
        averageInspectionScore: 0,
        averageQualityScore: 0,
        averageScore: 0,
    },
};

export const getDataByVideoHandler: any = createAsyncThunk(GET_DATA_BY_VIDEO, (data: any, { rejectWithValue }) =>
    getDataByVideo(data).catch((error) => error && rejectWithValue(error))
)

export const getDataByDealerHandler: any = createAsyncThunk(GET_DATA_BY_DEALER, (data: any, { rejectWithValue }) =>
    getDataByDealer(data).catch((error) => error && rejectWithValue(error))
)

export const getVideoDetailsHandler: any = createAsyncThunk(GET_DATA_BY_VIDEO, (uuid: any, { rejectWithValue }) =>
    getVideoDetails(uuid).catch((error) => error && rejectWithValue(error))
)

const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        setScoringData: (state, action: PayloadAction<UserState['scoringData']>) => {
            state.scoringData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDataByDealerHandler.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDataByDealerHandler.fulfilled, (state, action) => {
                state.loading = false;
                state.scoringData = {
                    averageInspectionScore: action.payload.averageInspectionScore,
                    averageQualityScore: action.payload.averageQualityScore,
                    averageScore: action.payload.averageScore,
                };
            })
            .addCase(getDataByDealerHandler.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
})

export const { setScoringData } = dashboardSlice.actions;
export default dashboardSlice.reducer;