import React, { useState, useEffect } from "react";
import ModalComponent from "./Modal";
import TireSideWall from "../assets/images/tireSideWall.svg";
import TireTread from "../assets/images/tireTread.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateManualTreadDepthHandler } from "../pages/Store/slices/tireScanSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import VideoModal from "./VideoModal";
import ThumbnailImageView from "./ThumbnailImageView";
import { useTranslation } from "react-i18next";

//  Tire details props start here
interface TireDetails {
  detectName: string;
  detectModel: string;
  detectSize: string;
  detectManufacture: string;
  storageLink: string;
  detectTreadDepthMin: string;
  detectTreadDepthAvg: string;
  detectTreadDepthMax: string;
  manualTreadDepth?: string;
  status: string;
}
//  Tire details props ends here

//  Tire tread scan modal props start here
interface TireTreadScanModalProps {
  showTreadModal: boolean;
  setShowTreadModal: (value: boolean) => void;
  loading: boolean;
  apiResponse: Record<string, Record<string, TireDetails[]>>;
  uuid: string;
  fetchTireScanData: () => {}
}
//  Tire tread scan modal props ends here
const TireTreadScanModal: React.FC<TireTreadScanModalProps> = ({
  showTreadModal,
  setShowTreadModal,
  loading,
  apiResponse,
  uuid,
  fetchTireScanData
}) => {
  // useState starts here
  const dispatch: ThunkDispatch<object, object, any> = useDispatch();
  const { t } = useTranslation("common");
  const [currentTab, setCurrentTab] = useState<any>("SideWall");
  const [manualTreadDepthValues, setManualTreadDepthValues] = useState<any>({});
  const [isEditing, setIsEditing] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [openVideoPopup, setOpenVideoPopup] = useState<boolean>(false)
  const [storageLink, setStorageLink] = useState<any>("");
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [viewImageLink, setViewImageLink] = useState<any>('');
  // useState ends here

  // Position Map start here
  const positionMap: any = {
    FL: "Left Front Tire",
    FR: "Right Front Tire",
    RL: "Left Rear Tire",
    RR: "Right Rear Tire",
  };
  // Position Map ends here

  //functions start here
  function checkMediaType(url: any) {
    const extension = url.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'webm'];

    if (imageExtensions.includes(extension)) {
      return 'Image';
    } else if (videoExtensions.includes(extension)) {
      return 'Video';
    } else {
      return 'Unknown type';
    }
  }

  const handleCloseModal = () => {
    setShowTreadModal(false);
    setErrors({});
    setIsEditing({});
  };

  const handleEditClick = (position: string) => {
    setIsEditing((prev: any) => ({
      ...prev,
      [position]: true,
    }));
  };

  const handleSaveClick = async (position: string) => {
    const value = manualTreadDepthValues[position];
    if (!/^(?!0+(\.0+)?$)\d+(\.\d+)?$/.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [position]: true,
      }));
      return;
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [position]: false,
    }));
    const data: any = {
      scanId: uuid,
      tirePosition: position,
      manualDepthTread: Number(manualTreadDepthValues[position]),
    };
    const result: any = await dispatch(updateManualTreadDepthHandler(data));
    if (result.type === "updatemanualtreaddepth/fulfilled" && result.payload.statusCode == 200) {
      fetchTireScanData()
      setIsEditing((prev: any) => ({
        ...prev,
        [position]: false,
      }));
      if (result.payload.statusCode == 200) {
        toast.success(result.payload.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } else {
      setManualTreadDepthValues((prevValues: any) => ({
        ...prevValues,
        [position]: null,
      }));

      toast.error(result.payload.message, {
        position: "top-right",
        autoClose: 2000,
      });
      setIsEditing((prev: any) => ({
        ...prev,
        [position]: false,
      }));
    }
  };

  const handleManualTreadDepthChange = (position: string, value: string) => {
    setManualTreadDepthValues((prev: any) => ({
      ...prev,
      [position]: value,
    }));
  };

  const renderTireDetails = () => {
    return Object.keys(positionMap).map((position, index) => {
      const details = apiResponse[position]?.[currentTab]?.[0] || {};
      const imageLink = details.storageLink;
      const type = currentTab == "SideWall" && details.storageLink && checkMediaType(details.storageLink);
      const status = details.status || "NOT SCANNED";
      const minDepth = details.detectTreadDepthMin || "N/A";
      const avgDepth: any = details.detectTreadDepthAvg || "N/A";
      const maxDepth = details.detectTreadDepthMax || "N/A";
      const manualTreadDepth =
        manualTreadDepthValues[position] ?? details.manualTreadDepth ?? "";

      let message = '';
      let messageClass = '';
      let borderColor = '#d5cbcb';

      if (avgDepth <= 1.6) {
        message = t('tireScan.tireUnsafeValidation')
        messageClass = 'message-unsafe';
        borderColor = '#8B0000';
      } else if (avgDepth > 1.6 && avgDepth <= 3.2) {
        message = t('tireScan.tireCriticalCondition');
        messageClass = 'message-critical';
        borderColor = '#FF0000';
      } else if (avgDepth > 3.2 && avgDepth <= 4.8) {
        message = t('tireScan.tireWornDown');
        messageClass = 'message-worn';
        borderColor = '#FFA500';
      } else if (avgDepth > 4.8 && avgDepth <= 6.0) {
        message = t('tireScan.tireUsable');
        messageClass = 'message-usable';
        borderColor = '#f5d207';
      } else if (avgDepth > 6.0) {
        message = t('tireScan.tireGoodCondition');
        messageClass = 'message-good';
        borderColor = '#008000';
      }
      let cardStyle = {
        border: `1px solid ${borderColor}`,
      };

      const handleCloseImageModal = () => {
        setShowImageModal(false);
      };
      const handleOpenImageModal = async (imageLink: any) => {
        setShowImageModal(true);
        setViewImageLink(imageLink)
      };
      return (
        <div key={index} className="tire-card" style={cardStyle}>
          <div className={currentTab == "Tread" ? "card-header tread-video" : "card-header tread-video"}>
            <div className="left-content-tread">
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none" className={messageClass}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.78095 0.8C1.69234 0.8 0.8 1.69234 0.8 2.78095V11.4119C0.8 12.5005 1.69234 13.3929 2.78095 13.3929H5.75714C6.84575 13.3929 7.7381 12.5005 7.7381 11.4119V2.63349L7.73777 2.62129C7.73736 2.60997 7.73649 2.5914 7.7346 2.56662C7.73081 2.51692 7.72302 2.44311 7.70707 2.35338C7.67485 2.17216 7.61113 1.93604 7.48753 1.70431C7.36504 1.47464 7.18615 1.2533 6.92163 1.08797C6.6584 0.923454 6.28747 0.8 5.75714 0.8H2.78095L2.78096 0H5.75714C6.41729 0 6.93922 0.155564 7.34563 0.409573C7.75075 0.662774 8.01829 0.999472 8.19342 1.32784C8.36744 1.65413 8.45253 1.97605 8.49472 2.21336C8.51597 2.3329 8.52677 2.43347 8.53229 2.50584C8.53505 2.5421 8.5365 2.57148 8.53726 2.59286L8.53796 2.61886L8.53807 2.62714L8.53809 2.63006L8.53809 2.6312C8.53809 2.6312 8.53809 2.63214 8.13809 2.63214L8.53809 2.6312L8.53809 11.4119C8.53809 12.9423 7.28758 14.1929 5.75714 14.1929H2.78095C1.25051 14.1929 0 12.9423 0 11.4119V2.78095C0 1.25051 1.25052 0 2.78096 0L2.78095 0.8Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75718 0.238281C5.97809 0.238281 6.15718 0.417367 6.15718 0.638281V2.30495C6.15718 2.52586 5.97809 2.70495 5.75718 2.70495C5.53626 2.70495 5.35718 2.52586 5.35718 2.30495V0.638281C5.35718 0.417367 5.53626 0.238281 5.75718 0.238281Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07847 0.238281C3.29938 0.238281 3.47847 0.417367 3.47847 0.638281V2.30495C3.47847 2.52586 3.29938 2.70495 3.07847 2.70495C2.85755 2.70495 2.67847 2.52586 2.67847 2.30495V0.638281C2.67847 0.417367 2.85755 0.238281 3.07847 0.238281Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75718 3.69141C5.97809 3.69141 6.15718 3.87049 6.15718 4.09141V4.80569C6.15718 5.02661 5.97809 5.20569 5.75718 5.20569C5.53626 5.20569 5.35718 5.02661 5.35718 4.80569V4.09141C5.35718 3.87049 5.53626 3.69141 5.75718 3.69141Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07847 3.69141C3.29938 3.69141 3.47847 3.87049 3.47847 4.09141V4.80569C3.47847 5.02661 3.29938 5.20569 3.07847 5.20569C2.85755 5.20569 2.67847 5.02661 2.67847 4.80569V4.09141C2.67847 3.87049 2.85755 3.69141 3.07847 3.69141Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75718 6.19141C5.97809 6.19141 6.15718 6.37049 6.15718 6.59141V7.36522C6.15718 7.58613 5.97809 7.76522 5.75718 7.76522C5.53626 7.76522 5.35718 7.58613 5.35718 7.36522V6.59141C5.35718 6.37049 5.53626 6.19141 5.75718 6.19141Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07847 6.19141C3.29938 6.19141 3.47847 6.37049 3.47847 6.59141V7.36522C3.47847 7.58613 3.29938 7.76522 3.07847 7.76522C2.85755 7.76522 2.67847 7.58613 2.67847 7.36522V6.59141C2.67847 6.37049 2.85755 6.19141 3.07847 6.19141Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75718 8.75C5.97809 8.75 6.15718 8.92909 6.15718 9.15V9.92381C6.15718 10.1447 5.97809 10.3238 5.75718 10.3238C5.53626 10.3238 5.35718 10.1447 5.35718 9.92381V9.15C5.35718 8.92909 5.53626 8.75 5.75718 8.75Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07847 8.75C3.29938 8.75 3.47847 8.92909 3.47847 9.15V9.92381C3.47847 10.1447 3.29938 10.3238 3.07847 10.3238C2.85755 10.3238 2.67847 10.1447 2.67847 9.92381V9.15C2.67847 8.92909 2.85755 8.75 3.07847 8.75Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75718 11.3086C5.97809 11.3086 6.15718 11.4877 6.15718 11.7086V13.3157C6.15718 13.5367 5.97809 13.7157 5.75718 13.7157C5.53626 13.7157 5.35718 13.5367 5.35718 13.3157V11.7086C5.35718 11.4877 5.53626 11.3086 5.75718 11.3086Z" fill="#E1B31B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07847 11.3086C3.29938 11.3086 3.47847 11.4877 3.47847 11.7086V13.3157C3.47847 13.5367 3.29938 13.7157 3.07847 13.7157C2.85755 13.7157 2.67847 13.5367 2.67847 13.3157V11.7086C2.67847 11.4877 2.85755 11.3086 3.07847 11.3086Z" fill="#E1B31B" />
              </svg>
              <p className="position-label">{positionMap[position]}</p>
            </div>
            {currentTab === "Tread" && imageLink && (
              <div className="play-now-button tread-video">
                <button className="tread-video" onClick={() => { setStorageLink(details.storageLink); setOpenVideoPopup(true) }}>
                  {t('tireScan.playNow')}
                </button>
              </div>
            )}
            {currentTab == "SideWall" && imageLink && type == "Video" &&
              <div className="play-now-button tread-video">
                <button className="tread-video" onClick={() => { setStorageLink(details.storageLink); setOpenVideoPopup(true) }}>
                  {t('tireScan.playNow')}
                </button>
              </div>}
          </div>
          <div className="card-body">
            {currentTab == "SideWall" && imageLink && type == "Image" &&
              <img src={imageLink} alt="thumbnail-image" className="thumbnail-image" onClick={() => { handleOpenImageModal(imageLink); }} />
            }
            <p className="status">{  t('common.status')}: {status}</p>
            <p className="details">
              {currentTab === "Tread" ? (
                <>
                  <div>{t('tireScan.minDepth')}: {minDepth}</div>
                  <div>{t('tireScan.avgDepth')}: {avgDepth}</div>
                  <div>{t('tireScan.maxDepth')}: {maxDepth}</div>
                  <div className="manual-tread-depth">
                    <span>{t('tireScan.manualTreadDepth')}: </span>
                    {isEditing[position] ? (
                      <>
                        <input
                          type="text"
                          value={manualTreadDepth}
                          onChange={(e) =>
                            handleManualTreadDepthChange(position, e.target.value)
                          }
                          className="manual-depth-input"
                          placeholder="Enter manual depth"
                        />
                      </>
                    ) : (
                      <span className="depth-value">{manualTreadDepth || "N/A"}</span>
                    )}
                    {
                      details.status &&
                      <button
                        className="edit-button"
                        onClick={() =>
                          isEditing[position]
                            ? handleSaveClick(position)
                            : handleEditClick(position)
                        }
                      >
                        <FontAwesomeIcon
                          icon={isEditing[position] ? faSave : faEdit}
                          className="edit-icon"
                        />
                      </button>
                    }

                  </div>
                  {errors[position] && (
                    <p className="text-danger">{t('tireScan.enterValidNumber')}</p>
                  )}
                  {message && (
                    <div className={`status-message`}>
                      {message}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>{t('tireScan.name')}: {details.detectName || "N/A"}</div>
                  <div>{t('tireScan.tireModel')}: {details.detectModel || "N/A"}</div>
                  <div>{t('tireScan.tireSize')}: {details.detectSize || "N/A"}</div>
                  <div>{t('tireScan.manufactureDate')}: {details.detectManufacture || "N/A"}</div>
                </>
              )}
            </p>
          </div>
          <ThumbnailImageView
            isOpen={showImageModal}
            onClose={handleCloseImageModal}
            image={viewImageLink}
          />
        </div>
      );
    });
  };
  //functions ends here

  //useEffect start here
  useEffect(() => {
    if (showTreadModal) {
      setCurrentTab("SideWall");
      setIsDataLoaded(false);
      const timer = setTimeout(() => {
        setIsDataLoaded(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [showTreadModal]);

  useEffect(() => {
    if (showTreadModal && apiResponse) {
      const initialValues = Object.keys(positionMap).reduce((acc: any, position: string) => {
        acc[position] = apiResponse[position]?.[currentTab]?.[0]?.manualTreadDepth ?? "";
        return acc;
      }, {});
      setManualTreadDepthValues(initialValues);
    }
  }, [showTreadModal, apiResponse, currentTab]);
  //useEffect ends here

  return (
    <>
      {
        openVideoPopup &&
        <VideoModal isOpen={openVideoPopup} setOpenVideoPopup={setOpenVideoPopup} videoSrc={storageLink} type={currentTab} />
      }
      <ModalComponent
        isOpen={showTreadModal}
        onClose={handleCloseModal}
        modalHeading="Tire Scan Details"
        size="lg"
      >
        <div className="tab-buttons mt-3">
          <button
            className={`btnTire ${currentTab === "SideWall" ? "active" : ""}`}
            onClick={() => setCurrentTab("SideWall")}
          >
            <img
              src={TireSideWall}
              className={currentTab === "SideWall" ? "active" : ""}
              alt="SideWall Icon"
            />
            {t('tireScan.SideWall')}
          </button>
          <button
            className={`btnTire ${currentTab === "Tread" ? "active" : ""}`}
            onClick={() => setCurrentTab("Tread")}
          >
            <img
              src={TireTread}
              className={currentTab === "Tread" ? "active" : ""}
              alt="Tread Icon"
            />
            {t('tireScan.Tread')}
          </button>
        </div>
        {loading || !isDataLoaded ? (
          <p className="text-center">Loading...</p>
        ) : (
          <div className="tire-details-grid">{renderTireDetails()}</div>
        )}
      </ModalComponent>
    </>
  );
};

export default TireTreadScanModal;