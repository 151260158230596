import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReactPaginate from "react-paginate";
import { TailSpin } from "react-loader-spinner";
import { getDataByDealerHandler, setScoringData } from "../Store/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/store";
import Previous from "../../assets/images/previous-icon.svg";
import Next from "../../assets/images/next.svg";
import { Autocomplete, FormControl, TextField } from '@mui/material';

const DataByLocation = ({ selectedKey, dealers, userId, selectedBrandId }: any) => {
    //single hook starts here
    const dispatch = useDispatch();
    const limit = 10;
    const paginationSize = 10;
    //single hook ends here
    //useState starts here
    const [data, setData] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<any>(1);
    const [totalRecords, setTotalRecords] = useState<any>(0);
    const [loader, setLoading] = useState<any>(false);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [selectedDealer, setSelectedDealer]: any = useState({ uuid: '', name: 'ALL' });
    const [selectedRegion, setSelectedRegion]: any = useState({ uuid: '', region: 'ALL' });

    const columns = [
        { key: "dealerName", label: "Dealer" },
        { key: "dealerCode", label: "Dealer Code" },
        { key: "dealerRegion", label: "Region" },
        { key: "totalVideos", label: "Videos" },
        { key: "inspectionScore", label: "Inspection Score" },
        { key: "qualityScore", label: "Quality Score" },
        { key: "car", label: "% Detected Car", isPercentage: true },
        { key: "air_filters", label: "% Detected Air Filters", isPercentage: true },
        { key: "brake_pad_gauge", label: "% Detected Brake Pad Gauge", isPercentage: true },
        { key: "brakes", label: "% Detected Brakes", isPercentage: true },
        { key: "indicators", label: "% Detected Indicators", isPercentage: true },
        { key: "license_plate", label: "% Detected License Plate", isPercentage: true },
        { key: "tires", label: "% Detected Tire", isPercentage: true },
        { key: "wiper_blades", label: "% Detected Wiper Blades", isPercentage: true },
        { key: "tire_gauge", label: "% Detected Tire Gauge", isPercentage: true },
        { key: "dirty_air_filters", label: "% Detected Dirty Air Filters", isPercentage: true },
        { key: "car_interior", label: "% Detected Car Interior", isPercentage: true },
        { key: "mentioned_battery", label: "% Mentioned Battery", isPercentage: true },
        { key: "mentioned_brake", label: "% Mentioned Brake", isPercentage: true },
        { key: "mentioned_filter", label: "% Mentioned Filter", isPercentage: true },
        { key: "mentioned_indicator", label: "% Mentioned Indicator", isPercentage: true },
        { key: "mentioned_license_plate", label: "% Mentioned License Plate", isPercentage: true },
        { key: "mentioned_tire", label: "% Mentioned Tire", isPercentage: true },
        { key: "mentioned_wiper", label: "% Mentioned Wiper", isPercentage: true },
        { key: "mentioned_car", label: "% Mentioned Car", isPercentage: true },
        { key: "mentioned_fluids", label: "% Mentioned Fluids", isPercentage: true },
        { key: "mentioned_air_filter", label: "% Mentioned Air Filter", isPercentage: true },
        { key: "mentioned_oil_filter", label: "% Mentioned Oil Filter", isPercentage: true },
        { key: "audioMuffled", label: "% Audio Muffled", isPercentage: true },
        { key: "profanity", label: "% Profanity", isPercentage: true },
        { key: "fps", label: "FPS" },
        { key: "length", label: "Video Length" },
        { key: "wordsPerMinute", label: "Words Per Minute" },
        { key: "voiceVolume", label: "Average Volume" },
        { key: "loudVoice", label: "Peak Volume" }
    ];

    //for loop to get unique regions 
    const uniqueRegions: any = [];
    const regionSet: any = {};
    for (const dealer of dealers) {
        if (!regionSet[dealer?.region]) {
            regionSet[dealer?.region] = true;
            uniqueRegions.push({ region: dealer.region });
        }
    }
    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        setPageNumber(newPageNumber);
    };

    const getDataByDealer = async () => {
        setLoading(true);
        try {
            const data = {
                userUuid: userId,
                brandUuid: selectedBrandId,
                dealerUuid: selectedDealer?.uuid ? selectedDealer.uuid : '',
                region: selectedRegion == null || selectedRegion.region == 'ALL' ? '' : selectedRegion.region,
                pageNumber: pageNumber || 1,
                limit
            };
            const result = await dispatch(getDataByDealerHandler(data));

            if (result.type === 'getDataByDealer/fulfilled') {
                const { data } = result.payload;
                dispatch(setScoringData({
                    averageInspectionScore: data.averageInspectionScore,
                    averageQualityScore: data.averageQualityScore,
                    averageScore: data.averageScore,
                }));
                setData(data.data);
                setTotalRecords(data.totalItems);
                setIsLastPage(pageNumber === data.totalItems);
                setIsFirstPage(pageNumber === 1);
                setLoading(false);
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };
    //Functions ends here

    //useEffects starts here
    useEffect(() => {
        if (selectedBrandId && selectedDealer !== undefined && selectedRegion !== undefined) {
            getDataByDealer();
        }
    }, [selectedBrandId, selectedDealer?.uuid, selectedRegion?.region, pageNumber]);
    //useEffect ends here

    useEffect(() => {
        setPageNumber(1);
        setSelectedRegion({ uuid: '', region: 'ALL' })
        setSelectedDealer({ uuid: '', name: 'ALL' })
    }, [selectedKey, selectedBrandId])
    return (
        <>
            <div className='row'>
                {loader && (
                    <div className="loader-overlay">
                        <div className="loader-container">
                            <TailSpin color="#00BFFF" height={80} width={80} />
                        </div>
                    </div>
                )}
                <div className="col-lg-2 mb-4">
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            options={dealers}
                            getOptionLabel={(option: any) => option.name || ""}
                            value={selectedDealer}
                            onChange={(event, newValue) => { setSelectedDealer(newValue); setPageNumber(1) }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Dealer" variant="outlined" />
                            )}
                        />
                    </FormControl>
                </div>
                <div className='col-lg-2 mb-4'>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            options={uniqueRegions}
                            getOptionLabel={(option: any) => option.region || ""}
                            value={selectedRegion}
                            onChange={(event, newValue) => { setSelectedRegion(newValue); setPageNumber(1) }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Region" variant="outlined" />
                            )}
                        />
                    </FormControl>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className="table-container">
                        <TableContainer component={Paper} className="custom-table mb-3">
                            <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((col) => (

                                            <TableCell
                                                key={col.key}
                                                sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }}
                                                className={["dealerName", "dealerCode", "dealerRegion"].includes(col.key) ? "text-left" : "text-center"}
                                            >
                                                {col.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.length > 0 ? (
                                        data?.map((item: any, index: number) => (
                                            <TableRow key={index} className="mb-4">
                                                {columns.map((col) => (
                                                    <TableCell key={col.key} sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                        {col.isPercentage ? `${`${item[col.key]}%` || 'N/A'}` : item[col.key] || 'N/A'}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={columns.length} align="center">
                                                No records found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="d-flex align-items-center justify-content-end">
                            {data && data?.length > 0 &&
                                <ReactPaginate
                                    pageCount={Math.ceil(totalRecords / paginationSize)}
                                    previousLabel={<img src={Previous} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                                    nextLabel={<img src={Next} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                                    onPageChange={({ selected }) => onHandleFetchMore(selected)}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DataByLocation;