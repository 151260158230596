// DataTable.js
import { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import { RootState } from "../pages/Store/store";
import { addVideoCustomerIdHandler, deleteVideoHandler, getTranscriptionTextHandler, getVideoDetailsHandler, retryVideoAnalysisHandler, videoHandler } from "../pages/Store/slices/videoSlice";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayerModal from "./VideoPlayerModal";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ThumbnailCarousalModal from "./ThumbnailCarousalModal";
import { getThumbnailsHandler } from "../pages/Store/slices/thumbnailsSlice";
import { DELETE_VIDEO, DUMMY_THUMBNAIL_URL, FAILED, GET_THUMBNAILS, GET_VIDEO_TRANSCRIPTION, SUCCESSFUL } from "../common/commonConstants";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProgressBar from "@ramonak/react-progress-bar";
import moment from "moment";
import { TbRotateClockwise2 } from 'react-icons/tb';
import socket from '../socket/socket';
import { WiTime8 } from "react-icons/wi";
import { CgTimelapse } from "react-icons/cg";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { Rating } from 'react-simple-star-rating';
import { faArrowLeft, faArrowRight, faEdit, faEye, faRotateForward, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoTranscriptionTextModal from "./TranscriptionTextModal";
import DeleteDialogBox from "./VideoDeleteModal";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { VIDEO_DETAIL } from "../common/routeConstants";
interface PageData {
  [pageNumber: number]: any[];
}

const DataTable = ({ trigger }: any) => {
  //single hooks starts here  
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const licensePlateDisplay = process.env.REACT_APP_LICENSE_PLATE;
  //single hooks ends here  

  //useSelectors starts here
  const { user } = useSelector((state: RootState) => state.auth);
  const { videoData, paginationData } = useSelector((state: RootState) => state.video);
  const { selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.settings);
  const userId = user?.user?.uuid ?? user?.uuid;
  //useSelectors ends here

  //useStates starts here
  const [videos, setVideos] = useState<any>([]);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
  const [paginationSize, setPaginationSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(paginationData?.currentPage);
  const [showThumbnailModal, setShowThumbnailModal] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);
  const [transcriptionTextTitle, setTranscriptionTextTitle] = useState("");
  const [transcriptionText, setTranscriptionText] = useState('');
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [showTranscriptionTextModal, setShowTranscriptionTextModal] = useState(false);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<PageData>({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', field: '', value: 'N/A' });
  const [isTouched, setIsTouched] = useState(false);
  //useStates ends here

  //functions starts here
  const handleEditClick = (title: any, field: any, value: any) => {
    setIsTouched(false);
    setModalData({ title, field, value });
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setModalData({ title: '', field: '', value: 'N/A' });
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const getVideoData = async (pageNumber = 1, dealerUuid: any) => {
    const data = {
      userid: userId,
      pageNumber,
      uuid: dealerUuid,
      brandId: selectedBrandId,
    };
    await dispatch(videoHandler(data))
      .then((res: any) => {
        const videoData = res?.payload?.data;
        setVideos(videoData);
        updateData(pageNumber, videoData);
        setTotalRecords(res?.payload?.totalItems);
        setCurrentPage(0);
        setPageNumber(pageNumber)
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  const handleViewDetails = async (video: any) => {
    await dispatch(getVideoDetailsHandler(video)).then((res: any) => {
      setSelectedVideo(res.payload);
    }).catch((err: any) => {
      console.log(err, "err");
    })
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenVideoModal = async (video: any) => {
    try {
      await dispatch(getVideoDetailsHandler(video)).then((res: any) => {
        if (res.payload?.videoDetail?.videoDisplay === false) {
          toast.error(t('validation.videoNotFound'), {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          setSelectedVideo(res.payload);
          setShowVideoModal(true);
        }
      }).catch((err: any) => {
        console.log("err", err);
      })
    } catch (error) {
      toast.error(t('VideoProcess.failedToGenerateVideoUrl'), {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const onHandleFetchMore = (selected: any) => {
    const newPageNumber = selected + 1;
    if (videos) {
      setPageNumber(newPageNumber)
    }
    getVideoData(newPageNumber, selectedDealerId);
    setPageNumber(newPageNumber)
    setCurrentPage(newPageNumber)
  };

  const handleThumbnailClick = async (uuid: string) => {
    await dispatch(getThumbnailsHandler(uuid)).then((res: any) => {
      if (res.type == GET_THUMBNAILS) {
        if (res.payload.data.rows) {
          setVideoThumbnails(res?.payload?.data?.rows);
          setShowThumbnailModal(true);
        } else {
          toast.warning(t('thumbnails.noThumbnails'), {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }
    }).catch((err: any) => {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 2000,
      });
    });
  };

  const handleRetry = async (uuid: string) => {
    await dispatch(retryVideoAnalysisHandler(uuid)).then(async (res: any) => {
      toast.success(t('VideoProcess.videoProcessing'), {
        position: "top-right",
        autoClose: 2000,
      });
      const data = {
        userid: userId,
        pageNumber: 1,
        uuid: selectedDealerId,
        brandId: selectedBrandId
      };

      await dispatch(videoHandler(data))
        .then((res: any) => {
          const videoData = res?.payload?.data;
          setVideos(videoData);
          setTotalRecords(res?.payload?.totalItems);
          updateData(pageNumber, videoData);
          setCurrentPage(0);
          setPageNumber(1);
        })
        .catch((err: any) => {
          console.log("err", err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 2000,
          });
        });
    }).catch((err: any) => {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 2000,
      });
    });
  }

  const toTitleCase = (str: string) => {
    if (!str) return '';
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  const handleReadMore = async (uuid: string, view: string) => {
    dispatch(getTranscriptionTextHandler({ uuid, view })).then((res: any) => {
      if (res.type == GET_VIDEO_TRANSCRIPTION) {
        setIsExpanded(true);
        setShowTranscriptionTextModal(true);
        const title = ('summary' === view) ? t('constants.summary') : t('constants.transcriptionText');
        setTranscriptionTextTitle(title);
        setTranscriptionText(res.payload);
      }
    });
  };

  const handleDeleteVideo = async (uuid: string) => {
    setShowVideoDeleteModal(true);
    setDeleteVideoId(uuid);
  }

  const deleteConfirmation = (uuid: string) => {
    const data = {
      uuid, userId: userId
    }
    dispatch(deleteVideoHandler(data)).then(async (deleteRes: any) => {
      if (deleteRes.payload.statusCode == 200) {
        const data = {
          userid: userId,
          pageNumber,
          uuid: selectedDealerId,
          brandId: selectedBrandId
        };
        let pageNo = pageNumber
        if (videos?.length == 1) {
          setPageNumber(pageNumber - 1);
          data.pageNumber = pageNumber - 1;
          pageNo = pageNumber - 1
        }
        await dispatch(videoHandler(data))
          .then((res: any) => {
            const videoData = res?.payload?.data;
            setVideos(videoData);
            setTotalRecords(res?.payload?.totalItems);
            updateData(pageNumber, videoData);
            setPageNumber(pageNo)
            setCurrentPage(pageNo);
          })
          .catch((err: any) => {
            console.log("err", err);
          });
        if (deleteRes.type == DELETE_VIDEO) {
          toast.success(t('VideoProcess.videoDelete'), {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error(deleteRes?.payload?.message ?? t('VideoProcess.videoDeleteFailed'), {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } else {
        toast.error(deleteRes?.payload?.message ?? t('VideoProcess.videoDeleteFailed'), {
          position: "top-right",
          autoClose: 2000,
        });
      }
    });
  }

  const monitorObjectOfVideo = (detectedMonitoredObjectsArr: any) => {
    const allMonitoredObjects = ['battery', 'brake', 'car', 'filter', 'indicator', 'license plate', 'tire', 'wiper', 'air filter', 'fluids'];
    const detectedMonitoredKeywordValues: any = []
    for (let detectedKeyword of detectedMonitoredObjectsArr) {
      detectedMonitoredKeywordValues.push(detectedKeyword.keyword.toLowerCase())
    }
    const highlightedElements = allMonitoredObjects.map((item, index) => {
      const isHighlighted = detectedMonitoredKeywordValues.includes(item);
      var titleCaseVideoObject = '';
      if (index >= 0 && index < (allMonitoredObjects.length - 1)) {
        titleCaseVideoObject = toTitleCase(item) + ', ';
      } else {
        titleCaseVideoObject = toTitleCase(item);
      }
      return (
        <span
          key={index}
          style={{
            color: isHighlighted ? '#009900' : '#AA0000',
            fontWeight: isHighlighted ? 'bold' : 'normal'
          }}
        >
          {titleCaseVideoObject}
        </span>
      );
    });
    return <>{highlightedElements}</>;
  };

  const objectOfVideo = (detectedVideoObjectsArr: any) => {
    const allVideoObjects = [
      'car', 'license plate', 'brakes', 'tires', 'air filters',
      'indicators', 'wiper blades', 'brake pad gauge', 'tire gauge',
      'dirty air filters', 'car interior'
    ];

    let detectedVideoObjectValuesArr: any = [];
    for (let detectedVideoObject of detectedVideoObjectsArr) {
      detectedVideoObjectValuesArr.push(detectedVideoObject.object.toLowerCase())
    }
    const highlightedElements = allVideoObjects.map((item: any, index) => {
      // Check if the current item is in the detectedVideoObjectsArr
      const isHighlighted = detectedVideoObjectValuesArr.includes(item);
      var titleCaseVideoObject = '';
      if (index >= 0 && index < (allVideoObjects.length - 1)) {
        titleCaseVideoObject = toTitleCase(item) + ', ';
      } else {
        titleCaseVideoObject = toTitleCase(item);
      }
      return (
        <span
          key={index}
          style={{
            color: isHighlighted ? '#009900' : '#AA0000',
            fontWeight: isHighlighted ? 'bold' : 'normal'
          }}
        >
          {titleCaseVideoObject}
        </span>
      );
    });
    return <>{highlightedElements}</>;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (selectedVideo?.uuid && modalData?.value != null) {
      const payload = {
        videoUuid: selectedVideo.uuid,
        [modalData.field]: modalData.value
      }
      await dispatch(addVideoCustomerIdHandler(payload)).then((res: any) => {
        setShowEditModal(false);
        handleViewDetails(selectedVideo.uuid)
      }).catch((err: any) => {
        console.log(err, "err");
      })
    } else {
      e.preventDefault();
      setIsTouched(true);
    }
  }
  //functions ends here

  //useEffcet starts here
  useEffect(() => {
    setVideos(videoData);
    setTotalRecords(paginationData?.totalItems);
    setCurrentPage(paginationData?.currentPage);
  }, [videoData, paginationData]);

  useEffect(() => {
    getVideoData(1, selectedDealerId)
  }, [trigger, selectedDealerId, selectedBrandId]);

  useEffect(() => {
    socket.on('video-data-update-res', async (data: any) => {
      const updatedData = await videos?.map((e: any) => {
        let newVideo = {};
        if (data.videoUuid !== e.uuid) {
          newVideo = e;
        } else {
          newVideo = {
            uuid: data?.videoUuid ?? e?.uuid,
            key: data?.storageLink,
            progress: data?.progress ?? e?.progress,
            status: data?.status ?? e?.status,
            posterThumbnailLink: data?.thumbnail ?? e?.thumbnail,
            createdAt: e?.createdAt,
            updatedAt: e?.updatedAt,
            videoUrl: e?.storageLink,
            storageLink: data?.storageLink,
            licensePlate: data?.licensePlate ?? e?.licensePlate,
            starRating: data?.starRating ?? e?.starRating,
            totalRating: data?.totalRating,
            totalRewardPoints: data?.totalRewardPoints
          };
        }
        return newVideo;
      });
      setVideos(updatedData);
    });

    return (() => {
      socket.off('video-data-update-res')
    })
  }, [videos])
  //useEffcet ends here

  // Extract common nouns from videoTranscriptCommonKeyword
  const commonNouns = selectedVideo?.videoTranscript?.videoTranscriptCommonKeyword || [];
  const commonNounKeywords = commonNouns.map((keyword: any) => keyword.keyword).join(', ');

  return (
    <div>
      <TableContainer component={Paper} className="custom-table mb-3">
        <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>
                {t('constants.UniqueID')}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }}>
                {t('constants.VideoURL')}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                {t('constants.Status')}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                {t('constants.progress')}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                {t('constants.videoRating')}
              </TableCell>
              {licensePlateDisplay == 'true' && <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }}>
                {t('constants.licensePlate')}
              </TableCell>}
              <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }}>
                {t('constants.updatedAt')}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", }} className="text-end">
                {t('constants.action')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos && videos.length > 0 ? videos?.map((row: any) => {
              return (
                <TableRow key={row.uuid} className="mb-4">
                  <TableCell sx={{ padding: '8px', verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-3">
                      <div onClick={() => { handleThumbnailClick(row?.uuid) }}>
                        {row?.posterThumbnailLink ? (<img
                          className="thumbnails"
                          src={row?.posterThumbnailLink ?? DUMMY_THUMBNAIL_URL}
                          title={t('thumbnails.videoThumbnail')}
                          onError={(e: any) => {
                            e.target.src = DUMMY_THUMBNAIL_URL;
                          }}
                        />) : (<img className="thumbnails" src={DUMMY_THUMBNAIL_URL} title={t('thumbnails.videoThumbnail')}>
                        </img>)}
                      </div>
                      <div>{row.uuid}</div>
                    </div>
                  </TableCell>
                  <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }}>
                    <a href="#" className="link-color" onClick={() => handleOpenVideoModal(row?.uuid)}>
                      {row?.storageLink?.split('/')[row?.storageLink.split('/').length - 1]?.split('?')[0]}
                    </a>
                  </TableCell>
                  <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} className="text-center">{toTitleCase(row?.status)}</TableCell>
                  <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }}>
                    <div className="progress-container">
                      <ProgressBar bgColor="#3483c8" completed={row?.progress} className="status-progressbar" />
                      <div className="retry-icon-container">
                        {row?.status !== SUCCESSFUL && row?.status !== FAILED ? (
                          <TbRotateClockwise2 className="rotate-icon" size={24} />
                        ) : (
                          <span className="retry-placeholder"></span>
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} className="text-center"><Rating fillColor="#3483c8" readonly allowFraction size={25} initialValue={row?.starRating} /><span style={{ marginLeft: '8px', fontSize: '10px' }}>{`${row?.totalRating}/${row?.totalRewardPoints}`}</span></TableCell>
                  {licensePlateDisplay == 'true' && <TableCell sx={{ padding: '0 8px' }}>{row?.licensePlate ? row?.licensePlate : 'N/A'}</TableCell>}
                  <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }}>{moment(row?.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}</TableCell>
                  <TableCell sx={{ padding: '8px', verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <Tooltip placement="top" title={t('constants.viewDetails')}>
                        <IconButton className='size-34' color="primary" onClick={() => navigate(VIDEO_DETAIL.replace(':uuid', row.uuid))}  >
                          <FontAwesomeIcon icon={faEye} size='xs' />
                        </IconButton>
                      </Tooltip>
                      {row.status == FAILED &&
                        <Tooltip placement="top" title={t('constants.retry')}>
                          <IconButton className='size-34' onClick={() => handleRetry(row.uuid)}  >
                            <FontAwesomeIcon icon={faRotateForward} size='xs' />
                          </IconButton>
                        </Tooltip>}
                      <Tooltip placement="top" title={t('constants.delete')}>
                        <IconButton className='size-34' color="error" onClick={() => handleDeleteVideo(row.uuid)}  >
                          <FontAwesomeIcon icon={faTrash} size='xs' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            }) : <tr><td colSpan={7} style={{ "textAlign": "center" }}>No videos found</td></tr>}
          </TableBody>
        </Table>
      </TableContainer>
      {showThumbnailModal && videoThumbnails?.length > 0 && (
        <ThumbnailCarousalModal
          isOpen={showThumbnailModal}
          onClose={() => setShowThumbnailModal(false)}
          thumbnails={videoThumbnails}
        />
      )}
      {showTranscriptionTextModal && isExpanded && (
        <VideoTranscriptionTextModal
          isOpen={isExpanded}
          title={transcriptionTextTitle}
          text={transcriptionText}
          onClose={() => { setShowTranscriptionTextModal(false); setIsExpanded(false); }}
        />
      )}
      {showVideoDeleteModal && (
        <DeleteDialogBox
          show={showVideoDeleteModal}
          clickOk={() => {
            deleteConfirmation(deleteVideoId);
            setShowVideoDeleteModal(false);
          }}
          clickCancel={() => {
            setShowVideoDeleteModal(false);
          }}
          color={"btn-danger"}
          btncancel={t("constants.cancel")}
          btnyes={t("constants.delete")}
          deleteText={t("VideoProcess.videoDeleteConfirmation")}
        />
      )}
      <ModalComponent
        isOpen={showModal}
        onClose={handleCloseModal}
        modalHeading={t('VideoProcess.videoObjectDetails')}
      >
        {selectedVideo && (
          <>
            <div className="table-responsive">
              <table className="video-details-table mt-4 mb-4">
                <tbody>
                  <tr>
                    <th>{t('constants.objects')}</th>
                    <th>{t('constants.metrics')}</th>
                  </tr>
                  <tr>
                    <td>{t('constants.uniqueID')}</td>
                    <td>{selectedVideo.uuid}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.videoUrl')}</td>
                    <td> <a href="#" onClick={() => { setShowModal(false); handleOpenVideoModal(selectedVideo.uuid) }}>{selectedVideo?.storageLink?.split('/')[selectedVideo?.storageLink.split('/').length - 1]?.split('?')[0]}</a></td>
                  </tr>
                  <tr>
                    <td>{t('constants.videoLength')}</td>
                    <td>{selectedVideo?.videoDetail?.length ? <WiTime8 /> : ''} {selectedVideo?.videoDetail?.length ? `${selectedVideo?.videoDetail?.length}  ${t('constants.seconds')}` : selectedVideo?.videoDetail?.length} </td>
                  </tr>
                  {licensePlateDisplay == 'true' && <tr>
                    {<td>{t('constants.licensePlate')}</td>}
                    {<td>{selectedVideo?.licensePlate ? selectedVideo?.licensePlate : 'N/A'}</td>}
                  </tr>}
                  <tr>
                    {<td>{t('constants.deaderName')}</td>}
                    {<td>{selectedVideo?.videoDetail?.detectedCarBrand ? selectedVideo?.videoDetail?.detectedCarBrand : 'N/A'}</td>}
                  </tr>
                  <tr>
                    {<td>{t('constants.carModelName')}</td>}
                    {<td>{selectedVideo?.videoDetail?.detectedCarModel ? selectedVideo?.videoDetail?.detectedCarModel : 'N/A'}</td>}
                  </tr>
                  <tr>
                    <td>{t('constants.status')}</td>
                    <td> {toTitleCase(selectedVideo?.status)} &nbsp;
                      {selectedVideo?.status === FAILED && (
                        <Tooltip placement="top" title={t('constants.retry') + ': ' + selectedVideo?.reason || ''}>
                          <button className="btn p-0" onClick={() => { handleRetry(selectedVideo?.uuid) }}>
                            <FontAwesomeIcon icon={faRotateForward} className="fs-6" />
                          </button>
                        </Tooltip>
                      )}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.progress')}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <ProgressBar bgColor="#3483c8" completed={selectedVideo?.progress} className="status-progressbar w-100" />
                        {selectedVideo?.status !== SUCCESSFUL && selectedVideo?.status !== FAILED && (
                          <TbRotateClockwise2 className="rotate-icon" size={24} />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.videoQualityRating')}</td>
                    <td>
                      <Rating fillColor="#3483c8" readonly allowFraction size={25} initialValue={selectedVideo?.starRating} />
                      <span style={{ marginLeft: '8px', fontSize: '10px' }}>{`${selectedVideo?.totalRating ? selectedVideo?.totalRating : 0} out of ${selectedVideo?.totalRewardPoints}`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.fps')}</td>
                    <td>{selectedVideo?.videoDetail?.fps}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.videoCodec')}</td>
                    <td>{selectedVideo?.videoDetail?.videoCodec}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.audioCodec')}</td>
                    <td>{selectedVideo?.videoDetail?.audioCodec}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.totalWords')}</td>
                    <td>{selectedVideo?.videoDetail?.totalWords}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.wordsPerMinute')}</td>
                    <td>{selectedVideo?.videoDetail?.wordsPerMinute}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.longestSilence')}</td>
                    <td>{selectedVideo?.videoDetail?.longestSilence ? <CgTimelapse /> : ''} {selectedVideo?.videoDetail?.longestSilence ? `${selectedVideo?.videoDetail?.longestSilence} ${t('constants.seconds')}` : selectedVideo?.videoDetail?.longestSilence}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.voiceVolume')}</td>
                    <td>{selectedVideo?.videoDetail?.voiceVolume ? `${selectedVideo?.videoDetail?.voiceVolume} ${t('constants.decibel')}` : selectedVideo?.videoDetail?.voiceVolume}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.loudNoises')}</td>
                    <td>{selectedVideo?.videoDetail?.loudVoice ? `${selectedVideo?.videoDetail?.loudVoice} ${t('constants.decibel')}` : selectedVideo?.videoDetail?.loudVoice}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.summary')}</td>
                    <td>
                      <Tooltip placement="top" title={t('constants.summary')}>
                        <button className="btn p-0" onClick={() => handleReadMore(selectedVideo.uuid, 'summary')}>
                          <FontAwesomeIcon icon={faEye} className="fs-6 text-primary" />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.transcriptionText')}</td>
                    <td>
                      <Tooltip placement="top" title={t('constants.transcriptionText')}>
                        <button className="btn p-0" onClick={() => handleReadMore(selectedVideo.uuid, 'transcript')}>
                          <FontAwesomeIcon icon={faEye} className="fs-6 text-primary" />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.monitoredKeywordsFound')}</td>
                    <td>
                      {selectedVideo?.monitoredKeywords && selectedVideo?.monitoredKeywords?.length > 0 && monitorObjectOfVideo(selectedVideo?.monitoredKeywords)
                      }
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{t('constants.videoObjects')}</strong></td>
                    <td>
                      <div className="table-alice-carousel">
                        <Swiper navigation={true} modules={[Navigation]} className="mySwiper"
                          spaceBetween={34}
                          slidesPerView={3}
                          loop={true}
                          onSlideChange={() => console.log('slide change')}
                          onSwiper={(swiper: any) => console.log(swiper)}
                        >
                          {selectedVideo?.thumbnails?.map((thumbnail: any, index: any) => {
                            return (
                              <SwiperSlide key={index} >
                                <div className="w-100" onClick={() => { handleThumbnailClick(selectedVideo?.uuid) }}>
                                  <img className="thumbnails w-100" src={thumbnail?.storageLink} alt='' title={t('thumbnails.videoThumbnail')}
                                    onError={(thumbnail: any) => {
                                      thumbnail.target.src = DUMMY_THUMBNAIL_URL; // Replace with your alternate image URL
                                    }} />
                                </div>
                              </SwiperSlide>
                            )
                          }
                          )}
                        </Swiper>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.customObjects')}</td>
                    <td>
                      {selectedVideo?.object && selectedVideo?.object.length > 0 && objectOfVideo(selectedVideo?.object)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.commonNoun')}</td>
                    <td>
                      {commonNounKeywords && commonNounKeywords.length > 0 ? (
                        <>
                          {commonNounKeywords}
                        </>
                      ) : (
                        <>
                          {'N/A'}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.customerId')}</td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{selectedVideo?.videoDetail?.customerId ?? 'N/A'}</span>
                        <button
                          className="btn p-0"
                          onClick={() => handleEditClick('Customer Id', 'customerId', selectedVideo?.videoDetail?.customerId)}
                        >
                          <FontAwesomeIcon icon={faEdit} className="fs-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.partnerId')}</td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        {selectedVideo?.videoDetail?.partnerId ?? 'N/A'}
                        <button className="btn p-0" onClick={() => handleEditClick('Partner Id', 'partnerId', selectedVideo?.videoDetail?.partnerId)}>
                          <FontAwesomeIcon icon={faEdit} className="fs-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.orderId')}</td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        {selectedVideo?.videoDetail?.orderId ?? 'N/A'}
                        <button className="btn p-0" onClick={() => handleEditClick('Order Id', 'orderId', selectedVideo?.videoDetail?.orderId)}>
                          <FontAwesomeIcon icon={faEdit} className="fs-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.technician')}</td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        {selectedVideo?.videoDetail?.technician ?? 'N/A'}
                        <button className="btn p-0" onClick={() => handleEditClick('Technician', 'technician', selectedVideo?.videoDetail?.technician)}>
                          <FontAwesomeIcon icon={faEdit} className="fs-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.roNumber')}</td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        {selectedVideo?.videoDetail?.roNumber ?? 'N/A'}
                        <button className="btn p-0" onClick={() => handleEditClick('Ro Number', 'roNumber', selectedVideo?.videoDetail?.roNumber)}>
                          <FontAwesomeIcon icon={faEdit} className="fs-6" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('constants.video')}</td>
                    <td>{selectedVideo?.videoDetail?.videoDisplay ? <FaCheck style={{ color: '#0A0' }} /> : <FaXmark style={{ color: '#A00' }} />}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.profanity')}</td>
                    <td>{selectedVideo?.videoDetail?.profanity ? <FaCheck style={{ color: '#A00' }} /> : <FaXmark style={{ color: '#0A0' }} />}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.audioMuffled')}</td>
                    <td>{selectedVideo?.videoDetail?.audioMuffled ? <FaCheck style={{ color: '#A00' }} /> : <FaXmark style={{ color: '#0A0' }} />}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.languages')}</td>
                    <td>{selectedVideo?.videoDetail?.lang}</td>
                  </tr>
                  <tr>
                    <td>{t('constants.subtitles')}</td>
                    <td>{selectedVideo?.videoDetail?.subtitle ? <FaCheck style={{ color: '#0A0' }} /> : <FaXmark style={{ color: '#A00' }} />}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalComponent>
      <VideoPlayerModal
        isOpen={showVideoModal}
        onClose={handleCloseVideoModal}
        video={selectedVideo}
        isProcessedLink={false}
      />
      <div className="d-flex align-items-center justify-content-end">
        {videos && videos?.length > 0 && (
          <ReactPaginate
            pageCount={Math.ceil(totalRecords / paginationSize)}
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
            nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
            onPageChange={({ selected }) => onHandleFetchMore(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
          />
        )}
      </div>
      {showEditModal && (
        <Modal show={showEditModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('constants.edit')} {modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="fieldValue" className="form-label">
                  {modalData.title}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fieldValue"
                  defaultValue={modalData.value}
                  onChange={(e: any) => setModalData({ ...modalData, value: e.target.value })}
                />
                {(modalData?.value?.trim() == '' || isTouched) && <span className="text-danger">{modalData.title} is required</span>}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              {t('constants.close')}
            </Button>
            <Button variant="primary" style={{ padding: '7px 20px' }} onClick={(e) => handleSubmit(e)} disabled={modalData?.value == undefined || modalData?.value?.trim() == ''}>
              {t('constants.submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default DataTable;