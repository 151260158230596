import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Paper,
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllMappedBrandDealerHandler, getUserAssignedBrandDealerHandler, saveUserBrandDealerHandler } from '../Store/slices/dealerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFilter } from '@fortawesome/free-solid-svg-icons';
import { VIEW_BRAND_DEALERS } from '../../common/routeConstants';
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import Previous from "../../assets/images/previous-icon.svg";
import Next from "../../assets/images/next.svg";
import BrandDealerFilter from "../../components/BrandDealerFilter";

const AsignedBrandAndDealers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId: any = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState<any>({});
    const [selectedDealer, setSelectedDealer] = useState<any>({});
    const [allData, setAllData] = useState<any>([]);
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const limit = 10;
    const paginationSize = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string>("");
    const [showFilter, setShowFilter] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    useEffect(() => {
        fetchAllBrandAndDealers(pageNumber, filterValue);
    }, []);

    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        setPageNumber(newPageNumber);
        if (selectedBrand || selectedDealer || filterValue) {
            fetchAllBrandAndDealers(newPageNumber, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
        }
    };

    const handleApply = () => {
        setShowFilter(false);
        if (selectedBrand || selectedDealer || filterValue) {
            fetchAllBrandAndDealers(1, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
        }
    };

    const handleReset = () => {
        setShowFilter(false);
        setSelectedBrand({});
        setSelectedDealer({});
        setFilterValue("");
        fetchAllBrandAndDealers(1, "", "", "");
    };

    const fetchAllBrandAndDealers = async (page: any, brandUuid?: any, dealerUuid?: any, searchVal?: any) => {
        const commonData = {
            userUuid: userId.uuid,
            brandUuid: brandUuid || "",
            dealerUuid: dealerUuid || "",
            searchVal: searchVal || "",
            pageNumber: page || 1,
            limit
        };
        try {
            const assignedDataResponse = await dispatch(getUserAssignedBrandDealerHandler(commonData));
            const dealerBrandDataResponse = await dispatch(getAllMappedBrandDealerHandler(commonData));
            const assignedData = assignedDataResponse?.payload?.data?.allData || [];
            const selectedDealers: any = assignedData.map((data: any) => data.mapBrandDealer);

            const filteredDealers = selectedDealers.filter((dealerId: string) => selectedData.includes(dealerId));
            setSelectedData((prevSelectedData: any) => {
                const updatedSelection = [...prevSelectedData, ...filteredDealers.length == 0 ? selectedDealers : filteredDealers].filter(
                    (value, index, self) => self.indexOf(value) === index
                );
                return updatedSelection;
            });
            if (dealerBrandDataResponse.type === 'getAllMappedBrandDealer/fulfilled') {
                const data = dealerBrandDataResponse.payload.data.data;
                const { page, total } = dealerBrandDataResponse.payload.data;
                setAllData(data);
                setTotalRecords(total);
                setIsLastPage(page === total);
                setIsFirstPage(page === 1);
                setSelectAllChecked(false);
                if (assignedData.length === 0) {
                    setSelectedData((prevSelectedData) => [...prevSelectedData]);
                    setSelectAllChecked(true);
                }
            }
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }
    };
    const checkBoxHandler = (checked: boolean, uuid: string) => {
        setSelectedData((prev: any[]) => {
            const updatedSelection = checked
                ? [...prev, uuid]
                : prev.filter((id: any) => id !== uuid);

            return updatedSelection;
        });
    };
    const checkAllHandler = (checked: boolean, currentPageDealers: any[]) => {
        setSelectAllChecked(checked);
        if (checked) {
            const newSelected = currentPageDealers
                .map((dealer: any) => dealer.uuid)
                .filter((uuid: any) => !selectedData.includes(uuid));

            setSelectedData((prev: any) => [...prev, ...newSelected]);
        } else {
            setSelectedData((prev: any) =>
                prev.filter((uuid: string) => !currentPageDealers.some((dealer: any) => dealer.uuid === uuid))
            );
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        if (selectedBrand == "") {
            setLoading(false);
            toast.error("Please select brand", {
                position: "top-right",
                autoClose: 2000
            });
            return;
        }
        else {
            const payload = [{
                userId: userId.uuid,
                mappedBrandDealerArray: selectedData.map((id: any) => ({ value: id })),
            }
            ];
            dispatch(saveUserBrandDealerHandler(payload)).then((res: any) => {
                setLoading(false);
                if (res?.type == 'saveUserBrandDealer/fulfilled') {
                    if (res?.payload == true) {
                        toast.success("Brand and Dealers assigned successfully", {
                            position: 'top-right',
                            autoClose: 2000,
                        });
                        setPageNumber(1)
                        fetchAllBrandAndDealers(1);
                    }
                }
                else {
                    setLoading(false);
                    toast.error('Error in assigning brands and dealer ', {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            })
        }
    }

    const handleSelectAll = () => {
        const data = {
            pageNumber: -1,
            searchVal: "",
        };
        dispatch(getAllMappedBrandDealerHandler(data)).then((res: any) => {
            if (res?.type === "getAllMappedBrandDealer/fulfilled") {
                const allSelected = res.payload.data.data?.map((dealer: any) => dealer.uuid);
                if (allSelected.length === selectedData.length) {
                    setSelectedData([]);
                    setIsAllSelected(false);
                    setSelectAllChecked(false);
                } else {
                    setSelectedData(allSelected);
                    setIsAllSelected(true);
                    setSelectAllChecked(true);
                }
            }
        });
    };

    useEffect(() => {
        const allSelected =
            allData.length > 0 &&
            allData.every((dealer: any) => selectedData.includes(dealer.uuid));
        setSelectAllChecked(allSelected);
        if (selectedData?.length !== totalRecords) {
            setIsAllSelected(false)
        } else {
            setIsAllSelected(true)
        }
    }, [allData, selectedData]);
    return (
        <>
            <div className="dashboard-wrapper brand-dealer-wrapper mb-2">
                <div className="container-fluid">
                    {loading && (
                        <div className="loader-overlay">
                            <div className="loader-container">
                                <TailSpin color="#00BFFF" height={80} width={80} />
                            </div>
                        </div>
                    )}

                    <h2>Assign Brands and Dealers</h2>
                    <div className="buttons-container d-flex align-items-center justify-content-between mt-2">
                        <div className="d-flex align-items-center gap-3">
                            <button
                                className="btn btn-primary px-3 mw-120 d-flex align-items-center gap-3 mb-2"
                                onClick={() => {
                                    const path = VIEW_BRAND_DEALERS.replace(':uuid', userId.uuid);
                                    navigate(path);
                                }}
                            >
                                <span><FontAwesomeIcon icon={faArrowLeft} /></span>
                                <span className="fw-semibold">Back</span>
                            </button>
                        </div>
                        <div className="d-flex align-items-center">
                            <div style={{ position: "relative" }}>
                                <button
                                    className="btn btn-primary px-3 mw-120 d-flex align-items-center gap-3 mb-2"
                                    type="button"
                                    onClick={() => setShowFilter(true)}
                                >
                                    <span className='fw-semibold'>Filter</span><FontAwesomeIcon icon={faFilter} className="filterIcon" />
                                </button>
                                {showFilter && (
                                    <BrandDealerFilter
                                        setShowFilter={setShowFilter}
                                        selectedBrand={selectedBrand}
                                        selectedDealer={selectedDealer}
                                        setSelectedDealer={setSelectedDealer}
                                        setSelectedBrand={setSelectedBrand}
                                        setFilterValue={setFilterValue}
                                        filterValue={filterValue}
                                        handleReset={handleReset}
                                        handleApply={handleApply}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <TableContainer component={Paper} className="custom-table mb-3">
                        <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", width: '30%' }}>
                                        {allData && Object.keys(allData).length > 0 &&
                                            <span className="d-flex align-items-center gap-4">
                                                <span className="checkbox-custom">
                                                    <Checkbox
                                                        checked={selectAllChecked}
                                                        onChange={(e) => checkAllHandler(e.target.checked, allData)}
                                                    />
                                                    <span>Select</span>
                                                </span>
                                                <span className="checkbox-custom">
                                                    <Checkbox
                                                        checked={isAllSelected}
                                                        onChange={handleSelectAll}
                                                    />
                                                    <span> Select All {isAllSelected}</span>
                                                </span>
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>Brand Name</TableCell>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>Dealer Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allData?.length > 0 ? (
                                    allData?.map((data: any) => {
                                        const brandName = data.brand.name;
                                        const dealerName = data.dealer.name;
                                        const mappedDealerUiid = data.uuid;
                                        return (
                                            <TableRow key={mappedDealerUiid}>
                                                <TableCell sx={{
                                                    verticalAlign: "middle",
                                                }}>
                                                    <Checkbox
                                                        checked={selectedData?.includes(mappedDealerUiid)}
                                                        onChange={(e) =>
                                                            checkBoxHandler(e.target.checked, mappedDealerUiid)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }}>{brandName}</TableCell>
                                                <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }}>{dealerName}</TableCell>
                                            </TableRow>)
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            No records found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mapBrandDealer-buttons">
                        <div className="buttons-container">
                            <button
                                className='btn btn-primary px-3 mw-120 d-flex align-items-center gap-2'
                                onClick={handleSubmit}
                            >
                                <span className='fw-semibold'>Submit</span>
                            </button>
                        </div>
                        <div className="pagination-container ">
                            {allData && allData?.length > 0 &&
                                <ReactPaginate
                                    pageCount={Math.ceil(totalRecords / paginationSize)}
                                    previousLabel={<img src={Previous} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                                    nextLabel={<img src={Next} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                                    onPageChange={({ selected }) => onHandleFetchMore(selected)}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AsignedBrandAndDealers;


