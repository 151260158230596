import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { userChangePassword } from "../../../services/auth.service";
import PasswordField from "../../../components/PasswordField";
import { faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutHandler } from "../../Store/slices/authSlice";
import AuthWrapper from "../../../components/AuthWrapper";
import { RootState } from "../../Store/store";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { user, isAdmin, admin } = useSelector((state: RootState) => state.auth)
  const uuid = isAdmin ? admin?.user?.uuid : user?.user?.uuid ?? ''

  //formik starts here
  const initialValues: any = {
    newPassword: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),


    confirmPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).oneOf([Yup.ref('newPassword')], 'Passwords must match')
  });
  //formik ends here

  //functions starts here
  const handleRegister = async (formValue: any, { resetForm }: any) => {
    const newPassword = formValue?.newPassword

    await userChangePassword(uuid, newPassword)
      .then((result: any) => {
        if (result?.statusCode === 201) {
          toast.success(result?.message, {
            position: 'top-right',
            autoClose: 2500,
          });
          resetForm();
          dispatch(logoutHandler())
        }
        else if (result?.statusCode === 400) {
          toast.error(result?.message, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      })
      .catch((err: any) => {
        console.error(err, "error");
      })

  };
  return (
    <>
      <AuthWrapper title="Change Password" icon={faUnlockKeyhole}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form className="vstack gap-4">
            <PasswordField name="newPassword" label="New Password" />
            <PasswordField name="confirmPassword" label="Confirm Password" />

            <div className="text-center mt-4">
              <button className="btn btn-primary rounded-4 px-4 py-2" type="submit">
                {t('common.setNewPassword')}
              </button>
            </div>
          </Form>
        </Formik>
      </AuthWrapper>
    </>
  );
}

export default ChangePassword