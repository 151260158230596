import { useEffect, useState } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { VIDEO_DETAIL } from "../../common/routeConstants";
import ReactPaginate from "react-paginate";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDataByVideoHandler } from "../Store/slices/dashboardSlice";
import { RootState } from "../Store/store";
import { TailSpin } from "react-loader-spinner";
import { Autocomplete, FormControl, TextField } from '@mui/material';
import moment from "moment";

const DataByVideo = ({ selectedKey, dealers, userId, selectedBrandId }: any) => {
    //single hook start here
    const dispatch = useDispatch();
    const limit = 10;
    const paginationSize = 10;
    //single hook ends here

    //useState start here
    const [data, setData] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(5);
    const [roNumber, setRoNumber] = useState<any>('');
    const [technician, setTechnician] = useState<any>('');
    const [loader, setLoading] = useState<boolean>(false);
    const [selectedDealer, setSelectedDealer]: any = useState({ uuid: '', name: 'ALL' });
    const [selectedRegion, setSelectedRegion]: any = useState({ uuid: '', region: 'ALL' });
    //useState ends here

    //Functions starts here

    //for loop to get unique regions 
    const uniqueRegions: any = [];
    const regionSet: any = {};
    for (const dealer of dealers) {
        if (!regionSet[dealer?.region]) {
            regionSet[dealer?.region] = true;
            uniqueRegions.push({ region: dealer.region });
        }
    }

    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        setPageNumber(newPageNumber);
    };

    const getDataByVideo = () => {
        setLoading(true);
        try {
            const data = {
                brandUuid: selectedBrandId,
                userId: userId,
                pageNumber: pageNumber,
                limit,
                region: selectedRegion == null || selectedRegion.region == 'ALL' ? '' : selectedRegion.region,
                searchByRoNumber: roNumber,
                searchByDealerName: selectedDealer?.uuid ? selectedDealer?.uuid : '',
                searchByTechnicianName: technician
            }
            dispatch(getDataByVideoHandler(data)).then((result: any) => {
                setData(result?.payload?.data)
                setTotalRecords(result?.payload?.totalItems);
            }).catch((err: any) => {
                throw err
            });

        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (selectedBrandId && selectedDealer !== undefined && selectedRegion !== undefined) {
            getDataByVideo();

        }
    }, [selectedBrandId, selectedDealer?.uuid, selectedRegion?.region, pageNumber, roNumber, technician]);    

    useEffect(() => {
        if (roNumber || technician) {
            setPageNumber(1);
        }
    }, [roNumber, technician]);

    //useEffect ends here
    useEffect(() => {
        setPageNumber(1);
        setSelectedRegion({ uuid: '', region: 'ALL' })
        setSelectedDealer({ uuid: '', name: 'ALL' })
        setTechnician('')
        setRoNumber('')
    }, [selectedKey, selectedBrandId])
    return (
        <>
            <div className='row'>
                {loader && (
                    <div className="loader-overlay">
                        <div className="loader-container">
                            <TailSpin color="#00BFFF" height={80} width={80} />
                        </div>
                    </div>
                )}
                <div className="col-lg-2 mb-4">
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            options={dealers}
                            getOptionLabel={(option: any) => option.name || ""}
                            value={selectedDealer}
                            onChange={(event, newValue) => { setSelectedDealer(newValue); setPageNumber(1) }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Dealer" variant="outlined" />
                            )}
                        />
                    </FormControl>
                </div>
                <div className='col-lg-2 mb-4'>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            options={uniqueRegions}
                            getOptionLabel={(option: any) => option.region || ""}
                            value={selectedRegion}
                            onChange={(event, newValue) => { setSelectedRegion(newValue); setPageNumber(1) }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Region" variant="outlined" />
                            )}
                        />
                    </FormControl>
                </div>
                <div className='col-lg-2 mb-4'>
                    <FormControl style={{ width: "100%" }}>
                        <input
                            type="search"
                            placeholder="RoNumber"
                            className="form-control search-height"
                            value={roNumber}
                            onChange={(e: any) => setRoNumber(e.target.value)}

                        />
                    </FormControl>
                </div>
                <div className='col-lg-2 mb-4'>
                    <FormControl style={{ width: "100%" }}>
                        <input
                            type="search"
                            placeholder="Technician"
                            className="form-control search-height"
                            value={technician}
                            onChange={(e: any) => setTechnician(e.target.value)}

                        />
                    </FormControl>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className="table-container">
                        <TableContainer component={Paper} className="custom-table mb-3">
                            <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} className="text-right">Dealer</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align="right">RO Number</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align="center">Video Created Date</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align="right">Region</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align="center">Technician</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align="center">Make</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align='right'>Inspection Score</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }} align='right'>Quality Score</TableCell>
                                        <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>Video Link</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.length > 0 ? (data &&
                                        data?.map((videosData: any) => {
                                            return (
                                                <>
                                                    <TableRow className="mb-4">
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} className="text-start">
                                                            <span className='text-nowrap'>
                                                                <Link to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>{videosData?.dealerName}</Link>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span><Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                {videosData?.roNumber}
                                                            </Link></span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span>
                                                                <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                    {moment(videosData?.createdAt).format("DD-MM-YYYY hh:mm:ss A")}
                                                                </Link>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span>
                                                                <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                    {videosData?.Region}
                                                                </Link>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span><Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                {videosData?.technician}</Link></span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span>   <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}> {videosData?.brandName}</Link>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span>
                                                                <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                    {videosData?.Inspection_Score || 'N/A'}</Link></span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="center">
                                                            <span>
                                                                <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>
                                                                    {videosData?.qualityScore || 'N/A'}</Link>  </span>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '0 8px', verticalAlign: "middle" }} align="left">
                                                            <span>
                                                                <Link className="navbar-brand" to={VIDEO_DETAIL.replace(':uuid', videosData?.videoId)}>{videosData?.storageLink}</Link></span>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })) : (<TableRow>
                                            <TableCell colSpan={9} align="center">
                                                No records found.
                                            </TableCell>
                                        </TableRow>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="d-flex align-items-center justify-content-end">
                            {data?.length > 0 &&
                                <ReactPaginate
                                    pageCount={Math.ceil(totalRecords / paginationSize)}
                                    previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
                                    nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
                                    onPageChange={({ selected }) => onHandleFetchMore(selected)}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DataByVideo;