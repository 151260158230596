import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from "react-bootstrap";
interface ModalProps {
  isOpen: any,
  onClose:any,
  children?: any,
  modalHeading?: string,
  size?: string
}
const ModalComponent: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  modalHeading,
  size = 'xl'
}: any) => {
  return (
    <>
      <Modal className='video-details-modal' show={isOpen} size={size} centered >
        <Modal.Header>
          <h5 className="modal-title fw-semibold">
            {modalHeading}
          </h5>
          <button
            type="button"
            aria-label="Close"
            className='btn focus-none'
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} className='fs-3 text-white' />
          </button>
        </Modal.Header>
        <div className="">
          {children}
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
