import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define types
interface ModalsState {
    createUserModal: boolean,
    assignBrandDealerModal: boolean,
    editUserModal: boolean,
    userDetailsModal: boolean,
    bulkStatusModal: boolean,
    csvByEmailModal: boolean
}

// Define the initial state
const initialState: ModalsState = {
    createUserModal: false,
    assignBrandDealerModal: false,
    editUserModal: false,
    userDetailsModal: false,
    bulkStatusModal: false,
    csvByEmailModal: false
};

// Create async thunks for authentication operations


// Create the slice
const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        // Optional synchronous actions can be defined here

        createUserModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.createUserModal = payload;
        },
        assignBrandDealersModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.assignBrandDealerModal = payload;
        },
        editUserModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.editUserModal = payload;
        },
        ShowUserDetailsModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.userDetailsModal = payload;
        },
        ShowBulkStatusModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.bulkStatusModal = payload;
        },
        ShowExportCsvModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.csvByEmailModal = payload;
        }
    },
    extraReducers: () => { },
});

export const { editUserModalHandler, ShowUserDetailsModalHandler, createUserModalHandler, assignBrandDealersModalHandler, ShowBulkStatusModalHandler,ShowExportCsvModalHandler } = modalsSlice.actions;
export default modalsSlice.reducer;
