import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../pages/Store/store';
import { logoutHandler } from '../pages/Store/slices/authSlice';
import UserProfile from "../assets/images/user-profile.svg";
import Logo from "../assets/images/logo.png";
import { Container, Nav, Navbar } from 'react-bootstrap';
import * as routeConstant from "../common/routeConstants";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const AdminHeader = () => {
    //single hooks starts here
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const { isAdmin, user, admin } = useSelector((state: RootState) => state.auth);
    const user_email = isAdmin ? admin?.user?.fName : user?.user?.fName ?? user?.email;
    //single hooks ends here

    //function starts here
    const handleLogout = () => {
        dispatch(logoutHandler())
    };
    //function ends here

    return (
        <Navbar expand="lg" className="header">
            <Container fluid>
                <Link to={routeConstant.ADMIN_DASHBOARD} className="navbar-brand">
                    <img src={Logo} height={50} alt="logo" />
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="flex-fill mt-3 mt-lg-0 mb-3 mb-lg-0 flex-wrap gap-3">
                        <div className="flex-fill"></div>
                        <div className="d-flex flex-column flex-lg-row justify-content-lg-end gap-4 gap-lg-3 mt-3 mt-lg-0 flex-grow-1">
                            <div className='d-flex d-lg-none justify-content-between gap-3'>
                                <div className='hstack gap-3'>
                                    <img className='img-fluid' src={UserProfile} alt="users" />
                                    <div className="text-black">{t('constants.Hello')}, {user_email}</div>
                                </div>
                                <button className="btn btn-primary" onClick={handleLogout}>
                                    {t('common.logout')}
                                </button>
                            </div>
                            <div className="dropstart custom-dropdown align-self-center d-none d-lg-flex">
                                <button
                                    className="btn p-0"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                >
                                    {/* <img className='img-fluid' src={UserProfile} alt="user" /> */}
                                    <FontAwesomeIcon icon={faUser} className="fs-2" />
                                </button>
                                <div className="dropdown-menu">
                                    <div className="custom-dropdown-menu">
                                        <div className="text-black">{t('constants.Hello')}, {user_email}</div>
                                        <button className="btn btn-primary" onClick={handleLogout}>
                                        {t('common.logout')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AdminHeader;