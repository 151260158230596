import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const VideoModal = ({ isOpen, setOpenVideoPopup, videoSrc, type }: any) => {
    const { t } = useTranslation("common");
    const handleCloseModal = () => {
        setOpenVideoPopup(false);
    };
    return (
        <>
            <Modal className='video-details-modal tread_video' show={isOpen} size='lg' centered >
                <Modal.Header>
                    <h5 className="modal-title fw-semibold">
                        {type}{t('constants.video')}
                    </h5>
                    <button
                        type="button"
                        aria-label="Close"
                        className='btn focus-none'
                        onClick={handleCloseModal}
                    >
                        <FontAwesomeIcon icon={faTimes} className='fs-3 text-white' />
                    </button>
                </Modal.Header>
                <div className="video_playing">
                    <video width="100%" controls>
                        <source src={videoSrc} type="video/mp4" />
                        {t('VideoProcess.browserNotSupport')}
                    </video>
                </div>
            </Modal>
        </>
    );
};

export default VideoModal;