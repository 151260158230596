import { ErrorMessage, Field } from 'formik';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { videoAlgorithmCondition, videoAlgorithmKey, conditionMapping, ispectAlgorithmKey, extraVideoAlgorithmKey } from '../../common/common';
import FormField from '../../components/FormField';
import { useEffect, useRef, useState } from 'react';

//video scroing from props starts here
interface VideoScoringFormProps {
    index: number;
    source?: string;
    removeForm: () => void;
    addForm: () => void;
    values: {
        baseFormula: string,
        data: Array<{
            videoScoringAlgorithmId?: number;
            videoAlgorithmKey?: string;
            videoAlgorithmCondition?: string;
            videoAlgorithmParams?: string;
            videoAlgorithmParamsbetween?: number;
            videoAlgorithmRewardPoint?: string;
            videoAlgorithmCustomCalc: string,
            status: string,
            extraVideoAlgorithmKey: string
        }>;
    };
    setTotalRewardPoints: any;
}
//video scroing from props ends here

const VideoScoringForm: React.FC<VideoScoringFormProps> = ({ index, source, removeForm, addForm, values, setTotalRewardPoints }) => {
    //useState starts here
    const [filteredConditions, setFilteredConditions] = useState<any>(videoAlgorithmCondition);
    const prevCondition = useRef<string | null>(null);
    //useState ends here

    //functions starts here
    const countTotalRewardPoints = () => {
        const rewardPointsWithoutIn = values.data
            .filter((item: any) => item.videoAlgorithmCondition !== "in" && item.videoAlgorithmCondition !== "not_in")
            .map((item: any) => {
                const rewardPoint = parseFloat(item.videoAlgorithmRewardPoint) || 0;
                return rewardPoint;
            })
            .reduce((acc: any, curr: any) => acc + curr, 0);
        const rewardPointsForIn = values.data
            .filter((item: any) => item.videoAlgorithmCondition === "in" || item.videoAlgorithmCondition === "not_in")
            .map((item: any) => {
                const inParamsCount = item.videoAlgorithmParams || "";
                const rewardPoints = parseFloat(item.videoAlgorithmRewardPoint) || 0;
                const splitArray = inParamsCount.split(",");
                const totalParams = splitArray.filter((param: string) => param.trim() !== "").length;
                return totalParams * rewardPoints;
            })
            .reduce((acc: any, curr: any) => acc + curr, 0);

        let totalRewardPoints = rewardPointsWithoutIn + rewardPointsForIn;
        setTotalRewardPoints(totalRewardPoints);
    };
    const currentData: any = values.data[index];
    const isAllFieldsEmpty = index === 0 &&
        !currentData.videoAlgorithmKey &&
        !currentData.videoAlgorithmCondition &&
        !currentData.videoAlgorithmParams &&
        !currentData.videoAlgorithmRewardPoint;
    const isDisabled =
        currentData.videoAlgorithmKey === "longest_silence" ||
        (currentData.videoAlgorithmKey === "words_per_minute" &&
            !["between_proportionality", "not_between_proportionality"].includes(currentData.videoAlgorithmCondition));
    const isInExist = currentData.videoAlgorithmCondition == "in" || currentData.videoAlgorithmCondition == "not_in";
    const getTotalForIn: any = () => {
        if (isInExist) {
            const inParamsCount: any = currentData.videoAlgorithmParams;
            const rewardPoints: any = currentData.videoAlgorithmRewardPoint;
            const splitArray = inParamsCount.split(",");
            const totalParams = splitArray.length;
            const totalPerKey = totalParams * rewardPoints;
            const roundedTotalPerKey = Number(totalPerKey.toFixed(2))
            return roundedTotalPerKey;
        }
    }

    // Update filtered conditions based on the selected algorithm key

    const handleKeyChange = (key: keyof typeof conditionMapping, baseFormula: string) => {
        let validConditions = [...conditionMapping[key]];
        if (baseFormula === 'ZERO') {
            validConditions = validConditions.filter(
                (condition) => condition !== 'between_proportionality' && condition !== 'not_between_proportionality'
            );
        }
        const updatedConditions = videoAlgorithmCondition?.filter((condition: any) =>
            validConditions.includes(condition.value)
        );
        setFilteredConditions([{ value: '', label: 'Select a video algorithm Condition' }, ...updatedConditions]);
    };

    const renderParamsField = () => {
        if (currentData?.videoAlgorithmCondition === 'between' || currentData?.videoAlgorithmCondition === 'calculate_btw' || currentData.videoAlgorithmCondition === 'not_between' || currentData.videoAlgorithmCondition === 'between_proportionality' || currentData?.videoAlgorithmCondition === 'not_between_proportionality') {
            return (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Field
                        as={TextField}
                        fullWidth
                        id={`data.${index}.videoAlgorithmParams`}
                        name={`data.${index}.videoAlgorithmParams`}
                        size="small"
                        variant="outlined"
                        placeholder="Start"
                    />
                    <Field
                        as={TextField}
                        fullWidth
                        id={`data.${index}.videoAlgorithmParamsbetween`}
                        name={`data.${index}.videoAlgorithmParamsbetween`}
                        size="small"
                        variant="outlined"
                        placeholder="End"
                    />
                </div>
            );
        }
        return (
            <Field
                as={TextField}
                fullWidth
                id={`data.${index}.videoAlgorithmParams`}
                name={`data.${index}.videoAlgorithmParams`}
                size="small"
                variant="outlined"
            />
        );
    };
    //functions ends here

    //useEffects starts here
    useEffect(() => {
        countTotalRewardPoints();
    }, [values]);

    useEffect(() => {
        if (currentData.videoAlgorithmKey) {
            handleKeyChange(currentData?.videoAlgorithmKey as keyof typeof conditionMapping, values?.baseFormula);
        }
    }, [currentData.videoAlgorithmKey, values.baseFormula]);
    useEffect(() => {
        if (values.baseFormula === "ZERO" && source === "qaulityScore") {
            if (
                currentData.videoAlgorithmCondition === "not_between_proportionality" ||
                currentData.videoAlgorithmCondition === "between_proportionality"
            ) {
                prevCondition.current = currentData.videoAlgorithmCondition; // Store previous value
                currentData.videoAlgorithmCondition = ""; // Reset condition
            }
        } else if (values.baseFormula === "MAXIMUM" && source === "qaulityScore") {
            if (prevCondition.current && currentData.videoAlgorithmCondition === "") {
                currentData.videoAlgorithmCondition = prevCondition.current;
            }
            prevCondition.current = currentData.videoAlgorithmCondition;
        }
    }, [values.baseFormula, currentData.videoAlgorithmCondition]);
    //useEffects ends here

    return (
        <div className="form-row">
            {/* Algorithm Key Field */}
            <FormField
                name={`data.${index}.videoAlgorithmKey`}
                fieldAs="select"
                title="Algorithm Key"
                options={source === "inspectScore" ? ispectAlgorithmKey : videoAlgorithmKey}
            />

            {/* Algorithm Condition Field */}
            <FormField
                name={`data.${index}.videoAlgorithmCondition`}
                fieldAs="select"
                title="Algorithm Condition"
                options={filteredConditions}
            />
            {isDisabled &&
                <FormField
                    name={`data.${index}.extraVideoAlgorithmKey`}
                    fieldAs='select'
                    title='Extra Algorithm Key'
                    options={extraVideoAlgorithmKey}
                />}
            {isDisabled &&
                <FormField
                    name={`data.${index}.videoAlgorithmCustomCalc`}
                    fieldAs='TextField'
                    title='Extra Calculations'
                />
            }
            <div className="form-field">
                <label htmlFor={`data.${index}.videoAlgorithmParams`}>Algorithm Params:</label>
                {renderParamsField()}
                <ErrorMessage
                    name={`data.${index}.videoAlgorithmParams`}
                    component="div"
                    className="text-danger txt-error"
                />
            </div>
            <div className="form-field">
                <FormField
                    name={`data.${index}.videoAlgorithmRewardPoint`}
                    fieldAs='TextField'
                    title={values.baseFormula == 'ZERO' ? 'Reward Points' : 'Penalty'}
                    isInExist={isInExist}
                    getTotalForIn={getTotalForIn()}
                />
            </div>
            {index == 0 &&
                <button
                    type="button"
                    className="submit-btn btn btn-primary"
                    onClick={addForm}
                    disabled={isAllFieldsEmpty}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            }
            {isDisabled || index !== 0 &&
                <button
                    type="button"
                    className="submit-btn btn btn-primary"
                    onClick={removeForm}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
            }
        </div>
    );
};

export default VideoScoringForm;