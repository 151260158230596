import { api } from './axiosInstance';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { enableLoader } from '../pages/Store/slices/commonSlice';
import { logoutHandler } from '../pages/Store/slices/authSlice';
import { RootState, store } from '../pages/Store/store';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import * as routeConstant from './routeConstants';

let abortController = new AbortController();

const AuthVerify = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { user, admin } = useSelector((state: RootState) => state.auth)
    const token = user?.token || admin?.token

    //For GET requests
    api.interceptors.request.use(
        (req) => {
            const token = store.getState().auth.user?.token || store.getState().auth.admin?.token
            const showLoader = store.getState().common.showLoader
            if (token) {
                req.headers.Authorization = 'Bearer ' + token;
            }
            req.signal = abortController.signal;
            !showLoader && dispatch(enableLoader(true))
            return req;
        },
        (err) => {
            if (err?.response?.status === 401) {
                dispatch(logoutHandler())
            } else {
                return Promise.reject(err);
            }
        }
    );

    // For POST requests
    api.interceptors.response.use(
        (res) => {
            const showLoader = store.getState().common.showLoader
            showLoader && dispatch(enableLoader(false));
            return res;
        },
        (err) => {
            const showLoader = store.getState().common.showLoader
            showLoader && dispatch(enableLoader(false));
            if (err?.response?.status === 403) {
                toast.error("Session Timeout. Please login again", {
                    position: "top-right",
                    autoClose: 2000
                })
                setTimeout(() => {
                    dispatch(logoutHandler())
                }, 2000)

                // Abort all ongoing requests
                abortController.abort();
                abortController = new AbortController();
            } else {
                return Promise.reject(err);
            }
        }
    );

    const isTokenExpired = (token: string) => {
        if (!token) return true;
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp && decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };

    useEffect(() => {
        if (token) {
            if (isTokenExpired(token)) {
                dispatch(logoutHandler());
                toast.error("Session Timeout. Please login again", {
                    position: "top-right",
                    autoClose: 2000
                })
                navigate(routeConstant.SIGNIN);
            }
        }
    }, [token]);
    return (<></>);
}
export default AuthVerify;  