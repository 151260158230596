import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

//video score rating chart props start here
interface VideoScoreRatingChartProps {
  brandsVideoRatingPercent: any;
}
//video score rating chart props ends here

const VideoScoreRatingChart: React.FC<VideoScoreRatingChartProps> = ({ brandsVideoRatingPercent }: any) => {
  //single hook start here
  const categories = brandsVideoRatingPercent?.map((data: any) => data.dealerName);
  const seriesData = brandsVideoRatingPercent?.map((data: any) => data.rating);
  const colors = brandsVideoRatingPercent?.map((data: any) => data.color);
  //single hook ends here

  //Chart options starts here
  const options: ApexOptions = {
    series: [{
      name: 'Rating',
      data: seriesData
    }],
    chart: {
      type: "bar",
      height: 380,
    },
    plotOptions: {
      bar: {
        barHeight: "30%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#2e2e2e"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    }, stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories,
    }, yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100
    },
    title: {
      text: "Dealer video rating",
      align: "center",
      floating: true,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    }
  }
  //Chart options ends here

  return (
    <div className="radial-chart">
      <Chart options={options} series={options.series} type="bar" height={450} />
    </div>
  );
};

export default VideoScoreRatingChart;
