import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { forgotPasswordHandler } from "../../Store/slices/authSlice";
import { TextField } from "@mui/material";
import { SIGNIN } from "../../../common/routeConstants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as routeConstant from "../../../common/routeConstants";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { t } = useTranslation('common');
    const emailRegex = /^(?:\s*(?:(?!\s+$)([\w.-]+(?:\s+[\w.-]+)*)@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})?\s*)?$/;

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .matches(emailRegex, t('oauth.enterValidEmail')),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const data = {
                email: values?.email
            }
            try {
                await dispatch(forgotPasswordHandler(data)).then((result: any) => {
                    if (result?.payload?.statusCode === 404) {
                        toast.error(result?.payload?.message, {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    }
                    else if (result?.payload?.statusCode === 201) {
                        toast.success(result?.payload?.message, {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                        resetForm();
                        navigate(SIGNIN)
                    }
                }).catch((error: any) => {
                    console.log(error)
                })
            } catch (error) {
                console.error(error, "error");
            }

        },
    });

    return (
        <>
            <div className="vh-100 bg-primary-light overflow-hidden">
                <Link className="btn btn-primary mt-5 ms-5" to={routeConstant.SIGNIN}>
                    <FontAwesomeIcon icon={faArrowLeft} className='me-2' />Back
                </Link>
                <div className="container vh-100">

                    <div className="row justify-content-center h-100">

                        <div className="col-md-8 col-xl-6 hstack align-items-center justify-content-center">

                            <div className="card border-0 mb-5 shadow w-100 p-4">
                                <div className="card-body">
                                    <div className="fs-4 fw-semibold mb-5 text-center hstack gap-3">
                                        <span>Forgot Password</span>
                                    </div>
                                    <form onSubmit={formik.handleSubmit} className="vstack gap-4">

                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            size="small"
                                            variant="standard"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                        <div>
                                            <button type="submit" className="btn btn-primary rounded-4 px-4 py-2 w-100">
                                                Send Verification Email
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword
