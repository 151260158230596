import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

export const register = async (userName: string, email: string, password: string) => {
    return api.post(API_URL + "auth/signup", {
        userName,
        email,
        password,
    }).then((response) => {
        return response.data;
    });;
};

export const login = async (email: string, password: string) => {
    return api
        .post(API_URL + "auth/login", {
            email,
            password,
        })
        .then((response) => {
            return response.data;
        });
};

export const userChangePassword = async (uuid: string, password: string) => {
    return api
        .post(API_URL + "auth/change-password", {
            uuid,
            password,
        })
        .then((response) => {
            return response.data;
        });
};

export const forgotPassword = async (email: string) => {
    return api
        .post(API_URL + "auth/forgot-password", {
            email
        })
        .then((response) => {
            return response.data;
        });
};

export const resetPassword = async (token: string, newPassword: string) => {
    return api
        .post(API_URL + "auth/reset-password/" + token, {
            newPassword
        })
        .then((response) => {
            return response.data;
        });
};