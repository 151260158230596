import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const RadialSemiGaugeChart = ({
    videoScoringFormula,
    avgRadialChartData,
    title,
    videoScoringAlgorithmCondition,
    videoAlgorithmParams,
    minRange,
    maxRange
}: any) => {
    //single hooks starts here
    const validData = typeof avgRadialChartData === 'number' ? avgRadialChartData : 0;
    let isConditionMet = false;
    const normalizedValue = ((validData - minRange) / (maxRange - minRange)) * 100;
    //single hooks ends here

    // video scoring algorithm condition start here
    switch (videoScoringAlgorithmCondition) {
        case 'gte':
            isConditionMet = validData >= videoAlgorithmParams;
            break;
        case 'calculate_gte':
            isConditionMet = validData >= videoAlgorithmParams;
            break;
        case 'lte':
            isConditionMet = validData <= videoAlgorithmParams;
            break;
        case 'calculate_lte':
            isConditionMet = validData <= videoAlgorithmParams;
            break;
        case 'eq':
            isConditionMet = validData === videoAlgorithmParams;
            break;
        case 'calculate_eq':
            isConditionMet = validData === videoAlgorithmParams;
            break;
        case 'neq':
            isConditionMet = validData !== videoAlgorithmParams;
            break;
        case 'calculate_neq':
            isConditionMet = validData !== videoAlgorithmParams;
            break;
        case 'gt':
            isConditionMet = validData > videoAlgorithmParams;
            break;
        case 'calculate_gt':
            isConditionMet = validData > videoAlgorithmParams;
            break;
        case 'lt':
            isConditionMet = validData < videoAlgorithmParams;
            break;
        case 'calculate_lt':
            isConditionMet = validData < videoAlgorithmParams;
            break;
        case 'between': {
            const [firstValue, secondValue] = videoAlgorithmParams.split(',').map(Number);
            isConditionMet = validData >= firstValue && validData <= secondValue;
            break;
        }
        case 'calculate_btw': {
            const [firstValue, secondValue] = videoAlgorithmParams.split(',').map(Number);
            isConditionMet = validData >= firstValue && validData <= secondValue;
            break;
        }
        case 'not_between': {
            const [firstValue, secondValue] = videoAlgorithmParams.split(',').map(Number);
            isConditionMet = validData < firstValue || validData > secondValue;
            break;
        }
        case 'between_proportionality': {
            const [firstValue, secondValue] = videoAlgorithmParams.split(',').map(Number);
            isConditionMet = validData >= firstValue && validData < secondValue;
            break;
        }
        case 'not_between_proportionality': {
            const [firstValue, secondValue] = videoAlgorithmParams.split(',').map(Number);
            isConditionMet = validData < firstValue || validData > secondValue;
            break;
        }
        default:
            isConditionMet = false;
    }

    // video scoring algorithm condition start here
    let graphColor;
    if(videoScoringFormula == "MAXIMUM"){
    graphColor = isConditionMet ? '#dc3545' : '#28a745';
    }else{
    graphColor = isConditionMet ? '#28a745' : '#dc3545';
    }

    //Chart options starts here
    const options: ApexOptions = {
        series: [normalizedValue],
        colors: [graphColor],
        chart: {
            type: 'radialBar',
            offsetY: -10,
            sparkline: { enabled: true },
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: '100%',
                    margin: 5,
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2,
                    },
                },
                dataLabels: {
                    name: { show: false },
                    value: {
                        offsetY: -2,
                        fontSize: '22px',
                        formatter: () => `${validData}`,
                    },
                },
            },
        },
        grid: { padding: { top: -10 } },
        title: {
            text: title,
            align: 'center',
            margin: 10,
            offsetY: 0,
            style: { fontSize: '18px', fontWeight: 'bold', color: '#263238' },
        },
    };
    //Charts options ends here
    return (
        <div className="radial-chart">
            <Chart options={options} series={[normalizedValue]} type="radialBar" height={390} />
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 40px' }}>
                <span>{minRange}</span>
                <span>  {maxRange}</span>
            </div>
        </div>
    );
};

export default RadialSemiGaugeChart;


