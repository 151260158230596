import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import * as Yup from 'yup';
import { Form, Formik, FieldArray, Field } from 'formik';
import VideoScoringForm from './VideoScoringForm';
import { getVideosScoreAlgorithmByBrandDealersHandler, updateVideoScoringHandler, videoScoringDataHandler } from '../Store/slices/videoSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FULFILLED, UPDATE_VIDEO_SCORING } from '../Store/actions';
import { toast } from 'react-toastify';
import * as routeConstant from '../../common/routeConstants';
import './VideoScoring.css';
import { baseOptions } from '../../common/common';

const VideoScoring: React.FC = () => {
    //single hook starts here
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const source = location.state?.source;
    const { user } = useSelector((state: RootState) => state.auth);
    const { selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.settings);
    //single hook ends here

    //useState starts here
    const [initialValues, setInitialValues] = useState({
        baseFormula: 'ZERO',
        data: [
            {
                videoScoringAlgorithmId: 0,
                videoAlgorithmKey: '',
                videoAlgorithmCondition: '',
                videoAlgorithmParams: '',
                videoAlgorithmParamsbetween: 0,
                videoAlgorithmRewardPoint: '',
                videoAlgorithmCustomCalc: '',
                extraVideoAlgorithmKey: '',
                status: 'ENABLED'
            },
        ],
    });
    const [totalRewardPoints, setTotalRewardPoints] = useState(0);
    // useState ends here 

    //   validation Schema starts here
    const validationSchema = Yup.object().shape({
        data: Yup.array().of(
            Yup.object().shape({
                videoAlgorithmKey: Yup.string()
                    .required('Video Algorithm Key is required')
                    .test(
                        'unique-selection',
                        'Words per minute or Longest silence is already selected',
                        function (value: string | undefined) {
                            const { path, options }: any = this;
                            const currentIndex = parseInt(path.match(/\d+/)?.[0] || '0');
                            const { data } = options.context;
                            const keysToValidate = ['words_per_minute', 'longest_silence'];
                            const isDuplicate = data.some(
                                (item: any, index: number) =>
                                    keysToValidate.includes(value || '') &&
                                    item.videoAlgorithmKey === value &&
                                    index !== currentIndex
                            );
                            return !isDuplicate;
                        }
                    ),
                videoAlgorithmCondition: Yup.string()
                    .required('Video Algorithm Condition is required'),
                videoAlgorithmParams: Yup.string()
                    .required('Video Algorithm Params is required')
                    .test(
                        'unique-combination',
                        'Video Algorithm Params should not be same for identical Key and Condition',
                        function (value: any) {
                            const { path, parent, options }: any = this;
                            const currentIndex = parseInt(path.match(/\d+/)?.[0] || '0');
                            const { data } = options.context;
                            const isDuplicate = data.some(
                                (item: any, index: number) =>
                                    index !== currentIndex &&
                                    item.videoAlgorithmKey === parent.videoAlgorithmKey &&
                                    item.videoAlgorithmCondition === parent.videoAlgorithmCondition &&
                                    item.videoAlgorithmParams === value
                            );
                            if (isDuplicate) {
                                return this.createError({
                                    path,
                                    message: 'Video Algorithm Params should not be same for identical Key and Condition',
                                });
                            }
                            return true;
                        }
                    )
                    .test(
                        'boolean-values-only',
                        function (value: any) {
                            const { parent } = this;
                            const booleanKeys = ["audio_muffled", "video", "profanity"];
                            const booleanConditions = ["eq", "neq"];

                            if (booleanKeys.includes(parent.videoAlgorithmKey) && booleanConditions.includes(parent.videoAlgorithmCondition)) {
                                if (value !== "true" && value !== "false") {
                                    return this.createError({
                                        path: this.path,
                                        message: 'Only boolean values are allowed',
                                    });
                                }
                            }
                            return true;
                        }
                    ).test(
                        'valid-range',
                        'Video Algorithm Params must be less than or equal to videoAlgorithmParamsbetween',
                        function (value: any) {
                            const { parent, options} :any= this;
                            const params = Number(value);
                            const paramsBetween = Number(parent.videoAlgorithmParamsbetween);
                            const { data } = options.context;
                            if (Object.prototype.hasOwnProperty.call(parent, 'videoAlgorithmParamsbetween')) {
                                if (parent.videoAlgorithmParamsbetween === undefined) {
                                    return this.createError({
                                        path: this.path,
                                        message: 'End parameters should not be empty',
                                    });
                                }
                            }
                                
                            if (['between', 'not_between', 'calculate_btw', 'not_between_proportionality','not_between_proportionality'].includes(parent.videoAlgorithmCondition)) {
                                if ((params > paramsBetween || params === paramsBetween) && data.videoAlgorithmParamsbetween) {
                                    return this.createError({
                                        path: this.path,
                                        message: 'End Params should be greater than start Params',
                                    });
                                }
                            }
                            return true;
                        }
                    ),
                videoAlgorithmRewardPoint: Yup.number()
                    .typeError('Reward Points must be a number')
                    .required('Reward Points are required')
                    .test(
                        'no-negative-maximum',
                        'Penalty cannot be negative for Base Formula MAXIMUM',
                        function (value) {
                            const { options }: any = this;
                            const { baseFormula } = options.context;
                            if (baseFormula === 'MAXIMUM' && value < 0) {
                                return false;
                            }
                            return true;
                        }
                    ),

            })
        ),
    });
    // validation Schema ends here

    //functions starts here
    function createFormula(variableName: string, operation: string) {
        const variablePlaceholder = `{${variableName}}`;
        let formula;
        if (operation.includes('/')) {
            const divisionEndIndex = operation.indexOf('*');
            const divisionPart = divisionEndIndex === -1
                ? `${variablePlaceholder}${operation}`
                : `${variablePlaceholder}${operation.slice(0, divisionEndIndex)}`;

            const remainingPart = divisionEndIndex === -1
                ? ''
                : operation.slice(divisionEndIndex);

            formula = `(${divisionPart})${remainingPart}`;
        } else {
            formula = `(${variablePlaceholder}${operation})`;
        }
        return formula;
    }

    const handleSubmit = async (values: any, formikHelpers: any) => {
        const errors = await formikHelpers.validateForm();
        if (Object.keys(errors).length) {
            return;
        }
        const payload = {
            type: source == 'qaulityScore' ? "QUALITY" : "INSPECTION",
            brandUuId: selectedBrandId,
            dealerUuId: selectedDealerId,
            userUuId: user.user.uuid,
            baseFormula: values.baseFormula,
            data: values.data.map((form: any) => ({
                videoAlgorithmKey: form.videoAlgorithmKey,
                videoAlgorithmCondition: form.videoAlgorithmCondition,
                videoAlgorithmParams: (form.videoAlgorithmCondition == 'between' || form.videoAlgorithmCondition == 'calculate_btw' || form.videoAlgorithmCondition == 'not_between' || form.videoAlgorithmCondition == 'between_proportionality' || form.videoAlgorithmCondition == 'not_between_proportionality') ? `${form.videoAlgorithmParams},${form.videoAlgorithmParamsbetween || 0}` : form.videoAlgorithmParams,
                videoAlgorithmRewardPoint: parseFloat(form.videoAlgorithmRewardPoint),
                status: form.status,
                ...((form.videoAlgorithmKey === 'words_per_minute' || form.videoAlgorithmKey === 'longest_silence') &&
                    { videoAlgorithmCustomCalc: createFormula(form.extraVideoAlgorithmKey || '', form.videoAlgorithmCustomCalc || '') })
            }))
            ,
        };
        if (totalRewardPoints !== 100 && values.baseFormula === 'ZERO') {
            toast.error(`Total of reward points must be 100,Total reward point is ${totalRewardPoints}`, {
                position: "top-right",
                autoClose: 2000
            });
            return;
        }
        if (totalRewardPoints == 100 && values.baseFormula === 'ZERO' || values.baseFormula === 'MAXIMUM') {
            dispatch(updateVideoScoringHandler(payload)).then((response: any) => {
                if (response?.type === `${UPDATE_VIDEO_SCORING}/${FULFILLED}`) {
                    toast.success("Video scoring algorithm updated successfully", {
                        position: "top-right",
                        autoClose: 2000
                    });
                    navigate(routeConstant.VIDEOS);
                } else {
                    toast.error("Some error occur", {
                        position: "top-right",
                        autoClose: 2000
                    })
                }
            })
        }
    };

    function parseFormula(formula: any) {
        const match = formula.match(/^\(?\{?(\w+)\}?\)?(.*)$/);
        if (match) {
            const variable = match[1];
            let operation = match[2].trim();
            operation = operation.replace(/[()]+/g, '');
            const isValidOperation = /^[\w\s\+\-\*\/\.]+$/.test(operation);
            if (!isValidOperation) {
                return { variable, operation: null };
            }
            return { variable, operation };
        }
        return { variable: null, operation: null };
    }

    const handleVideoScoringAlgorithmData = async (data: any) => {
        await dispatch(getVideosScoreAlgorithmByBrandDealersHandler(data)).then((res: any) => {
            dispatch(videoScoringDataHandler(res?.payload))
            const updatedData = res.payload.map((item: any) => {
                let videoAlgorithmCustomCalc, extraVideoAlgorithmKey;
                if (item.videoAlgorithmCustomCalc) {
                    const formula = item.videoAlgorithmCustomCalc;
                    const formulaParsed = parseFormula(formula);
                    videoAlgorithmCustomCalc = formulaParsed.operation;
                    extraVideoAlgorithmKey = formulaParsed.variable;
                }
                if ((item.videoAlgorithmCondition === 'between' || item.videoAlgorithmCondition === 'calculate_btw' || item.videoAlgorithmCondition === 'not_between' || item.videoAlgorithmCondition === "between_proportionality" || item.videoAlgorithmCondition === 'not_between_proportionality') && item.videoAlgorithmParams.includes(',')) {
                    const [firstValue, secondValue] = item.videoAlgorithmParams.split(',');
                    return {
                        ...item,
                        videoAlgorithmParams: firstValue,
                        videoAlgorithmParamsbetween: secondValue,
                        videoAlgorithmCustomCalc,
                        extraVideoAlgorithmKey,
                    };
                }
                return {
                    ...item,
                    videoAlgorithmCustomCalc,
                    extraVideoAlgorithmKey,
                };
            });
            setInitialValues((prevState) => ({
                ...prevState,
                baseFormula: updatedData[0]?.videoScoringAlgorithm?.baseFormula || prevState.baseFormula,
                data: updatedData,
            }));
        }).catch((err: any) => {
            toast.error("Some error occur", {
                position: "top-right",
                autoClose: 2000
            })
        });
    };
    //functions ends here

    //useEffects starts here
    useEffect(() => {
        const data = {
            brandId: selectedBrandId,
            type: source == 'qaulityScore' ? "QUALITY" : "INSPECTION"
        }
        handleVideoScoringAlgorithmData(data);
    }, [selectedBrandId, source]);
    //useEffects ends here

    return (
        <Formik
            enableReinitialize={true}
            validateOnBlur={true} 
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setTouched  }) => (

                <div className="row mb-5">
                    <div className='col-4'><h3>Video Scoring Algorithm</h3></div>
                    <div className='col-4 text-end mt-2'><label>Total Reward points : {values.baseFormula == 'MAXIMUM' ? 100 : Number(totalRewardPoints.toFixed(2))} </label></div>
                    <div className='col-1 text-end mt-2'><label>Base :</label></div>
                    <div className='col-3'>
                        <Field
                            as="select"
                            name="baseFormula"
                            className="form-select"
                            onChange={(e:any) => {
                                setFieldValue("baseFormula", e.target.value);
                                setTouched({});
                              }}
                        >
                            {baseOptions.map(({ value, label }: any) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </Field>
                    </div>
                    <div className="upload-area">
                        <Form>
                            <FieldArray
                                name="data"
                                render={({ push, remove }) => (
                                    <>
                                        {values.data.map((_, index) => (
                                            <div key={index}>
                                                <VideoScoringForm
                                                    index={index}
                                                    source={source}
                                                    addForm={() =>
                                                        push({
                                                            videoScoringAlgorithmId: 0,
                                                            videoAlgorithmKey: '',
                                                            videoAlgorithmCondition: '',
                                                            videoAlgorithmParams: '',
                                                            videoAlgorithmParamsbetween: 0,
                                                            videoAlgorithmRewardPoint: '',
                                                            videoAlgorithmCustomCalc: '',
                                                            status: 'ENABLED'
                                                        })
                                                    }
                                                    removeForm={() => remove(index)}
                                                    values={values}
                                                    setTotalRewardPoints={setTotalRewardPoints}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            />
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                            <p><label className='text-danger mt-2'>{values.baseFormula == 'ZERO' && `Note : Total of reward points must be 100.`}</label>
                            </p>
                        </Form>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default VideoScoring;