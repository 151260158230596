import { FC } from 'react';

// Delete props starts here
interface DeleteProps {
    show: any;
    deleteText: string;
    btncancel: string;
    btnyes: string;
    clickOk: any;
    clickCancel: any;
    color: any;
}
// Delete props ends here

const DeleteDialogBox: FC<DeleteProps> = ({ show, deleteText, btncancel, btnyes, clickOk, clickCancel, color }) => {

    return show ? (
        <div className='delete-confirm'>
            <div className='inner card p-2'>
                <p className='delete-confirm-title font-color'>{deleteText}</p>
                <div className='d-flex justify-content-end gap-2'>
                    <button className='btn btn-sm btn-secondary small-btn' type='button' onClick={() => { clickCancel() }}>{btncancel}</button>
                    <button className={`btn btn-sm ${color} text-white small-btn`} type='button' onClick={() => { clickOk() }}>{btnyes}</button>
                </div>
            </div>
        </div >
    ) : null;
}

export default DeleteDialogBox;