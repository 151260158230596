import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { asignedBrandAndDealer, createUser, deleteUser, getBrandDealersUserByUuid, getUserByUuid, getUserDetailsByUuid, updateUserByUuid, userData } from '../../../services/users.service';

// Define the initial state for user data
interface UserState {
    users: any[];
    loading: boolean;
    error: string | null;
    totalUsersRecord: number
    totalPage: number
    editUserId: string
}

const initialState: UserState = {
    users: [],
    loading: false,
    error: null,
    totalUsersRecord: 0,
    totalPage: 0,
    editUserId: ''
};


export interface IUserLogin {
    pageLimit: number,
    perPage: number,
    searchVal: string,
    sortColumn: string,
    sortType: string
}

export interface CreateUserPayload {
    username: string;
    email: string;
    status: string;
    contact: string;
    language: string
}
// Create an async thunk for fetching user data
export const getAllUserHandler: any = createAsyncThunk('users/fetchUsers', (data: IUserLogin, { rejectWithValue }) =>
    userData(data?.pageLimit, data?.perPage, data?.searchVal, data?.sortColumn, data?.sortType).catch((error) => error && rejectWithValue(error))
)

export const createUserHandler: any = createAsyncThunk('createUser', (data: any, { rejectWithValue }) =>
    createUser(data).catch((error) => error && rejectWithValue(error))
);
export const getUserByIdHandler: any = createAsyncThunk('getUserById', (data: any, { rejectWithValue }) =>
    getUserByUuid(data).catch((error) => error && rejectWithValue(error))
)
export const deleteUserHandler: any = createAsyncThunk(
    "deleteUser",
    (data: any, { rejectWithValue }) =>
        deleteUser(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const updateUserByUuidhandler: any = createAsyncThunk('updateUserByUuid', (data: any, { rejectWithValue }) =>
    updateUserByUuid(data).catch((error) => error && rejectWithValue(error))
)
export const getBrandDealerByUserUuidHandler: any = createAsyncThunk('getBrandDealerByUserUuid', (data: any, { rejectWithValue }) =>
    getBrandDealersUserByUuid(data).catch((error) => error && rejectWithValue(error))
)
export const getUserDetailsByUuidHandler: any = createAsyncThunk('getUserDetailsByUuid', (data: any, { rejectWithValue }) =>
    getUserDetailsByUuid(data).catch((error) => error && rejectWithValue(error))
)
export const asignedBrandAndDealerHandler: any = createAsyncThunk('asignedBrandAndDealer', (data: any, { rejectWithValue }) =>
    asignedBrandAndDealer(data?.uuid , data?.brandAndDealer).catch((error) => error && rejectWithValue(error))
)
// Created a slice for user data management
const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        editUserByIdHandler: (state, { payload }: PayloadAction<any>) => {
            state.editUserId = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUserHandler.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
        builder.addCase(getAllUserHandler.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.users = action.payload.data;
            state.totalPage = action.payload.totalPages;
            state.totalUsersRecord = action.payload.totalItems
        })
        builder.addCase(getAllUserHandler.rejected, (state, action) => {
            state.loading = false;
        })
    }
});

export default userSlice.reducer;

// Export action creators
export const { editUserByIdHandler } = userSlice.actions;
