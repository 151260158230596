import { Language } from "../types/user.type"

export const defaultLanguage: Language = { "value": "en", "label": "English" }

export const languages: Language[] = [
    { "value": "af", "label": "Afrikaans" },
    { "value": "ar", "label": "Arabic" },
    { "value": "hy", "label": "Armenian" },
    { "value": "az", "label": "Azerbaijani" },
    { "value": "be", "label": "Belarusian" },
    { "value": "bs", "label": "Bosnian" },
    { "value": "bg", "label": "Bulgarian" },
    { "value": "ca", "label": "Catalan" },
    { "value": "zh", "label": "Chinese" },
    { "value": "hr", "label": "Croatian" },
    { "value": "cs", "label": "Czech" },
    { "value": "da", "label": "Danish" },
    { "value": "nl", "label": "Dutch" },
    { "value": "en", "label": "English" },
    { "value": "et", "label": "Estonian" },
    { "value": "fi", "label": "Finnish" },
    { "value": "fr", "label": "French" },
    { "value": "gl", "label": "Galician" },
    { "value": "de", "label": "German" },
    { "value": "gj", "label": "Gujarati" },
    { "value": "el", "label": "Greek" },
    { "value": "he", "label": "Hebrew" },
    { "value": "hi", "label": "Hindi" },
    { "value": "hu", "label": "Hungarian" },
    { "value": "is", "label": "Icelandic" },
    { "value": "id", "label": "Indonesian" },
    { "value": "it", "label": "Italian" },
    { "value": "ja", "label": "Japanese" },
    { "value": "kn", "label": "Kannada" },
    { "value": "kk", "label": "Kazakh" },
    { "value": "ko", "label": "Korean" },
    { "value": "lv", "label": "Latvian" },
    { "value": "lt", "label": "Lithuanian" },
    { "value": "mk", "label": "Macedonian" },
    { "value": "ms", "label": "Malay" },
    { "value": "mr", "label": "Marathi" },
    { "value": "mi", "label": "Maori" },
    { "value": "ne", "label": "Nepali" },
    { "value": "no", "label": "Norwegian" },
    { "value": "fa", "label": "Persian" },
    { "value": "pl", "label": "Polish" },
    { "value": "pt", "label": "Portuguese" },
    { "value": "ro", "label": "Romanian" },
    { "value": "ru", "label": "Russian" },
    { "value": "sr", "label": "Serbian" },
    { "value": "sk", "label": "Slovak" },
    { "value": "sl", "label": "Slovenian" },
    { "value": "es", "label": "Spanish" },
    { "value": "sw", "label": "Swahili" },
    { "value": "sv", "label": "Swedish" },
    { "value": "tl", "label": "Tagalog" },
    { "value": "ta", "label": "Tamil" },
    { "value": "th", "label": "Thai" },
    { "value": "tr", "label": "Turkish" },
    { "value": "uk", "label": "Ukrainian" },
    { "value": "ur", "label": "Urdu" },
    { "value": "vi", "label": "Vietnamese" },
    { "value": "cy", "label": "Welsh" }
]

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const specialCharacterAndAlphabetRegex = /^(?=.*[a-zA-Z])^[a-zA-Z0-9]*$/;
export const userStatus = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "DELETED", label: "DELETED" },
    { value: "SUSPENDED", label: "SUSPENDED" }
]

export const extraVideoAlgorithmKey: any[] = [
    { value: '', label: 'Select a video algorithm key' },
    { value: 'video_length', label: 'Video Length' },
    { value: 'words_per_minute', label: 'Words Per Minute' },
    { value: 'longest_silence', label: 'Longest Silence' },
    { value: 'voice_volume', label: 'Voice Volume' },
    { value: 'loud_noises', label: 'Loud Noises' },
    { value: 'fps', label: 'FPS' }
];
export const videoAlgorithmKey: any[] = [
    { value: '', label: 'Select a video algorithm key' },
    { value: 'video_length', label: 'Video Length' },
    { value: 'words_per_minute', label: 'Words Per Minute' },
    { value: 'longest_silence', label: 'Longest Silence' },
    { value: 'voice_volume', label: 'Voice Volume' },
    { value: 'loud_noises', label: 'Loud Noises' },
    { value: 'profanity', label: 'Profanity' },
    { value: 'audio_muffled', label: 'Audio Muffled' },
    { value: 'video', label: 'Video Display' },
    { value: 'dealer_name', label: 'Dealer Name' },
    { value: 'car_model', label: 'Car Model Name' },
    { value: 'languages', label: 'Languages' },
    { value: 'fps', label: 'FPS' }
];
export const ispectAlgorithmKey: any[] = [
    { value: '', label: 'Select a video algorithm key' },
    { value: 'monitored_keywords_found', label: 'Monitored Keywords Found' },
    { value: 'custom_objects', label: 'Custom Objects' },
];

export const conditionMapping = {
    video_length: ['between', 'not_between', 'between_proportionality', 'not_between_proportionality', 'gte', 'lte', 'gt', 'lt'],
    fps: ['between', 'not_between', 'between_proportionality', 'not_between_proportionality', 'gte', 'lte', 'eq', 'neq', 'gt', 'lt',],
    words_per_minute: ['calculate_btw', 'between_proportionality', 'not_between_proportionality', 'calculate_gt', 'calculate_lt', 'calculate_neq', 'calculate_gte', 'calculate_lte'],
    longest_silence: ['calculate_btw', 'calculate_gt', 'calculate_lt', 'calculate_neq', 'calculate_gte', 'calculate_lte'],
    voice_volume: ['between', 'not_between', 'between_proportionality', 'not_between_proportionality', 'gte', 'lte', 'eq', 'neq', 'gt', 'lt'],
    loud_noises: ['between', 'not_between', 'between_proportionality', 'not_between_proportionality', 'gte', 'lte', 'eq', 'neq', 'gt', 'lt'],
    monitored_keywords_found: ['in', 'not_in'],
    custom_objects: ['in', 'not_in'],
    profanity: ['eq', 'neq'],
    audio_muffled: ['eq', 'neq'],
    video: ['eq', 'neq'],
    dealer_name: ['eq', 'neq'],
    car_model: ['eq', 'neq'],
    languages: ['eq', 'neq', 'in'],
} as const;

export const videoAlgorithmCondition = [
    { value: '', label: 'Select a video algorithm Condition' },
    { value: 'between', label: 'Between' },
    { value: 'not_between', label: 'Not between' },
    { value: 'between_proportionality', label: 'Between Proportionality' },
    { value: 'not_between_proportionality', label: 'Not Between Proportionality' },
    { value: 'gte', label: 'Greater Than or Equal To' },
    { value: 'lte', label: 'Less Than or Equal To' },
    { value: 'eq', label: 'Equal To' },
    { value: 'neq', label: 'Not Equal To' },
    { value: 'gt', label: 'Greater Than' },
    { value: 'lt', label: 'Less Than' },
    { value: 'in', label: 'In' },
    { value: 'not_in', label: 'Not In' },
    { value: 'calculate_gt', label: 'Calculate gt' },
    { value: 'calculate_lt', label: 'calculate lt' },
    { value: 'calculate_eq', label: 'Calculate eq' },
    { value: 'calculate_neq', label: 'Calculate neq' },
    { value: 'calculate_btw', label: 'Calculate btw' },
    { value: 'calculate_gte', label: 'Calculate gte' },
    { value: 'calculate_lte', label: 'Calculate lte' }
    ,
];


export const baseOptions = [
    { value: 'ZERO', label: 'ZERO' },
    { value: 'MAXIMUM', label: 'MAXIMUM' },
]

export const reportsOptions = [
    "Dealer wise Video Object",
    "Total Videos brand wise",
    "Dealer wise average Video length",
    "Dealer wise noisy rate",
    "Dealer wise failure rate",
    "Dealer wise success rate",
    "Dealer Video Score for each Brand",
    "Brand wise video score",
]
export const scoringAlgoOptions = [
    "Quality Scoring",
    "Inspect Scoring"
]