import React, { useEffect, useRef, useState } from 'react'
import Papa from "papaparse";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../pages/Store/store';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload, faEye, faRotateForward, faUpload } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, TableCell, TableContainer, TableHead, TableRow, TextField, Table, TableBody, IconButton, Tooltip } from '@mui/material';
import { Pair } from '../types/user.type';
import { getBulkUploadBtachHandler, retryBulkVideoAnalysisHandler, videoUploadFromExcelHandler } from '../pages/Store/slices/videoSlice';
import ReactPaginate from 'react-paginate';
import { ShowBulkStatusModalHandler } from '../pages/Store/slices/modalSlice';
import BulkUploadVideoStatusModal from './Modal/BulkUploadVideoStatusModal';
import socket from '../socket/socket';

const BulkVideoUpload = () => {
    //single hooks starts here
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const SUPPORTED_EXCEL_FORMATS = ["text/csv", "text/xls", "text/xlsx"];
    const MAX_FILE_SIZE = 1024 * 1024 * 500; // 100MB
    //single hooks ends here

    //useRef starts here
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    //useRef ends here

    //useSelector starts here
    const { user, userBrands } = useSelector((state: RootState) => state.auth);
    const { selectedDealerId } = useSelector((state: RootState) => state.settings);
    const userId = user?.user?.uuid ?? user?.uuid;
    //useSelector ends here

    //useState starts here
    const [fileName, setFileName] = useState<string>('');
    const [selectedBrandId, setSelectedBrandId] = useState<string>('');
    const [batchData, setBatchData] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const limit: any = 10;
    const [totalRecords, setTotalRecords] = useState(20);
    const paginationSize: any = 10;
    const [batchId, setBtachId] = useState<any>('');
    //useState ends here

    //function starts here
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files) {
            const file = event?.target?.files[0];
            event.target.value = '';
            validateAndUpload(file);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event?.dataTransfer?.files) {
            const file = event?.dataTransfer?.files[0];
            validateAndUpload(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const validateAndUpload = (file: File) => {
        if (!selectedBrandId) {
            toast.error(t('common.pleaseSelectBrandFirst'), {
                position: "top-right",
                autoClose: 2000,
            });
            setFileName('');
            return;
        }
        if (file?.size > MAX_FILE_SIZE) {
            toast.error(t('commonToastMsg.fileSizeLimit'), {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }
        if (!SUPPORTED_EXCEL_FORMATS.includes(file.type)) {
            toast.error(t('commonToastMsg.fileFormat'), {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }
        uploadExcelFiles(file)
        setFileName(file.name);
    };

    const handleBrandChange = async (e: any) => {
        const brandUuid = e.target.value
        setSelectedBrandId(brandUuid);
    };

    const uploadExcelFiles = async (file: any) => {
        try {
            if (!file) {
                throw new Error(t('common.fileNotFound'));
            }
            const parseCSV = (file: File): Promise<any[]> => {
                return new Promise((resolve, reject) => {
                    Papa.parse(file, {
                        header: true,
                        skipEmptyLines: true,
                        complete: (result: any) => resolve(result.data),
                        error: (error: any) => reject(error),
                    });
                });
            };
            const csvData: any = await parseCSV(file);
            const filteredData = csvData.map((row: any) => ({
                dealer_name: row.dealer_name,
                video_link: row.video_link,
                order_id: row.order_id,
                ro_number: row.ro_number,
                technician: row.technician
            }));
            if (!csvData[0].video_link || !csvData[0].dealer_name) {
                toast.error(t('common.invalidFile'), {
                    position: "top-right",
                    autoClose: 2000,
                });
                return
            }
            const data: any = {
                brandId: selectedBrandId,
                urlsArray: filteredData,
                uuid: selectedDealerId
            }
            const newData = {
                data: data,
                userId: userId,
                excelFile: file
            }

            await dispatch(videoUploadFromExcelHandler(newData)).then((result: any) => {
                getBulkUploadBtachAndStatus()
                toast.success(result.payload.message, {
                    position: "top-right",
                    autoClose: 2500,
                });
                setFileName('');
                setSelectedBrandId('');

            }).catch((err: any) => {

            });
        } catch (error) {
            console.error('Error reading the CSV file:', error);
        }
    };

    const getBulkUploadBtachAndStatus = async () => {
        const data: any = {
            pageNumber: pageNumber,
            limit: limit,
            userId: userId
        }
        await dispatch(getBulkUploadBtachHandler(data)).then((result: any) => {
            setBatchData(result?.payload?.data);
            setTotalRecords(result.payload?.totalItems);
            dispatch(ShowBulkStatusModalHandler(false));
        }).catch((err: any) => {
            console.log(err)
        });
    }

    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        setPageNumber(newPageNumber);
        setPageNumber(newPageNumber);
    };
    //function ends here

    //useEffects starts here
    useEffect(() => {
        getBulkUploadBtachAndStatus()
    }, [pageNumber]);

    useEffect(() => {
        socket.on('bulk-upload-data-update-res', async (data: any) => {
            const updatedData = await batchData?.map((e: any) => {
                let newVideo = {};
                if (data.batchId !== e.id) {
                    newVideo = e;
                } else {
                    newVideo = {
                        batchId: e.batchId,
                        fileLink: data.fileLink ?? e.fileLink,
                        pendingCount: data.statusCount.PENDING,
                        queuedCount: data.statusCount.QUEUED,
                        failedCount: data.statusCount.FAILED,
                        successfulCount: data.statusCount.SUCCESSFUL
                    };
                }
                return newVideo;
            });
            setBatchData(updatedData);
        });

        return (() => {
            socket.off('bulk-upload-data-update-res')
        })
    }, [batchData])
    //useEffect ends here

    const handleRetry = async (batchId: string) => {
        await dispatch(retryBulkVideoAnalysisHandler(batchId)).then(async (res: any) => {
            toast.success(t('VideoProcess.videoProcessing'), {
                position: "top-right",
                autoClose: 2000,
            });
            getBulkUploadBtachAndStatus();
        }).catch((err: any) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 2000,
            });
        });
    }

    return (
        <>
            <div className='row gy-4 mb-5 d-flex justify-content-center'>
                <div className="col-lg-6">
                    <div className='upload-area'>
                        <div className="text-center">
                            <div>
                                <div className="my-4">
                                    <FontAwesomeIcon icon={faUpload} size="2x" className='mb-3' />
                                    <p className='fs-4 fw-bold'>{t('Dashboard.dragDropPlaceholder')}</p>
                                    <div className='text-black'>{t('Dashboard.supportedExcelPropsPlaceholder')}</div>
                                </div>
                                {fileName && <p className='mb-0 mt-2'>{t('Dashboard.selectFile')}: {fileName}</p>}
                            </div>
                            <div className='mb-4'>
                                {userBrands && userBrands.length > 0 &&
                                    <TextField
                                        select
                                        label="Select Brand"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedBrandId}
                                        onChange={handleBrandChange}
                                        style={{ textAlign: 'start' }}
                                    >
                                        {userBrands.map((option: Pair, index: number) => (
                                            <MenuItem className='text-align-start' key={option.value + index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center mb-4 gap-4'>
                                <button
                                    className='btn btn-primary custom-btn px-4'
                                    onClick={() => window.open(`${process.env.PUBLIC_URL}/sampleFile.csv`, '_blank')}
                                >
                                    <FontAwesomeIcon icon={faDownload} className='me-2' />
                                    {t('common.downloadSampleFile')}
                                </button>
                                <button className='btn btn-primary custom-btn px-4' onClick={handleClick}>
                                    <FontAwesomeIcon icon={faUpload} className='me-2' />
                                    {t('Dashboard.browseFile')}
                                </button>
                            </div>
                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onClick={handleClick}
                            >
                                <input
                                    type="file"
                                    accept=".csv , .xlxs"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-container">
                <div>
                    <TableContainer className="custom-table mb-3">
                        <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>
                                        {t('common.BatchId')}
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }}>
                                        {t('common.FileDownloadLink')}
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }} className="text-status">
                                        {t('common.status')}
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                                        {t('constants.action')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchData && batchData.length > 0 ? batchData?.map((row: any) => {
                                    return (
                                        <TableRow key={row.batchId} className="mb-4">
                                            <TableCell sx={{ padding: '8px' }}>
                                                <div className="d-flex align-items-center gap-3">
                                                    <div>{row.batchId}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell sx={{ padding: '0 8px' }}>
                                                <a href={row?.fileLink}
                                                    download
                                                    className="link-color"
                                                >
                                                    {row?.fileLink?.split('/')[row?.fileLink.split('/').length - 1]?.split('?')[0]}
                                                </a>  <FontAwesomeIcon icon={faDownload} />
                                            </TableCell>
                                            <TableCell sx={{ padding: '0 8px' }} className="text-start">
                                                <span className='text-primary'> <b>{t('common.QUEUED')}:- </b>  </span>{row.queuedCount ?? '0'}
                                                <br />
                                                <span className='text-warning'><b>{t('common.PENDING')}:- </b> </span>{row.pendingCount ?? '0'} <br />
                                                <span className='text-danger'>
                                                    <b> {t('common.FAILED')}:- </b>
                                                </span>
                                                {row.failedCount ?? '0'}
                                                {row.failedCount > 0 && (
                                                    <Tooltip placement="top" title={t('constants.retry')}>
                                                        <IconButton className='size-34' onClick={() => handleRetry(row.batchId)}  >
                                                            <FontAwesomeIcon icon={faRotateForward} size='xs' />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <br />
                                                <span className='text-success'> <b>{t('common.SUCCESSFUL')}:- </b>  </span>{row.successfulCount ?? '0'} </TableCell>
                                            <TableCell sx={{ padding: '8px' }} align='center'>
                                                <button className='btn btn-primary custom-btn' onClick={() => { dispatch(ShowBulkStatusModalHandler(true)); setBtachId(row.batchId) }}>
                                                    <FontAwesomeIcon icon={faEye} className='me-2' />
                                                    {'View Details'}
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <tr><td colSpan={7} style={{ "textAlign": "center" }}>{t('common.noVideoFound')}</td></tr>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
                <div className="d-flex align-items-center justify-content-end">
                    {batchData && batchData.length > 0 &&
                        <ReactPaginate
                            pageCount={Math.ceil(totalRecords / paginationSize)}
                            previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
                            nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
                            onPageChange={({ selected }) => onHandleFetchMore(selected)}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                        />
                    }
                </div>
            </div>
            <BulkUploadVideoStatusModal batchId={batchId} />
        </>
    )
}
export default BulkVideoUpload
