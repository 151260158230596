import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { createAuthClient, createWebhook, deleteOAuth, getAllClientCredential, getSelectedClientCredential, getWebhookById, getWebhookHistory, getWebhookHistoryById, updateAuthClient, updateWebhook } from "../../../services/oauth.service";

interface ModalState {
  isOpen: boolean;
  clientData: any[];
  selectedClientData: any;
  paginationData: any;
  isUpdate: boolean;
  selectedOauthClient: any;
  showEventNotification: boolean;
  showWebhookHistoryModal:boolean;
}

const initialState: ModalState = {
  isOpen: false,
  clientData: [],
  selectedClientData: {},
  paginationData: {},
  isUpdate: false,
  selectedOauthClient: '',
  showEventNotification: false,
  showWebhookHistoryModal: false
};

export const submitOauthClient: any = createAsyncThunk(actions.OAUTH_ADD, (data: any, { rejectWithValue }) =>
  createAuthClient(data).catch((error) => error && rejectWithValue(error)))

export const updateOauthClient: any = createAsyncThunk(actions.OAUTH_UPDATE,
  (data, { rejectWithValue }) =>
    updateAuthClient(data).catch(
      (error) => error && rejectWithValue(error)
    )
);

export const getAllClientData: any = createAsyncThunk(actions.SCRATCH_DETECT_VIDEO,
  (data, { rejectWithValue }) =>
    getAllClientCredential(data).catch(
      (error) => error && rejectWithValue(error)
    )
);

export const getCredentialForSelected: any = createAsyncThunk(actions.OAUTH_EDIT,
  (data, { rejectWithValue }) =>
    getSelectedClientCredential(data).catch(
      (error) => error && rejectWithValue(error)
    )
);
export const deleteOauthHandler: any = createAsyncThunk(actions.OAUTH_DELETE, (data: any, { rejectWithValue }) =>
  deleteOAuth(data).catch((error) => error && rejectWithValue(error))
);

export const createWebhookHandler: any = createAsyncThunk(actions.CREATEWEBHOOK, (data: any, { rejectWithValue }) => {
  createWebhook(data).catch((error) => error && rejectWithValue(error))
}
)

export const getWebhookByIdHandler: any = createAsyncThunk(actions.GETWEBHOOKBYID, (userId: any, { rejectWithValue }) =>
  getWebhookById(userId).catch((error) => error && rejectWithValue(error))
)

export const updateWebhookHandler: any = createAsyncThunk(actions.UPDATEWEBHOOK, (data: any, { rejectWithValue }) =>
  updateWebhook(data).catch((error) =>
    error && rejectWithValue(error)
  )
)
export const getWebhookHistoryHandler: any = createAsyncThunk(actions.GETWEBHOOKHISTORY, (data: any, { rejectWithValue }) =>
  getWebhookHistory(data).catch((error: any) =>
    error && rejectWithValue(error)
  )
)
export const webhookHistoryByIdHandler: any = createAsyncThunk(actions.GETWEBHOOKHISTORYBYID, (data: any, { rejectWithValue }) =>
  getWebhookHistoryById(data).catch((error: any) =>
    error && rejectWithValue(error)
  )
)

const oauthSlice = createSlice({
  name: 'oauthSlice',
  initialState,
  reducers: {
    openModal(state) {
      state.isOpen = true;
    },
    closeModal(state) {
      state.isOpen = false;
    },
    toggleModal(state) {
      state.isOpen = !state.isOpen;
    },
    showEventNotificationModal: (state) => {
      state.showEventNotification = true;
    },
    closeEventNotificationModal: (state) => {
      state.showEventNotification = false;
    },
    showWebhookHistoryModal: (state) =>{
      state.showWebhookHistoryModal = true;
    },
    closeWebhookHistoryModal:(state) =>{
      state.showWebhookHistoryModal = false;
    }
    
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOauthClient.pending, (state, action) => {
      })
      .addCase(submitOauthClient.fulfilled, (state, action) => {
        state.isUpdate = !state.isUpdate;
        state.isOpen = false
      })
      .addCase(submitOauthClient.rejected, (state, action) => {
        state.isOpen = true
      })
      .addCase(getAllClientData.pending, (state, action) => {
      })
      .addCase(getAllClientData.fulfilled, (state, action) => {
        state.clientData = action.payload?.data;
        state.paginationData = action.payload
      })
      .addCase(getAllClientData.rejected, (state, action) => {
        console.error('request failed:', action.error.message);
      })
      .addCase(getCredentialForSelected.pending, (state, action) => {
      })
      .addCase(getCredentialForSelected.fulfilled, (state, action) => {
        state.selectedClientData = action.payload?.data;
      })
      .addCase(getCredentialForSelected.rejected, (state, action) => {
        console.error('request failed:', action.error.message);
      })
      .addCase(updateOauthClient.pending, (state, action) => {
      })
      .addCase(updateOauthClient.fulfilled, (state, action) => {
        state.isUpdate = !state.isUpdate;
        state.isOpen = false
      })
      .addCase(updateOauthClient.rejected, (state, action) => {
        console.error('request failed:', action.error.message);
      })
      .addCase(deleteOauthHandler.pending, (state, action) => {
      })
      .addCase(deleteOauthHandler.fulfilled, (state, action) => {
        state.isUpdate = !state.isUpdate;
      })
      .addCase(deleteOauthHandler.rejected, (state, action) => {
        console.error('request failed:', action.error.message);
      })
  },
});

export const { openModal, closeModal, toggleModal, showEventNotificationModal, closeEventNotificationModal,showWebhookHistoryModal,closeWebhookHistoryModal} = oauthSlice.actions;

export default oauthSlice.reducer;
