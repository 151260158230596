import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_DASHBOARD, SELECT_BRAND_DEALERS } from '../../common/routeConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFilter, faPlus, faMinus,  } from '@fortawesome/free-solid-svg-icons';
import { deleteUserAssignedBrandDealerHandler, getUserAssignedBrandDealerHandler } from '../Store/slices/dealerSlice';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import BrandDealerFilter from '../../components/BrandDealerFilter';
import ReactPaginate from 'react-paginate';
import Previous from "../../assets/images/previous-icon.svg";
import Next from "../../assets/images/next.svg";
import { toast } from 'react-toastify';
import DeleteDialogBox from '../../components/VideoDeleteModal';
import { useTranslation } from 'react-i18next';

const ViewUserBrandsDealers = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId: any = useParams();
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState<any>({});
    const [selectedDealer, setSelectedDealer] = useState<any>({});
    const [filterValue, setFilterValue] = useState<string>("");
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const limit = 10;
    const paginationSize = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [selectedIds, setSelectedIds]: any = useState([]);
    const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false); //for select page number wise all data
    const [isAllSelectedData, setIsAllSelectedData] = useState<boolean>(false); // for select whole data

    const handleApply = () => {
        setShowFilter(false);
        setPageNumber(1);
        setFilterValue(filterValue);
        setSelectedBrand(selectedBrand);
        setSelectedDealer(selectedDealer);
        if (selectedBrand || selectedDealer || filterValue) {
            fetchData(1, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
        }
    };

    const handleReset = () => {
        setShowFilter(!showFilter);
        setSelectedDealer({});
        setSelectedBrand({});
        setFilterValue("");
        setPageNumber(1);
        setTimeout(() => {
            fetchData(1, "", "", "");
        }, 0);
    };

    const fetchData = async (page: any, brandUuid?: any, dealerUuid?: any, searchVal?: any) => {
        setLoading(true);
        try {
            const payload = {
                userUuid: userId.uuid,
                brandUuid: brandUuid || "",
                dealerUuid: dealerUuid || "",
                searchVal: searchVal || "",
                pageNumber: page || 1,
                limit
            }
            const response = await dispatch(getUserAssignedBrandDealerHandler(payload));
            if (response?.type === 'getDealersForUser/fulfilled') {
                setLoading(false);
                const data = response?.payload?.data || [];
                setData(data?.mappingData)
                setTotalRecords(data?.totalItems);
                setCurrentPage(data?.currentPage);
                setIsLastPage(data?.currentPage === data?.totalPages);
                setIsFirstPage(data?.currentPage === 1);
                setSelectedIds(selectedIds)
                setIsAllSelected(false);
            }
        } catch (error) {
            setLoading(false);
            throw error
        }
    }

    useEffect(() => {
        fetchData(pageNumber, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
    }, []);

    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        setPageNumber(newPageNumber);
        if (selectedBrand) {
            fetchData(newPageNumber, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
        }
    };

    const handleFilterChange = () => {
        setShowFilter(!showFilter);
        if (!showFilter) {
            handleReset();
        }
    }

    const checkAllHandler = (checked: boolean, currentPageDealers: any[]) => {
        setIsAllSelected(checked);
        if (checked) {
            const newSelectedIds = data.map((item: any) => item?.mapUserBrandDealer).filter((uuid: any) => !selectedIds.includes(uuid));;
            setSelectedIds((prev: any) => [...prev, ...newSelectedIds]);
        } else {
            setSelectedIds((prev: any) =>
                prev.filter((uuid: string) => !currentPageDealers.some((item: any) => item.mapUserBrandDealer === uuid))
            );
        }
    }
    const checkBoxHandler = (checked: boolean, uuid: string) => {
        setSelectedIds((prevSelected: any = []) => {
            const updatedSelection = checked
                ? [...prevSelected, uuid]
                : prevSelected.filter((id: any) => id !== uuid);
            if (!checked) {
                setIsAllSelected(false);
            } else {
                const allDealerUUIDs = (selectedIds || []).map((dealer: any) => dealer.uuid);
                if (allDealerUUIDs.every((id: any) => updatedSelection.includes(id))) {
                    setIsAllSelected(true);
                }
            }
            return updatedSelection;
        });
    };

    const deleteBrandDealerMapping = async () => {
        setShowVideoDeleteModal(true);
    }

    const handleSelectAll = async () => {

        const payload = {
            userUuid: userId.uuid,
            brandUuid: "",
            dealerUuid: "",
            searchVal: "",
            pageNumber: 1,
            limit
        };

        // Dispatch the action to get the dealer data
        dispatch(getUserAssignedBrandDealerHandler(payload)).then((response: any) => {
            if (response?.type == 'getDealersForUser/fulfilled') {
                setLoading(false);
                const allData = response?.payload?.data?.allData || [];
                const allIds = allData.map((item: any) => item?.mapUserBrandDealer);
                if (allIds.length === selectedIds.length) {
                    setSelectedIds([]);
                    setIsAllSelected(false);
                    setIsAllSelectedData(false);
                } else {
                    setSelectedIds(allIds);
                    setIsAllSelected(true);
                    setIsAllSelectedData(true);
                }
            }
        });
    };

    const deleteHandler = async () => {
        setLoading(true)
        try {
            setLoading(false);
            if (selectedIds?.length == 0) {
                toast.error("Please select a checkbox first", {
                    position: "top-right",
                    autoClose: 2000
                });
                return
            }
            const payload = {
                mappedUuids: selectedIds
            }
            const response = await dispatch(deleteUserAssignedBrandDealerHandler(payload));
            if (response?.type === 'deleteAssignBrandsDealers/fulfilled') {
                setLoading(false);
                toast.success(response?.payload.message, {
                    position: "top-right",
                    autoClose: 2000
                });
                fetchData(1, selectedBrand?.uuid, selectedDealer?.uuid, filterValue);
            }
        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    useEffect(() => {
        const allSelected =
            data.length > 0 &&
            data.every((dealer: any) =>
               selectedIds.includes(dealer.mapUserBrandDealer));
        setIsAllSelected(allSelected);
    }, [data, selectedIds]);
    
    return (
        <div className="table-container">
            {showVideoDeleteModal && (
                <DeleteDialogBox
                    show={showVideoDeleteModal}
                    clickOk={() => {
                        deleteHandler();
                        setShowVideoDeleteModal(false);
                    }}
                    clickCancel={() => {
                        setShowVideoDeleteModal(false);
                    }}
                    color={"btn-danger"}
                    btncancel={t("constants.cancel")}
                    btnyes={t("constants.delete")}
                    deleteText="Are your sure you want to delete this record"
                />
            )}
            <div className="container-fluid">
                {loading && (
                    <div className="loader-overlay">
                        <div className="loader-container">
                            <TailSpin color="#00BFFF" height={80} width={80} />
                        </div>
                    </div>
                )}
            <h2>Assigned Brands and Dealers</h2>
                <div className="buttons-container d-flex align-items-center justify-content-between">
                    <div className="d-flex gap-3">
                        <button
                            className='btn btn-primary px-3 mw-120 d-flex align-items-center gap-3 mb-2'
                            onClick={() => navigate(ADMIN_DASHBOARD)}
                        >
                            <span><FontAwesomeIcon icon={faArrowLeft} /></span>
                            <span className='fw-semibold'>Back</span>
                        </button>
                        <button
                            className="btn btn-primary px-3 mw-120 d-flex align-items-center gap-3 mb-2"
                            onClick={() => {
                                const path = SELECT_BRAND_DEALERS.replace(':uuid', userId.uuid);
                                navigate(path, { state: { source: 'assign' } });
                            }}
                        >    <FontAwesomeIcon icon={faPlus} />
                            <span className="fw-semibold">Assign More</span>
                        </button>

                        <button
                            className="btn btn-danger px-3 mw-120 d-flex align-items-center gap-3 mb-2"
                            onClick={() => {
                                deleteBrandDealerMapping()
                            }}
                            disabled={data.length === 0 || selectedIds.length === 0 }
                        >
                            <FontAwesomeIcon icon={faMinus} />
                            Unassign

                        </button>

                    </div>

                    <div className="buttons-container d-flex align-items-center justify-content-between">
                        {/* ////////FOR FUTURE USE///// */}
                        <div style={{ position: "relative" }}>
                            <button
                                className="btn btn-primary px-3 mw-120 d-flex align-items-center gap-3 mb-2"
                                type="button"
                                onClick={() => { handleFilterChange() }}
                            >
                                <span className='fw-semibold'>Filter</span><FontAwesomeIcon icon={faFilter} className="filterIcon" />
                            </button>
                            {showFilter && (
                                <BrandDealerFilter
                                    setShowFilter={setShowFilter}
                                    selectedBrand={selectedBrand}
                                    selectedDealer={selectedDealer}
                                    setSelectedDealer={setSelectedDealer}
                                    setSelectedBrand={setSelectedBrand}
                                    setFilterValue={setFilterValue}
                                    filterValue={filterValue}
                                    handleReset={handleReset}
                                    handleApply={handleApply}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper} className="custom-table mb-3">
                    <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold",width: '30%' }}>
                                    <span>

                                        {data?.length > 0 &&
                                            <span className="d-flex align-items-center gap-2">
                                                <span className="checkbox-custom">
                                                    <Checkbox
                                                        checked={isAllSelected}
                                                        onChange={(e) => checkAllHandler(e.target.checked, data)}
                                                    />
                                                    <span>Select</span>
                                                </span>
                                                <span className="checkbox-custom">
                                                    <Checkbox
                                                        checked={isAllSelectedData}
                                                        onClick={handleSelectAll}
                                                    />
                                                    <span> Select All {isAllSelected}</span>
                                                </span>
                                            </span>
                                        }
                                    </span>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>Brand Name</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", }}>Dealer Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length > 0 ? (
                                data?.map((item: any) => {
                                    return (
                                        <TableRow key={item?.id}>
                                            <TableCell sx={{
                                                verticalAlign: "middle",
                                            }}>
                                                <Checkbox
                                                    checked={selectedIds.includes(item?.mapUserBrandDealer)}
                                                    onChange={(e) => checkBoxHandler(e.target.checked, item?.mapUserBrandDealer)}
                                                />

                                            </TableCell>
                                            <TableCell sx={{
                                                verticalAlign: "middle",
                                            }}>{item?.brandName}</TableCell>
                                            <TableCell sx={{
                                                verticalAlign: "middle",
                                            }}>{item?.dealerName}</TableCell>
                                        </TableRow>)
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        No records found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="mapBrandDealer-buttons" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="pagination-container">
                        {data && data?.length > 0 &&
                            <ReactPaginate
                                pageCount={Math.ceil(totalRecords / paginationSize)}
                                previousLabel={<img src={Previous} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                                nextLabel={<img src={Next} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                                onPageChange={({ selected }) => onHandleFetchMore(selected)}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                            />
                        }
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ViewUserBrandsDealers;