import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProgressBar from "@ramonak/react-progress-bar";
import { TbRotateClockwise2 } from 'react-icons/tb';
import { faArrowLeft, faArrowRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { getBatchCsvByEmailHandler, getVideoDetailsHandler } from '../../pages/Store/slices/videoSlice';
import { RootState } from '../../pages/Store/store';
import { FAILED, SUCCESSFUL } from '../../common/commonConstants';
import ThumbnailCarousalModal from '../ThumbnailCarousalModal';
import VideoTranscriptionTextModal from '../TranscriptionTextModal';
import VideoPlayerModal from '../VideoPlayerModal';
import { ShowBulkStatusModalHandler, ShowExportCsvModalHandler } from '../../pages/Store/slices/modalSlice';
import { getVideoDetailsByBatch } from '../../services/video.service';
import Swal from "sweetalert2";
import { TailSpin } from 'react-loader-spinner';
import socket from '../../socket/socket';

interface BulkUploadVideoStatusModalProps {
  batchId: string;
}
const BulkUploadVideoStatusModal: React.FC<BulkUploadVideoStatusModalProps> = ({ batchId }) => {
  //single hooks starts here
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { paginationData } = useSelector((state: RootState) => state.video);
  const { bulkStatusModal } = useSelector((state: RootState) => state.modals);
  const userId = user?.user?.uuid ?? user?.uuid;
  const userName = user?.user?.fName ?? user?.email;
  const transcriptionTextTitle = "";
  const paginationSize = 10;
  const videoThumbnails: any = [];
  const transcriptionText = "";
  //single hooks ends here

  //useStates Starts here
  const [videos, setVideos] = useState<any>([]);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const video: any = selectedVideo?.payload;
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
  const [showThumbnailModal, setShowThumbnailModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTranscriptionTextModal, setShowTranscriptionTextModal] = useState(false);
  //useStates ends here

  //functions starts here
  const getVideoData = async () => {
    setLoading(true);
    const data = {
      pageNumber: pageNumber,
      batchId: batchId
    };
    await getVideoDetailsByBatch(data)
      .then((res: any) => {
        const videoData = res?.data;
        setVideos(videoData);
        setLoading(false);
        setTotalRecords(res?.totalItems);
        setPageNumber(res?.currentPage)
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleOpenVideoModal = async (video: any) => {
    try {
      await dispatch(getVideoDetailsHandler(video)).then((res: any) => {
        setSelectedVideo(res);
      }).catch((err: any) => {
        console.log("err", err);
      })
      setShowVideoModal(true);
    } catch (error) {
      toast.error(t('VideoProcess.failedToGenerateVideoUrl'), {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const onHandleFetchMore = (selected: any) => {
    const newPageNumber = selected + 1;
    if (videos) {
      setPageNumber(newPageNumber)
    }
  };

  const clearForm = () => {
    dispatch(ShowBulkStatusModalHandler(false))
    setPageNumber(1)
  }

  const handleExportCsvModal = async (formatType: any) => {
    try {
      await dispatch(getBatchCsvByEmailHandler({ userId, batchId, userName,formatType })).then((res: any) => {
        if (res) {
          const icon = res.payload.message === "Batch videos are under process , This email will automatically send once the process will complete." ? "warning" : "success";
          Swal.fire({
            icon: icon,
            text: `${res.payload.message}`,
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#3085d6",
          });
          dispatch(ShowExportCsvModalHandler(true));
        }
      }).catch((err: any) => {
        console.log(err);
      })
    } catch (error) {
      toast.error(t('VideoProcess.tryAgain'), {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }
  //functions ends here

  //useEffect starts here
  useEffect(() => {
    if (batchId) {
      getVideoData()
    }
  }, [batchId, pageNumber]);

  useEffect(() => {
    socket.on('video-data-update-res', async (data: any) => {
      const updatedData = await videos?.map((e: any) => {
        let newVideo = {};
        if (data?.videoUuid !== e?.uuid) {
          newVideo = e;
        } else {
          newVideo = {
            uuid: data?.videoUuid ?? e?.uuid,
            key: data?.storageLink,
            progress: data?.progress ?? e?.progress,
            status: data?.status ?? e?.status,
            videoUrl: e?.storageLink,
            storageLink: data?.storageLink,
          };
        }
        return newVideo;
      });
      setVideos(updatedData);
    });

    return (() => {
      socket.off('video-data-update-res')
    })
  }, [videos])
  //useEffect ends here

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        </div>
      )}
      <Modal centered backdrop="static" show={bulkStatusModal} onHide={clearForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className='fw-semibold modal-title'>{'Video Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ marginBottom: '15px' }} className='me-3'>
              <button className="btn btn-primary mb-2 float-end me-3" disabled={videos.length == 0} onClick={() => handleExportCsvModal('new')}>
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                {t('VideoProcess.exportCsv')}
              </button>
            </div>
            <div style={{ marginBottom: '15px' }} className=''>
              <button className="btn btn-primary mb-2 float-end me-3" onClick={() => handleExportCsvModal('old')} disabled={videos.length == 0} >
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                {t('VideoProcess.exportOldCsv')}
              </button>
            </div>
            <TableContainer component={Paper} className="custom-table mb-3">
              <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }}>
                    {t('constants.uniqueId')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                    {t('constants.Status')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: '0 8px' }} className="text-center">
                      {t('constants.progress')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videos && videos.length > 0 ? videos?.map((row: any) => {
                    return (
                      <TableRow key={row?.videoDetails?.uuid} className="mb-4">
                        <TableCell sx={{ padding: '0 8px' }}>
                          <a href="#" className="link-color" onClick={() => handleOpenVideoModal(row?.videoDetails?.uuid)}>

                            {row?.videoDetails?.storageLink?.split('/')[row?.videoDetails?.storageLink.split('/').length - 1]?.split('?')[0]}
                          </a>
                        </TableCell>
                        <TableCell sx={{ padding: '0 8px' }} className="text-center">{(row?.videoDetails?.status)}</TableCell>
                        <TableCell sx={{ padding: '0 8px' }}>
                          <div className="progress-container">
                            <ProgressBar bgColor="#3483c8" completed={row?.videoDetails?.progress} className="status-progressbar" />
                            <div className="retry-icon-container">
                              {row?.videoDetails?.status !== SUCCESSFUL && row?.videoDetails?.status !== FAILED ? (
                                <TbRotateClockwise2 className="rotate-icon" size={24} />
                              ) : (
                                <span className="retry-placeholder"></span>
                              )}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  }) : <tr><td colSpan={7} style={{ "textAlign": "center" }}>{t('common.noVideoFound')}</td></tr>}
                </TableBody>
              </Table>
            </TableContainer>
            {showThumbnailModal && videoThumbnails?.length > 0 && (
              <ThumbnailCarousalModal
                isOpen={showThumbnailModal}
                onClose={() => setShowThumbnailModal(false)}
                thumbnails={videoThumbnails}
              />
            )}
            {showTranscriptionTextModal && isExpanded && (
              <VideoTranscriptionTextModal
                isOpen={isExpanded}
                title={transcriptionTextTitle}
                text={transcriptionText}
                onClose={() => { setShowTranscriptionTextModal(false); setIsExpanded(false); }}
              />
            )}
            <VideoPlayerModal
              isOpen={showVideoModal}
              onClose={handleCloseVideoModal}
              video={video}
              isProcessedLink={false}
            />
            <div className="d-flex align-items-center justify-content-end">
              {videos && videos?.length > 0 && (
                <ReactPaginate
                  pageCount={Math.ceil(totalRecords / paginationSize)}
                  previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
                  nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
                  onPageChange={({ selected }) => onHandleFetchMore(selected)}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                  forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                />
              )}
            </div>
          </div >
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BulkUploadVideoStatusModal
