import React, { useEffect, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../pages/Store/store";
import { createWebhookHandler, getWebhookByIdHandler, updateWebhookHandler } from "../pages/Store/slices/oauthSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CREATEWEBHOOK, FULFILLED, GETWEBHOOKBYID, UPDATEWEBHOOK } from "../pages/Store/actions";

const EventNotificationModal = ({ closeEventNotificationModal }: any) => {
    //single hooks starts here
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const methods = ["GET", "POST"];
    //single hooks ends here

    //useSelectors starts here
    const { user } = useSelector((state: RootState) => state.auth);
    const userId = user?.user?.uuid ?? user?.uuid;
    //useSelectors ends here

    //useStates starts here
    const [initialValues, setInitialValues] = useState<any>({
        videoStatusMethod: "GET",
        videoStatus: "",
        bulkUploadStatusMethod: "GET",
        bulkUploadStatus: "",
        strachDetectStatusMethod: "GET",
        strachDetectStatus: ""
    });
    //useStates ends here

    //formik starts here
    const validationSchema = Yup.object().shape({
        videoStatus: Yup.string()
            .matches(/^https:\/\/.*/, t('constants.urlStartWithHttp')),
        bulkUploadStatus: Yup.string()
            .matches(/^https:\/\/.*/, t('constants.urlStartWithHttp')),
        strachDetectStatus: Yup.string()
            .matches(/^https:\/\/.*/, t('constants.urlStartWithHttp')),
    });
    //formik ends here

    //function starts here
    const getWebhookById = async () => {
        await dispatch(getWebhookByIdHandler(userId)).then((res: any) => {
            if (res.type == `${GETWEBHOOKBYID}/${FULFILLED}`) {
                const { payload } = res;
                const videoStatus = payload.data.data.find((webhook: any) => webhook.webhookType === "video_status");
                const bulkUploadStatus = payload.data.data.find((webhook: any) => webhook.webhookType === "bulk_upload_status");
                const strachDetectStatus = payload.data.data.find((webhook: any) => webhook.webhookType === "scratch_detect_status");
                setInitialValues({
                    videoStatusMethod: videoStatus?.method || "GET",
                    videoStatus: videoStatus?.url || "",
                    bulkUploadStatusMethod: bulkUploadStatus?.method || "GET",
                    bulkUploadStatus: bulkUploadStatus?.url || "",
                    strachDetectStatusMethod: strachDetectStatus?.method || "GET",
                    strachDetectStatus: strachDetectStatus?.url || ""
                });
            }
        }).catch((error: any) => {
            console.error("Error fetching webhook data:", error);
        });
    };

    const handleSubmit = async (formValue: any) => {
        const payload = {
            userUuId: userId,
            data: [
                {
                    webhookType: "video_status",
                    url: formValue.videoStatus,
                    method: formValue.videoStatusMethod
                },
                {
                    webhookType: "bulk_upload_status",
                    url: formValue.bulkUploadStatus,
                    method: formValue.bulkUploadStatusMethod
                },
                {
                    webhookType: "scratch_detect_status",
                    url: formValue.strachDetectStatus,
                    method: formValue.strachDetectStatusMethod
                }
            ]
        };

        const isExistingWebhook = initialValues.videoStatus || initialValues.bulkUploadStatus || initialValues.strachDetectStatus;
        if (isExistingWebhook) {
            // update the webhook
            await dispatch(updateWebhookHandler(payload)).then((res: any) => {
                if (res.type === `${UPDATEWEBHOOK}/${FULFILLED}`) {
                    toast.success(t("auth.updateWebhook"), {
                        position: "top-right",
                        autoClose: 2000
                    });
                    closeEventNotificationModal();
                } else {
                    toast.error(t("auth.updateWebhookFail"), {
                        position: "top-right",
                        autoClose: 2000
                    });
                }
            }).catch((error: any) => {
                toast.error(t(error.message), {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        } else {
            // create a new webhook
            await dispatch(createWebhookHandler(payload)).then((res: any) => {
                if (res.type === `${CREATEWEBHOOK}/${FULFILLED}`) {
                    toast.success(t("auth.createWebhook"), {
                        position: "top-right",
                        autoClose: 2000
                    });
                    closeEventNotificationModal();
                } else {
                    toast.error(t("auth.createWebhookFail"), {
                        position: "top-right",
                        autoClose: 2000
                    });
                }
            }).catch((error: any) => {
                toast.error(t(error.message), {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        }
    };
    //function ends here

    //useEffect starts here
    useEffect(() => {
        getWebhookById();
    }, [userId]);
    //useEffect ends here

    return (
        <div className="modal-body">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {({ values, dirty }) => {
                    const isFormValid = dirty && (values.videoStatus || values.bulkUploadStatus || values.strachDetectStatus);
                    return (
                        <Form>
                            <div className="mainContainer methodModal">
                                {/* Video Status URL */}
                                <div className="input-container">
                                    <label htmlFor="videoStatus" className="input-label"> {t('oauth.videoStatusUrl')}</label>
                                    <div className="combined-field">
                                        <Field as="select" name="videoStatusMethod" className="method-dropdown">
                                            {methods.map((method, index) => (
                                                <option key={index} value={method}>{method}</option>
                                            ))}
                                        </Field>
                                        <Field
                                            type="text"
                                            id="videoStatus"
                                            name="videoStatus"
                                            placeholder="https://example.com"
                                            className="url-input-field no-border"
                                        />
                                    </div>
                                    <ErrorMessage name="videoStatus" component="div" className="text-danger txt-error" />
                                </div>
                                {/* Bulk Upload Status URL */}
                                <div className="input-container">
                                    <label htmlFor="bulkUploadStatus" className="input-label">{t('oauth.bulkUploadStatusUrl')}</label>
                                    <div className="combined-field">
                                        <Field as="select" name="bulkUploadStatusMethod" className="method-dropdown">
                                            {methods.map((method, index) => (
                                                <option key={index} value={method}>{method}</option>
                                            ))}
                                        </Field>
                                        <Field
                                            type="text"
                                            id="bulkUploadStatus"
                                            name="bulkUploadStatus"
                                            placeholder="https://example.com"
                                            className="url-input-field no-border"
                                        />
                                    </div>
                                    <ErrorMessage name="bulkUploadStatus" component="div" className="text-danger txt-error" />
                                </div>
                                {/* Strach Detect Status URL */}
                                <div className="input-container">
                                    <label htmlFor="strachDetectStatus" className="input-label">{t('oauth.strachDetectUrl')}</label>
                                    <div className="combined-field">
                                        <Field as="select" name="strachDetectStatusMethod" className="method-dropdown">
                                            {methods.map((method, index) => (
                                                <option key={index} value={method}>{method}</option>
                                            ))}
                                        </Field>
                                        <Field
                                            type="text"
                                            id="strachDetectStatus"
                                            name="strachDetectStatus"
                                            placeholder="https://example.com"
                                            className="url-input-field no-border"
                                        />
                                    </div>
                                    <ErrorMessage name="strachDetectStatus" component="div" className="text-danger txt-error" />
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={!isFormValid}>
                                    {initialValues.videoStatus || initialValues.bulkUploadStatus || initialValues.strachDetectStatus
                                        ? 'Update' : 'Save'}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default EventNotificationModal;

