import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCopy, faEdit, faPlus, faTrash, faCheck, faLink, faHistory } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import ModalComponent from "../../components/Modal";
import { RootState } from '../../pages/Store/store';
import { useDispatch, useSelector } from "react-redux";
import { closeEventNotificationModal, closeModal, deleteOauthHandler, getAllClientData, getCredentialForSelected, openModal, showEventNotificationModal } from "../Store/slices/oauthSlice";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import DeleteDialogBox from "../../components/VideoDeleteModal";
import { toast } from "react-toastify";
import { FULFILLED, OAUTH_DELETE } from "../Store/actions";
import AuthForm from "../../components/AuthForm";
import EventNotificationModal from "../../components/EventNotificationModal";
import { Link } from "react-router-dom";
import * as routeConstant from "../../common/routeConstants";

const OAuth = () => {
  //single hooks starts here
  const { t } = useTranslation("common");
  const paginationSize = 10;
  const dispatch = useDispatch();
  //single hooks ends here

  //useSelector starts here
  const showModal = useSelector((state: RootState) => state.oauth.isOpen);
  const isUpdate = useSelector((state: RootState) => state.oauth.isUpdate);
  const { user } = useSelector((state: RootState) => state.auth);
  const { paginationData } = useSelector((state: RootState) => state.oauth);
  const userId = user?.user?.uuid;
  //useSelector ends here

  //useStates starts here
  const [copiedClientId, setCopiedClientId] = useState<string | null>(null);
  const showEventNotifiModal = useSelector((state: RootState) => state.oauth.showEventNotification);
  const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
  const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);
  const { selectedClientData } = useSelector((state: RootState) => state.oauth);
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [initialData, setInitialData] = useState(selectedClientData);
  const [pageNumber, setPageNumber] = useState(0);
  const [clientCredentialsData, setClientCredentialsData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  //useStates ends here

  //functions starts here
  const handleCloseCreateCredentialModal = () => {
    setInitialData(null);
    dispatch(closeModal());
  };

  const handleCreateCredentialModal = () => {
    setInitialData(null);
    dispatch(openModal());
  };

  const handleEventNotificationModal = () => {
    dispatch(showEventNotificationModal());
  }
  const handleCloseEventNotificationModal = () => {
    dispatch(closeEventNotificationModal());
  };

  const handleDeleteCredentialModal = async (uuid: string) => {
    setShowVideoDeleteModal(true);
    setDeleteVideoId(uuid);
  }

  const handleEditCredentialModal = async (clientUuid: any) => {
    const data = { uuid: clientUuid };
    setLoader(true);
    dispatch(getCredentialForSelected(data)).then((res: any) => {
      setLoader(false);
      if (res?.payload) {
        setInitialData(res.payload);
        dispatch(openModal());
      } else {
        console.error("Error fetching data", res?.payload?.message);
      }
    }).catch((error: any) => {
      setLoader(false);
      console.error("Error in handleEditCredentialModal:", error);
    });
  };

  const onHandleFetchMore = async (selectedPage: number) => {
    setPageNumber(selectedPage);
    try {
      const data = {
        uuid: userId,
        pageNumber: selectedPage + 1,
        paginationSize: paginationSize,
      };
      const clientData = await dispatch(getAllClientData(data));
      setClientCredentialsData(clientData?.payload?.data);
      setTotalRecords(clientData?.payload?.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const deleteConfirmation = (uuid: string) => {
    const data = {
      uuid, userId: userId
    }
    dispatch(deleteOauthHandler(data)).then(async (deleteRes: any) => {
      if (deleteRes.type == `${OAUTH_DELETE}/${FULFILLED}`) {
        toast.success(t(deleteRes?.payload?.message), {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        setLoader(false);
        toast.error(deleteRes?.payload?.message ?? t('oauth.oauthDelete'), {
          position: "top-right",
          autoClose: 2000,
        });
      }
    });
  }

  const handleCopy = (clientID: any) => {
    navigator.clipboard.writeText(clientID).then(() => {
      setCopiedClientId(clientID); // Set copied client ID
      setTimeout(() => setCopiedClientId(null), 2000);
    });
  };
  //functions ends here

  //useEffects starts here
  useEffect(() => {
    window.onload = () => {
      dispatch(closeModal());
    };
    const fetchData = async () => {
      try {
        const data = {
          uuid: userId,
          pageNumber: 1,
          paginationSize: paginationSize,
        };
        const clientData = await dispatch(getAllClientData(data));
        setClientCredentialsData(clientData?.payload?.data);
        setTotalRecords(clientData?.payload?.totalItems);
        setPageNumber(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [isUpdate]);
  //useEffects ends here

  return (
    <div className="min-vh-100 container-fluid">
      <div className="row gy-2 mb-3">
        <div className="col-lg-12 d-flex align-items-center">
          <button type="button" className="btn btn-primary mx-2" onClick={handleCreateCredentialModal}>
            <FontAwesomeIcon icon={faPlus} /> Create Credentials
          </button>
          <button type="button" className="btn btn-primary mx-2" onClick={handleEventNotificationModal}>
            <FontAwesomeIcon icon={faLink} /> Event Notification
          </button>
          <Link className="btn btn-primary mx-2" to={routeConstant.WEBHOOKHISTORY}>
            <FontAwesomeIcon icon={faHistory} /> Webhook History
          </Link>
          {loader && (
            <div className="loader-overlay">
              <div className="loader-container">
                <TailSpin color="#00BFFF" height={80} width={80} />
              </div>
            </div>
          )}
        </div>
      </div>


      {showVideoDeleteModal && (
        <DeleteDialogBox
          show={showVideoDeleteModal}
          clickOk={() => {
            deleteConfirmation(deleteVideoId);
            setShowVideoDeleteModal(false);
          }}
          clickCancel={() => {
            setShowVideoDeleteModal(false);
          }}
          color={"btn-danger"}
          btncancel={t("constants.cancel")}
          btnyes={t("constants.delete")}
          deleteText={t("oauth.oauthDeleteConfirmation")}
        />
      )}
      <ModalComponent isOpen={showModal} onClose={handleCloseCreateCredentialModal} modalHeading={initialData ? "Edit OAuth Client" : "Add OAuth Client"}>
        {initialData ? <AuthForm mode="edit" closeModal={handleCloseCreateCredentialModal} initialData={initialData} /> : <AuthForm mode="add" closeModal={handleCloseCreateCredentialModal} />}
      </ModalComponent>
      <TableContainer component={Paper} className="custom-table mb-3">
        <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Client Name</TableCell>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Client Type</TableCell>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Application Type</TableCell>
              <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Client ID</TableCell>
              <TableCell sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold", padding: "0 8px" }}>{t("constants.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientCredentialsData && clientCredentialsData?.length > 0 ? (
              clientCredentialsData.map((row: any) => (
                <TableRow key={row.uuid} className="mb-4">
                  <TableCell sx={{ padding: "8px" }}>{row.clientName}</TableCell>
                  <TableCell sx={{ padding: "8px" }}>{row.clientType}</TableCell>
                  <TableCell sx={{ padding: "8px" }}>Application</TableCell>
                  <TableCell sx={{ padding: "8px" }}>{row.clientId}
                    <Tooltip title={copiedClientId === row.clientId ? "Copied!" : "Copy to clipboard"} placement="top">
                      <button className="btn e-2" type="button" onClick={() => { handleCopy(row.clientId) }}>
                        <FontAwesomeIcon
                          icon={copiedClientId === row.clientId ? faCheck : faCopy} // Change icon based on copiedClientId
                          className={`fs-6 ${copiedClientId === row.clientId ? "text-primary" : "text-primary"}`}
                        />
                      </button>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    <div className="d-flex align-items-start justify-content-start">
                      <Tooltip placement="top" title={t('constants.edit')}>
                        <button className="btn e-2">
                          <FontAwesomeIcon onClick={() => handleEditCredentialModal(row.uuid)} icon={faEdit} className="fs-6 text-primary" />
                        </button>
                      </Tooltip>
                      <Tooltip placement="top" title={t('constants.delete')}>
                        <button className="btn e-2" onClick={() => { handleDeleteCredentialModal(row.uuid) }}>
                          <FontAwesomeIcon icon={faTrash} color="red" className="fs-6" />
                        </button>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  No oauth credentials found
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex align-items-center justify-content-end">
        {clientCredentialsData?.length > 0 && (
          <ReactPaginate
            pageCount={Math.ceil(totalRecords / paginationSize)}
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
            nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
            onPageChange={({ selected }) => onHandleFetchMore(selected)}
            containerClassName="pagination"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            forcePage={pageNumber === 0 ? pageNumber : pageNumber - 1}
          />
        )}
      </div>
      <ModalComponent isOpen={showEventNotifiModal} onClose={handleCloseEventNotificationModal} modalHeading={"Event Notification"} size='lg' >
        <EventNotificationModal closeEventNotificationModal={handleCloseEventNotificationModal} />
      </ModalComponent>
    </div>
  );
};

export default OAuth;
