import { api } from "../common/axiosInstance";
const API_URL = process.env.REACT_APP_API_URL;


export const createAuthClient = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  try {
    const response = await api({
      url: `${API_URL}oauth/clients/register`,
      data,
      method: "post",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};

export const updateAuthClient = async (data: any) => {
  try {
    if (data.uuid != undefined && data.uuid != null && data.uuid != '') {
      const response = await api.put(`${API_URL}oauth/clients/updateCredentials?uuid=${data?.uuid}`, {
      updatedData : data
    });
      return response;
    }
  } catch (error) {
    throw error;
  }
}; 

export const getAllClientCredential = async (data: any) => {
  if (
    data?.uuid != undefined &&
    data.uuid != "" &&
    data.uuid != "0" &&
    data?.pageNumber != undefined &&
    data.pageNumber != "" &&
    data.pageNumber != "0" &&
    data?.paginationSize != undefined &&
    data.paginationSize != "" &&
    data.paginationSize != "0" 
  ) {
    const pageNumber = data?.pageNumber;
    const userId = data?.uuid;
    const paginationSize = data?.paginationSize;
    return api
      .get(`${API_URL}oauth/clients/getOauthCredentials?pageNumber=${pageNumber}&limit=${paginationSize}`, {
        params: { userId },
      })
      .then((response: any) => {
        return response.data;
      });
  }
};

export const getSelectedClientCredential = async (data: any) => {
  if (
    data?.uuid != undefined &&
    data.uuid != "" &&
    data.uuid != "0"
  ) {
    const uuid = data?.uuid;
    return api
      .get(`${API_URL}oauth/clients/getCredentials`, {
        params: { uuid },
      })
      .then((response: any) => {
        return response.data;
      });
  }
};

export const deleteOAuth = async (data: { userId: string; uuid: string }) => {
  if (data?.userId != undefined && "" !== data?.userId) {
    const newHeader = {
      "Content-Type": "application/json",
    };
    try {
      const response = await api({
        url: `${API_URL}oauth/clients/deleteOauthCredential?uuid=${data.uuid}&userId=${data.userId}`,
        method: "delete",
        headers: newHeader,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting OAuth credential:", error);
      throw error;
    }
  }
};

export const createWebhook = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  try {
    const response = await api({
      url: `${API_URL}oauth/clients/registerWebhook`,
      data,
      method: "post",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWebhookById = async (userId: any) =>{
  try {
    const response = await api.get(`${API_URL}oauth/clients/getWebhook?uuid=${userId}`);
    return response;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
}

export const updateWebhook = async (data:any) =>{
  try {
      const response = await api.put(`${API_URL}oauth/clients/updateWebhook?uuid=${data.userUuId}`,
        {
          data : data.data
        }
      );
      return response;
  } catch (error) {
    throw error;
  }
}

export const getWebhookHistory = async (data:any)=> {
  try{

    if (data) {
      const pageNumber = data?.pageNumber;
      const userId = data?.uuid;
      const paginationSize = data?.paginationSize;
      return api.get(`${API_URL}oauth/clients/getAllWebhookHistory?pageNumber=${pageNumber}&limit=${paginationSize}&uuid=${userId}`)
        .then((response: any) => {
          return response.data;
        });
    }
  }
  catch(error){
    throw error;
  }
};

export const getWebhookHistoryById = async (uuid:any)=>{
  try {
    const response = await api.get(`${API_URL}oauth/clients/getWebhookHistoryById?uuid=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
}