import { useSelector } from 'react-redux';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import { RootState } from '../pages/Store/store';
import AdminHeader from './AdminHeader';

const Layout = () => {
    //useSelector starts here
    const { isAdmin } = useSelector((state: RootState) => state.auth);
    //useSelector ends here

    return (
        <div className="min-vh-100 container-fluid">
            <div className='px-lg-5 mx-lg-5'>
                <div className="mt-2">
                    {isAdmin ? <AdminHeader /> : <Header />}
                </div>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default Layout;