import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface commonData {
    showLoader: boolean
    sessionExpired: boolean
    isRedirectedForReport: boolean
}

//loader initial states
const initialState: commonData = {
    showLoader: false,
    sessionExpired: false,
    isRedirectedForReport: false
};


//loaderSlice
export const loaderSlice = createSlice({
    name: 'loaderSlice',
    initialState,
    reducers: {
        enableLoader: (state, { payload }: PayloadAction<boolean>) => {
            state.showLoader = payload;
        },
        sessionExpiredHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.sessionExpired = payload;
        },
        isRedirectedForReportHanlder: (state, { payload }: PayloadAction<boolean>) => {
            state.isRedirectedForReport = payload;
        },
    },

    extraReducers: (builder) => {

    }
})

export const { enableLoader, sessionExpiredHandler, isRedirectedForReportHanlder } = loaderSlice.actions;
export default loaderSlice.reducer;

export const hideLoader = () => (dispatch: any) => {
    dispatch(enableLoader(false));
};