import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { RootState, store } from '../pages/Store/store';
import { logoutHandler, userBrandHandler, userDealerHandler, userFirstLoginHandler } from '../pages/Store/slices/authSlice';
import { getAllUserBrandsHandler, getUserDealersHandler } from '../pages/Store/slices/dealerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faExternalLink, faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from "../assets/images/logo.png";
import { Container, Nav, Navbar } from 'react-bootstrap';
import * as routeConstant from "../common/routeConstants";
import { MenuItem, TextField } from '@mui/material';
import { Pair } from '../types/user.type';
import { getBrandTotalVideoAvgLengthHandler, getBrandTotalVideoCountHandler, getBrandTotalVideoFailureRateHandler, getBrandTotalVideoNoisyRateHandler, getBrandTotalVideoScoreByBrandDealerHandler, getBrandTotalVideoScoreByBrandHandler, getBrandTotalVideoSuccessRateHandler } from '../pages/Store/slices/videoSlice';
import { exportToExcelFile } from '../services/exportToexcel';
import { updateSelectedBrandDealerHandler, userSelectedDealerHandler } from '../pages/Store/slices/settingsSlice';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isRedirectedForReportHanlder } from '../pages/Store/slices/commonSlice';
import { reportsOptions, scoringAlgoOptions } from '../common/common';

const Header = () => {
  //single hook starts here
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  //single hook ends here

  //useSelector starts here
  const { user, userBrands, userDealers, isAdmin } = useSelector((state: RootState) => state.auth);
  const { selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.settings);
  const user_email = user?.user?.fName ?? user?.email;
  const userId = user?.user?.uuid ?? user?.uuid;
  //useSelector ends here

  //functions starts here
  const handleLogout = () => {
    sessionStorage.removeItem("selectedTab");
    dispatch(logoutHandler())
  };

  const handleNavigation = (option: string) => {
    if (option === "Quality Scoring") {
      navigate(routeConstant.VIDEO_SCORING, { state: { source: 'qaulityScore' } });
    } else if (option === "Inspect Scoring") {
      navigate(routeConstant.VIDEO_SCORING, { state: { source: 'inspectScore' } });
    }
  };

  const exportToExcel = (reportOption: any) => {
    switch (reportOption) {
      case "Dealer wise Video Object":
        dispatch(isRedirectedForReportHanlder(true))
        !currentPath.includes('dashboard') && navigate('/dashboard')
        break;
      case "Total Videos brand wise":
        dispatch(getBrandTotalVideoCountHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise average Video length":
        dispatch(getBrandTotalVideoAvgLengthHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise noisy rate":

        dispatch(getBrandTotalVideoNoisyRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise failure rate":
        dispatch(getBrandTotalVideoFailureRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer wise success rate":
        dispatch(getBrandTotalVideoSuccessRateHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      case "Dealer Video Score for each Brand":

        dispatch(getBrandTotalVideoScoreByBrandHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        console.log("Exporting Dealer video score report");
        break;
      case "Brand wise video score":
        dispatch(getBrandTotalVideoScoreByBrandDealerHandler(user.user.uuid)).then((res: any) => {
          exportToExcelFile(res.payload.data, reportOption)
        })
        break;
      default:
        console.log("Unknown report option");
    }
  };

  const handleBrandChange = async (e: any) => {
    const brandUuid = e.target.value

    const dealerData = {
      userId: userId,
      brand: brandUuid
    }
    await dispatch(getUserDealersHandler(dealerData)).then((res: any) => {
      if (res?.payload?.statusCode === 200) {

        const dealers = res.payload.data.map((dealer: any) => ({
          value: dealer.uuid,
          label: dealer.name
        }));

        const dealerUuid = res.payload.data[0]?.uuid;
        dispatch(userDealerHandler(dealers));
        dispatch(updateSelectedBrandDealerHandler({ brandUuid, dealerUuid }))
      }
    });
  };

  const handleDealerChange = async (e: any) => {
    const dealerUuid = e.target.value
    dispatch(userSelectedDealerHandler(dealerUuid))
  };

  const getAllBrandsAndDealers = async () => {
    if (currentPath !== routeConstant.BULK_VIDEOS_UPLOAD &&
      currentPath !== routeConstant.OAUTH &&
      currentPath !== routeConstant.WEBHOOKHISTORY &&
      !currentPath.includes('/video-detail/')) {
      await dispatch(getAllUserBrandsHandler(userId)).then(async (res: any) => {
        if (res?.payload?.statusCode === 200) {
          const brands = res.payload.data.map((brand: any) => ({
            value: brand.uuid,
            label: brand.name
          }));
          dispatch(userBrandHandler(brands));
          const latestSelectedBrandId = store.getState().settings.selectedBrandId
          const isSelectedBrandPresent = brands.some((brand: any) => brand.value === latestSelectedBrandId)
          const brandUuid = isSelectedBrandPresent ? latestSelectedBrandId : res.payload?.data[0]?.uuid;
          if (
            currentPath !== routeConstant.BULK_VIDEOS_UPLOAD &&
            currentPath !== routeConstant.DASHBOARD &&
            currentPath !== routeConstant.OAUTH &&
            currentPath !== routeConstant.VIDEO_SCORING &&
            currentPath !== routeConstant.INSCPECT_SCORING &&
            !currentPath.includes('/video-detail/') &&
            currentPath !== routeConstant.WEBHOOKHISTORY 
          ) {
            const dealerData = {
              userId: userId,
              brand: brandUuid
            }
            await dispatch(getUserDealersHandler(dealerData)).then((res: any) => {
              if (res?.payload?.statusCode === 200) {

                const dealers = res.payload.data.map((dealer: any) => ({
                  value: dealer.uuid,
                  label: dealer.name
                }));

                const latestSelectedDealerId = store.getState().settings.selectedDealerId
                const isSelectedDealerPresent = dealers.some((dealer: any) => dealer.value === latestSelectedDealerId)
                const dealerUuid = isSelectedBrandPresent ? isSelectedDealerPresent ? latestSelectedDealerId : res.payload?.data[0]?.uuid : res.payload?.data[0]?.uuid;
                dispatch(userDealerHandler(dealers));
                dispatch(updateSelectedBrandDealerHandler({ brandUuid, dealerUuid }))
                setTimeout(() => {
                  dispatch(userFirstLoginHandler())
                }, 3000)
              }
            });
          }
        }
      });
    }
  }
  useEffect(() => {
    if (userId) {
      getAllBrandsAndDealers();
    }
  }, [userId, currentPath]);

  useEffect(() => {
    if (!userId) return
    const socket = io(process.env.REACT_APP_SOCKET_URL + '/user-updates', {
      query: { userId }
    })
    socket.on('update-brand-dealer-res', async (data) => {
      await getAllBrandsAndDealers()
      toast.warning("Assigned brands and dealers are updated.")
    })
    socket.on('delete-user-res', async (data) => {
      toast.warning("User deleted.")
      dispatch(logoutHandler())
    })
    return () => {
      socket.disconnect()
    }
  }, [userId])
  //useEffect ends here

  return (
    <>
      {!currentPath.includes('/changepassword/') &&
        <Navbar expand="lg" className="header">
          <Container fluid className="px-0">
            <Link to={routeConstant.DASHBOARD} className="navbar-brand">
              <img src={Logo} height={48} alt="logo" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="flex-fill mt-3 mt-lg-0 mb-3 mb-lg-0 flex-wrap gap-2">
                <div className="flex-fill d-flex flex-column flex-lg-row justify-content-lg-end gap-2">
                  {!isAdmin &&
                    <>
                      <NavLink to={routeConstant.DASHBOARD} className="nav-link">
                        {t('common.dashboard')}
                      </NavLink>
                      <NavLink to={routeConstant.VIDEOS} className="nav-link">
                        {t('common.videos')}
                      </NavLink>
                      <NavLink to={routeConstant.BULK_VIDEOS_UPLOAD} className="nav-link">
                        {t('common.bulkVideoUpload')}
                      </NavLink>
                      <NavLink to={routeConstant.SCRATCHDETECT} className="nav-link">
                        {t('common.scratchDetect')}
                      </NavLink>
                      <NavLink to={routeConstant.TIREDETECT} className="nav-link">
                        {t('common.tireScan')}
                      </NavLink>
                      <div className="dropdown show nav-link">
                        <a className="nav-link p-0 flex-row align-items-center gap-2 justify-content-start justify-content-lg-start" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span>{t('common.SCORINGALGO')}</span> <FontAwesomeIcon icon={faCaretDown} />
                        </a>
                        <div className="dropdown-menu">
                          {scoringAlgoOptions.map((option, index) => (
                            <div key={option + index} className="dropdown-item" onClick={() => handleNavigation(option)}>
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="dropdown show nav-link">
                        <a className="nav-link p-0 flex-row align-items-center gap-2 justify-content-start justify-content-lg-start" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span>{t('common.REPORTS')}</span> <FontAwesomeIcon icon={faCaretDown} />
                        </a>
                        <div className="dropdown-menu">
                          {reportsOptions.map((option: any, index: number) => (
                            <div key={option + index} className="dropdown-item" onClick={() => exportToExcel(option)}>{option}
                              {index === 0 && <FontAwesomeIcon icon={faExternalLink} className='ms-3' />}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-lg-row justify-content-lg-end gap-2 mt-3 mt-lg-0 flex-grow-1">
                        {currentPath !== routeConstant.BULK_VIDEOS_UPLOAD &&
                          currentPath !== routeConstant.OAUTH &&
                          currentPath !== routeConstant.WEBHOOKHISTORY &&
                          !currentPath.includes('/video-detail/') &&
                          userBrands &&
                          userBrands.length > 0 &&
                          <TextField
                            select
                            label="Select Brand"
                            fullWidth
                            variant="outlined"
                            value={selectedBrandId}
                            onChange={handleBrandChange}
                            className='nav-link dealers-dropdown'
                          >
                            {userBrands.map((option: Pair, index: number) => (
                              <MenuItem key={option.value + index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        }
                        {currentPath !== routeConstant.BULK_VIDEOS_UPLOAD &&
                          currentPath !== routeConstant.DASHBOARD &&
                          currentPath !== routeConstant.OAUTH &&
                          currentPath !== routeConstant.VIDEO_SCORING &&
                          currentPath !== routeConstant.INSCPECT_SCORING &&
                          !currentPath.includes('/video-detail/') &&
                          currentPath !== routeConstant.WEBHOOKHISTORY &&
                          userDealers &&
                          userDealers.length > 0 &&
                          <TextField
                            select
                            label="Select Dealers"
                            fullWidth
                            variant="outlined"
                            value={selectedDealerId}
                            onChange={handleDealerChange}
                            className='nav-link dealers-dropdown'
                          >
                            {userDealers.map((option: Pair, index: number) => (
                              <MenuItem key={option.value + index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        }
                      </div>
                    </>
                  }
                  <div className='d-flex d-lg-none justify-content-between gap-3'>
                    <div className='hstack gap-3'>
                      <FontAwesomeIcon icon={faUser} className="fs-2" />
                      <div className="text-black">{t('common.hello')}, {user_email}</div>
                    </div>
                    <button className="btn btn-primary" onClick={handleLogout}>
                      {t('common.logout')}
                    </button>
                  </div>
                  <div className="dropstart custom-dropdown me-lg-1 align-self-center d-none d-lg-flex">
                    <button
                      className="btn p-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={faUser} className="fs-3" />
                    </button>
                    <div className="dropdown-menu">
                      <div className="custom-dropdown-menu">
                        <div className="text-black text-center mb-2">{t('common.hello')}, {user_email}</div>
                        <button className="btn btn-primary" onClick={handleLogout}>
                          {t('common.logout')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      }
    </>
  );
};

export default Header;