import { api } from "../common/axiosInstance";
const API_URL = process.env.REACT_APP_API_URL;

export const getVideo = async (data: any) => {
  if (
    data?.uuid != undefined &&
    data.uuid != "" &&
    data.uuid != "0" &&
    data?.pageNumber != undefined &&
    data.pageNumber != "" &&
    data.pageNumber != "0" &&
    data?.userid != undefined &&
    data?.userid != "" &&
    data?.userid != "0" &&
    data?.brandId != undefined &&
    data?.brandId != "" &&
    data?.brandId != "0"
  ) {
    const pageNumber = data?.pageNumber;
    const userUuid = data?.userid;
    const dealerUuid = data?.uuid;
    const brandUuid = data.brandId;
    return api
      .get(`${API_URL}videos?pageNumber=${pageNumber}`, {
        params: { userUuid, brandUuid, dealerUuid },
      })
      .then((response: any) => {
        return response.data;
      });
  }
};
export const getVideoDetailsByBatch = async (data: any) => {
  if (
    data?.batchId != undefined &&
    data.batchId != "" &&
    data.batchId != "0" &&
    data?.pageNumber != undefined &&
    data.pageNumber != "" &&
    data.pageNumber != "0"
  
  ) {
    const pageNumber = data?.pageNumber;
    const batchId = data?.batchId;
    return api
      .get(`${API_URL}videos/getBulkVideo?pageNumber=${pageNumber}`, {
        params: { batchId },
      })
      .then((response: any) => {
        return response.data;
      });
  }
};

export const getAllScratcheddetectVideo = async (data: any) => {
  if (
    data?.uuid != undefined &&
    data.uuid != "" &&
    data.uuid != "0" &&
    data?.pageNumber != undefined &&
    data.pageNumber != "" &&
    data.pageNumber != "0" &&
    data?.userid != undefined &&
    data?.userid != "" &&
    data?.userid != "0"
  ) {
    const pageNumber = data?.pageNumber;
    const userUuid = data?.userid;
    const dealerUuid = data?.uuid;
    const brandUuid = data.brandName;
    return api
      .get(`${API_URL}videos/getScratchDetectVideo?pageNumber=${pageNumber}`, {
        params: { userUuid, brandUuid, dealerUuid },
      })
      .then((response: any) => {
        return response.data;
      });
  }
  if (
    data?.uuid != undefined && data.uuid != '' && data.uuid != '0' &&
    data?.pageNumber != undefined && data.pageNumber != '' && data.pageNumber != '0' &&
    data?.userid != undefined && data?.userid != '' && data?.userid != '0'
  ) {
    const pageNumber = data?.pageNumber;
    const userUuid = data?.userid;
    const dealerUuid = data?.uuid;
    return api.get(`${API_URL}videos?pageNumber=${pageNumber}`, { params: { userUuid, dealerUuid } }).then((response: any) => {
      return response.data;
    });
  }
};

export const uploadVideo = (video: string, userId: string) => {
  let formData = new FormData();
  formData.append("userId", userId);
  formData.append("video", video);

  let newHeader = {
    // ...authHeader(),
    "Content-Type": "multipart/form-data",
  };

  return api({
    url: API_URL + "videos/create",
    method: "post",
    headers: newHeader,
    data: formData,
  });
};
export const uploadVideoFromExcel = async (data: any, userId: string, excelFile: any) => {
  let formData = new FormData();
  formData.append('brandId', data.brandId)
  formData.append('urlsArray', JSON.stringify(data.urlsArray))
  formData.append('uuid', data.uuid)
  formData.append('excelFile', excelFile)
  let newHeader = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await api({
      url: `${API_URL}videos/upload-excel-video?uuid=${userId}`,
      data: formData,
      method: 'post',
      headers: newHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting video urls:', error);
    throw error;
  }
};

export const getVideoUrl = async (uuid: string) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getVideoUrl?uuid=${uuid}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error;
  }
};

export const getVideoDetails = async (uuid: string) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getVideo?uuid=${uuid}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error;
  }
};
export const getScratchVideoDetails = async (uuid: string) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getScratchedVideo?uuid=${uuid}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error;
  }
};

export const getBulkUploadBtach = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json"
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getUploadBatch?uuid=${data.userId}&pageNumber=${data.pageNumber}&limit=${data.limit}`,
      method: 'get',
      headers: newHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading video:', error);
    throw error;
  }

}

export const submitVideoUrls = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  try {
    const response = await api({
      url: `${API_URL}videos/addVideosUrls`,
      data,
      method: "post",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};
export const submitScratchDetectVideoUrls = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  try {
    const response = await api({
      url: `${API_URL}videos/addScratchDetectVideosUrls`,
      data,
      method: "post",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};

export const uploadVideoTos3 = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/generatePutUrl`,
      method: "get",
      headers: newHeader,
      params: { fileName: data?.video?.name, contentType: data?.video?.type },
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};
export const uploadVideoTos3ForScratchDetect = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/generatePutUrlForScratchDetect`,
      method: "get",
      headers: newHeader,
      params: { fileName: data?.video?.name, contentType: data?.video?.type },
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};

export const addVideo = (
  videoUuid: string,
  fileName: string,
  userId: string,
  videoUpload: any,
  uuid: any,
  brandId:any
) => {
  let formData = new FormData();
  formData.append("userId", userId);
  formData.append("fileName", fileName);
  formData.append("uuid", videoUuid);
  formData.append("videoUpload", videoUpload);
  formData.append("dealerId", uuid);
  formData.append("brandId", brandId);

  let newHeader = {
    // ...authHeader(),
    "Content-Type": "application/json",
  };

  return api({
    url: API_URL + "videos/addVideo",
    method: "post",
    headers: newHeader,
    data: formData,
  });
};
export const addScratchVideo = (
  videoUuid: string,
  fileName: string,
  userId: string,
  videoUpload: any,
  uuid: any,
  fileType: any,
  getUrl: any,
  brandId:any
) => {
  let formData = new FormData();
  formData.append("userId", userId);
  formData.append("fileName", fileName);
  formData.append("uuid", videoUuid);
  formData.append("videoUpload", videoUpload);
  formData.append("dealerId", uuid);
  formData.append("brandId", brandId);
  formData.append("fileType", fileType);
  formData.append("getUrl", getUrl);

  let newHeader = {
    // ...authHeader(),
    "Content-Type": "application/json",
  };

  return api({
    url: API_URL + "videos/add-scratch-video",
    method: "post",
    headers: newHeader,
    data: formData,
  });
};

export const logout = () => {
  sessionStorage.clear();
};

export const retryVideoAnalysis = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  const payload = { uuid: data };
  try {
    const response = await api({
      url: `${API_URL}videos/retry-sqs`,
      method: "post",
      headers: newHeader,
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrying video analysis:", error);
    throw error;
  }
};


export const retryBulkVideoAnalysis = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  const payload = { batchId: data };
  try {
    const response = await api({
      url: `${API_URL}videos/retry-bulk-sqs`,
      method: "post",
      headers: newHeader,
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrying video analysis:", error);
    throw error;
  }
};

export const retryScratchVideoAnalysis = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  const payload = { uuid: data };
  try {
    const response = await api({
      url: `${API_URL}videos/retry-scratched-sqs`,
      method: "post",
      headers: newHeader,
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrying video analysis:", error);
    throw error;
  }
};

export const getTranscriptionTex = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getVideoText?uuid=${data.uuid}&view=${data.view}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting transcription text of the video:", error);
    throw error;
  }
};

export const deleteVideo = async (data: { userId: string; uuid: string }) => {
  if (data?.userId != undefined && "" !== data?.userId) {
    const newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/deleteVideo?uuid=${data.uuid}&userId=${data.userId}`,
        method: "delete",
        headers: newHeader,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting video:", error);
      throw error;
    }
  }
};
export const deleteScratchDetectVideo = async (data: {
  userId: string;
  uuid: string;
}) => {
  if (data?.userId != undefined && "" !== data?.userId) {
    const newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/deleteScratchDetectVideo?uuid=${data.uuid}&userId=${data.userId}`,
        method: "delete",
        headers: newHeader,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting video:", error);
      throw error;
    }
  }
};

export const getDashboardData = async (data: any) => {
  if (data?.brand != undefined && "" !== data?.brand) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/dashboardData`,
        method: "get",
        headers: newHeader,
        params: {
          userUuid: data?.userid,
          brandUuid: data?.brand,
          dealerUuid: data?.dealerUuid,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving custom objects percent", error);
      throw error;
    }
  }
};

export const getCustomObjectsPercent = async (data: any) => {
  if (data?.brand != undefined && "" !== data?.brand) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/customObjectPercent`,
        method: "get",
        headers: newHeader,
        params: { userUuid: data?.userid, brandUuid: data?.brand },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving custom objects percent", error);
      throw error;
    }
  }
};

export const getDealerVideoRatingPercent = async (data: any) => {
  if (
    data?.brand != undefined &&
    "" !== data?.brand &&
    "0" != data?.brand &&
    data?.dealerUuid != undefined &&
    "" !== data?.dealerUuid &&
    "0" != data?.dealerUuid
  ) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videoScoreRating`,
        method: "get",
        headers: newHeader,
        params: {
          userUuid: data?.userid,
          brandUuid: data?.brand,
          dealerUuid: data.dealerUuid,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video rating percent:", error);
      throw error;
    }
  }
};

export const getBrandVideoRatingPercent = async (data: any) => {
  if (
    data.userid != undefined &&
    "" !== data.userid &&
    data.brand != undefined &&
    data.brand != "0" &&
    data.brand != ""
  ) {
    let newHeader = {
      "Content-Type": "application/json",
    };
    try {
      const response = await api({
        url: `${API_URL}videos/brandVideoScoreRating?userUuid=${data?.userid}&brandUuid=${data.brand}`,
        method: "get",
        headers: newHeader,
      });
      return response.data;
    } catch (error) {
      console.error("Error giving brand video rating percent:", error);
      throw error;
    }
  }
};

export const getDealerVideosStatusPercent = async (data: any) => {
  if (
    data?.dealer != undefined &&
    data?.userid != undefined &&
    "" !== data?.dealer &&
    "0" !== data?.dealer &&
    "" !== data?.userid
  ) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosStatus`,
        method: "get",
        headers: newHeader,
        params: { userid: data?.userid, dealer: data?.dealer },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video status percent:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoCount = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/totalBrandVideos`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving total video of brands:", error);
      throw error;
    }
  }
};
export const getBrandTotalVideoAvgLength = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/averageVideosLength`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving brand videos average length:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoNoisyRate = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosNoisyRate`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving brand videos noisy percent:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoSuccessRate = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosSuccessRate`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video success percent:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoFailureRate = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosFailureRate`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video success percent:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoScoreByBrand = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosScoreByBrand`,
        method: "get",
        headers: newHeader,
        params: { userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video success percent:", error);
      throw error;
    }
  }
};

export const getBrandTotalVideoScoreByBrandDealer = async (userUuid: any) => {
  if (userUuid != undefined && "" !== userUuid && "0" !== userUuid) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/videosScoreByBrandDealers`,
        method: "get",
        headers: newHeader,
        params: { userUuid: userUuid },
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video success percent:", error);
      throw error;
    }
  }
};

export const addVideoDetailIds = async (data: any) => {
  if (data) {
    let newHeader = {
      "Content-Type": "application/json",
    };

    try {
      const response = await api({
        url: `${API_URL}videos/addCustomerPartnerOrderId`,
        method: "put",
        headers: newHeader,
        data: data,
      });
      return response.data;
    } catch (error) {
      console.error("Error giving video success percent:", error);
      throw error;
    }
  }
};

export const getVideosScoreAlgorithmByBrandDealers = async (data:any) => {
  const brandId:string = data.brandId;
  const type: string = data.type
  let newHeader = {
    "Content-Type": "application/json",
  };

  try {
    const response = await api({
      url: `${API_URL}videos/getVideoScoringAlgorithmConditions?brandId=${brandId}&type=${type}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error;
  }
};

export const updateVideoScoring = async (data: any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  try {
    const response = await api({
      url: `${API_URL}videos/updateVideoScoring`,
      data,
      method: "put",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting video urls:", error);
    throw error;
  }
};

export const getBatchCsvByEmail = async (data:any) => {
  let newHeader = {
    "Content-Type": "application/json",
  };
  const {userId,batchId,userName,formatType} = data
  try {
    const response = await api({
      url: `${API_URL}videos/getBatchVideoStatus?userId=${userId}&batchId=${batchId}&userName=${userName}&formatType=${formatType}`,
      method: "get",
      headers: newHeader,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error;
  }
};