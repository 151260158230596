import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import "./Signin.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as ACTION from "../../Store/actions/index";
import { useFormik } from "formik";
import { adminHandler, forceChangePwdHandler, signInHandler, signInMessageHandler, userHandler } from "../../Store/slices/authSlice";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { faEye, faEyeSlash, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthWrapper from "../../../components/AuthWrapper";
import { RootState } from "../../Store/store";
import { ADMIN_DASHBOARD, CHANGE_PASSWORD, DASHBOARD, FORGOT_PASSWORD } from "../../../common/routeConstants";
import { useTranslation } from "react-i18next";

const secretKey = process.env.REACT_APP_SECRET_KEY;

const Signin = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRegex = /^(?:\s*(?:(?!\s+$)([\w.-]+(?:\s+[\w.-]+)*)@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})?\s*)?$/;

  const salt = new TextEncoder().encode("some_random_salt");

  const { isUser, isAdmin, forceChangePwd } = useSelector((state: RootState) => state.auth);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState(false);


  const getKey = async (secret: any) => {
    const keyMaterial = await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(secret),
      "PBKDF2",
      false,
      ["deriveKey"]
    );

    return crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-CBC", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
  };

  const encryptPassword = async (password: any, key: any) => {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const encrypted = await crypto.subtle.encrypt(
      { name: "AES-CBC", iv },
      key,
      new TextEncoder().encode(password)
    );
    return {
      iv: Array.from(iv),
      password: Array.from(new Uint8Array(encrypted)),
    };
  };

  const decryptPassword = async (encrypted: any, iv: any, key: any) => {
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-CBC", iv: new Uint8Array(iv) },
      key,
      new Uint8Array(encrypted)
    );
    return new TextDecoder().decode(decrypted);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, t('oauth.enterValidEmail')),
    password: Yup.string()
      .required("Password is required")
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      }),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(signInHandler(values)).then((res: any) => {
        if (res?.type === `${ACTION.LOGIN}/${ACTION.FULFILLED}`) {
          dispatch(forceChangePwdHandler(!!res?.payload?.user.forceChngPwd))
          if (res.payload.roleUser == false) {
            dispatch(adminHandler(res?.payload))
            toast.success("Login Succesfull!", {
              position: "top-right",
              autoClose: 3000,
            });
            navigate(ADMIN_DASHBOARD)
          } else {
            if (res?.payload?.user.forceChngPwd === true) {
              dispatch(userHandler(res?.payload))
              navigate(CHANGE_PASSWORD + '/' + res?.payload?.user.uuid)
              const today: any = new Date();
              const dbDateObj: any = new Date(
                res?.payload?.user?.lastPwdChngAt
              );
              const timeDiff = Math.abs(today - dbDateObj);
              const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              if (res?.payload?.user?.lastPwdChngAt == null) {
                toast.warning("Please Change Your Password", {
                  position: "top-right",
                  autoClose: 3000,
                });
              } else if (dayDiff >= 90) {
                toast.warning(
                  "You need to change your password as last password was changed about 90 days ago",
                  {
                    position: "top-right",
                    autoClose: 3000,
                  }
                );
              }
            } else {
              dispatch(userHandler(res?.payload))
              navigate(DASHBOARD);
              toast.success("Login successfully", {
                position: "top-right",
                autoClose: 2000,
              });
              if (rememberMe) {
                const keyPromise = getKey(secretKey);
                keyPromise.then(async (key) => {
                  const { iv, password: encryptedPassword } =
                    await encryptPassword(values.password, key);
                  localStorage.setItem("email", values.email);
                  localStorage.setItem(
                    "password",
                    JSON.stringify(encryptedPassword)
                  );
                  localStorage.setItem("iv", JSON.stringify(iv));
                });
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("iv");
              }
            }
          }
        } else {
          if (res?.type === `${ACTION.LOGIN}/${ACTION.REJECTED}`) {
            dispatch(
              signInMessageHandler(res?.payload?.response?.data?.message)
            );
            toast.error(res?.payload?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          } else {
            toast.error("Login failed");
          }
        }
      });
    },
  });

  useEffect(() => {
    if (!forceChangePwd) {
      if (isAdmin) {
        navigate(ADMIN_DASHBOARD);
      }
      if (isUser) {
        navigate(DASHBOARD);
      }
    }
  }, [isAdmin, isUser, forceChangePwd]);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("email");
    const encryptedPassword = localStorage.getItem("password");
    const rememberedIV = localStorage.getItem("iv");

    if (rememberedEmail && encryptedPassword && rememberedIV) {
      getKey(secretKey).then(async (key) => {
        const decryptedPassword = await decryptPassword(
          JSON.parse(encryptedPassword),
          JSON.parse(rememberedIV),
          key
        );
        formik.setValues({
          ...formik.values,
          email: rememberedEmail,
          password: decryptedPassword,
        });
        setRememberMe(true);
      });
    }
  }, [])

  return (
    <>
      <AuthWrapper title="Sign in" icon={faShield}>
        <form onSubmit={formik.handleSubmit} className="vstack gap-4">
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            size="small"
            variant="standard"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            id="password"
            name="password"
            label={"Password"}
            size="small"
            variant="standard"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      size="xs"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="hstack justify-content-between align-items-center gap-3 flex-wrap">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="rememberme"
                onChange={handleRememberMe}
                checked={rememberMe}
              />
              <label className="form-check-label mt-1 ms-2" htmlFor="rememberme">
                Remember me
              </label>
            </div>
            <p className="forgotpsd mb-0">
              <Link to={FORGOT_PASSWORD} className="text-decoration-none">{"Forgot Password?"}</Link>                      </p>
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary rounded-4 px-4 py-2 w-100"
            >
              Login
            </button>
          </div>
        </form>
      </AuthWrapper>
    </>
  );
};

export default Signin;
