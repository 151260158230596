import { useEffect, useState } from 'react';
import { DatatableWrapper, Filter, TableBody, TableHeader } from 'react-bs-datatable';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DialogBox from '../../components/DeleteBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import { deleteUserHandler, editUserByIdHandler, getAllUserHandler } from '../Store/slices/userSlice';
import { toast } from 'react-toastify';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { BRAND_DELAER_MAPPING } from '../../common/routeConstants';
import { createUserModalHandler, ShowUserDetailsModalHandler } from '../Store/slices/modalSlice';
import UserCreateEditModal from '../../components/Modal/UserCreateAndEditModal';
import UserViewModal from '../../components/Modal/UserViewModal';
import Eye from "../../assets/images/eye-icon.svg";
import Edit from "../../assets/images/edit.svg";
import Bin from "../../assets/images/bin.svg";
import Previous from "../../assets/images/previous-icon.svg";
import Next from "../../assets/images/next.svg";

const UserDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isAdmin,isUser} = useSelector((state: RootState) => state.auth);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
    const [pageNumber, setPageNumber] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [startItem, setStartItem] = useState(1);
    const [endItem, setEndItem] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const { users } = useSelector((state: RootState) => state.users);
    const [userDeleteID, setUserDeleteID] = useState('');
    const [currentUserfName, setCurrentUserfName] = useState("");
    const [totalRecord, setTotalRecord] = useState(0);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);

    useEffect(() => {
        if (!isAdmin) {
            navigate('/dashboard')
        }else{
            navigate('/admin/dashboard')
        }
    }, [])
    const getUserData = async (isDelete = false) => {
        const trimmedFilterValue = filterValue.trim();
        // Check if filterValue contains only spaces
        if (trimmedFilterValue.length === 0 && filterValue.length > 0) {
            // If filterValue contains only spaces, do not call the API
            return;
        }
        const searchVal = filterValue.trim().length === 0 ? "" : filterValue.trim().replace(/\s+/g, ' ');
        const sortColumnProp = sortColumn.prop;
        const sortColumnOrder = sortColumn.order;

        const data = {
            pageLimit: pageNumber + 1, perPage, searchVal: searchVal, sortColumn: sortColumnProp, sortType: sortColumnOrder
        };

        const userDataList = await dispatch(getAllUserHandler(data));
        if (userDataList?.payload?.currentPage !== undefined) {
            setPageNumber(userDataList?.payload?.currentPage - 1);
            setTotalRecord(userDataList?.payload?.totalItems);

            if (userDataList?.payload.currentPage == userDataList?.payload.totalPages) {
                setIsLastPage(true)
            } else {
                setIsLastPage(false)
            }
            if (userDataList?.payload.currentPage == 1) {
                setIsFirstPage(true)
            } else {
                setIsFirstPage(false)
            }
            if (isDelete && userDataList?.payload?.currentPage > 1 && userDataList?.payload?.dataLength === 0) {
                handlePageClick({ selected: userDataList?.payload?.currentPage - 2 });
            }
        } else {
            setPageNumber(prev => prev);
        }
    };

    const totalrecordHandle = () => {
        let totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalRecord) {
            totalRecordTemp = totalRecord;
        }
        setEndItem(totalRecordTemp);
    };

    const handlePageClick = ({ selected }: { selected: number }) => {
        setPageNumber(selected);
        setStartItem(selected * perPage + 1);
        totalrecordHandle();
    };

    const deleteConfirmHandler = (uuid: any, fName: any) => {
        setShowDelete(true);
        setUserDeleteID(uuid);
        setCurrentUserfName(fName);
    };

    const deleteUser = async (uuid: String) => {
        await dispatch(deleteUserHandler(uuid)).then((response: any) => {
            if (response?.payload?.status === 200) {
                getUserData(true);
                toast.success("User Deleted", {
                    position: "top-right",
                    autoClose: 2500,
                });
            }
        });
    };
    const header = [
        {
            title: 'First Name',
            prop: "fName",
            isSortable: true,
            isFilterable: true,
            cellProps: { style: { width: '300px' } }
        },
        {
            title: 'Email',
            prop: "email",
            isSortable: true,
            isFilterable: true,
        },
        {
            title: "Actions",
            prop: "status",
            thProps: {
                className: "text-end"
            },
            cell: (row: any) => (
                <div className="hstack justify-content-end gap-4">
                    {/* <IconButton className='p-0' aria-label="edit-brands-dealers" color="warning" onClick={() => {
                        const path = SELECT_BRAND_DEALERS.replace(':uuid', row.uuid);
                        navigate(path,{ state: { source: 'assign' } });
                    }} >
                        <img className='img-fluid-user-list-icons' src={Clipboard} alt='Clipboard' />
                    </IconButton> */}
                    <Tooltip placement="top" title={"Edit User"}>
                        <IconButton className='p-0' aria-label="edit" color="success" onClick={() => {
                            dispatch(editUserByIdHandler(row.uuid));
                            dispatch(createUserModalHandler(true))
                        }}>
                            <img className='img-fluid-user-list-icons' src={Edit} alt='Edit' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title={"View User Details"}>
                        <IconButton className='p-0' aria-label="view" color="primary" onClick={() => {
                            dispatch(ShowUserDetailsModalHandler(true))
                            dispatch(editUserByIdHandler(row.uuid))
                        }}>
                            <img className='img-fluid-user-list-icons' src={Eye} alt='eye' />
                        </IconButton>

                    </Tooltip>
                    <Tooltip placement="top" title={"Delete User"}>
                        <IconButton className='p-0' aria-label="delete" color="error" onClick={() => { deleteConfirmHandler(row.uuid, row.fName) }}>
                            <img className='img-fluid-user-list-icons' src={Bin} alt='Bin' />
                        </IconButton>
                    </Tooltip>

                </div>
            ),
        },
    ];

    const openCreateUser = () => {
        dispatch(createUserModalHandler(true));
        dispatch(editUserByIdHandler(""));
    }

    const openBrandDealerModal = () => {
        const path = BRAND_DELAER_MAPPING;
        navigate(path);
    }

    useEffect(() => {
        getUserData();
    }, [perPage, pageNumber, sortColumn, filterValue]);

    useEffect(() => {
        totalrecordHandle();
    }, [startItem, endItem, totalRecord, users]);

    return (
        <div className='dashboard-wrapper'>
            <div className="container-fluid">
                <div className="users">
                    <div className="row">
                        <div className="col-12">
                            <DatatableWrapper body={users} headers={header}>
                                <div className="query-filter mb-3">
                                    <Filter
                                        classes={{
                                            inputGroup: 'w-auto filter-options',
                                            input: ""
                                        }}
                                        placeholder={"Search by Name/Email"}
                                        controlledProps={{
                                            filter: filterValue,
                                            onFilterChange: (value) => {
                                                setFilterValue(value);
                                                setPageNumber(0);
                                            },
                                        }}
                                    />
                                    <Tooltip placement="top" title={"Create Users"}>
                                        <button
                                            onClick={openCreateUser}
                                            className="btn btn-primary hstack gap-2 rounded-4 px-3 mw-120 create-btn"
                                        >
                                            <span>Create Users</span>
                                            <FontAwesomeIcon icon={faUserPlus} />
                                        </button>
                                    </Tooltip>
                                    <button
                                        onClick={openBrandDealerModal}
                                        className="btn btn-primary hstack gap-2 rounded-4 px-3 mw-120 create-btn"
                                    >
                                        <span>Brand Dealer Mapping</span>
                                    </button>
                                </div>
                                <div className="table-responsive custom-table table-middle mb-3">
                                    <table className="table mb-0">
                                        <TableHeader
                                            controlledProps={{
                                                onSortChange: setSortColumn,
                                                sortState: sortColumn,
                                            }}
                                        />
                                        {users.length === 0 ? (
                                            <tbody className="tbody"><tr className="tbody-tr"><td className="tbody-td" colSpan={header.length}><span>No records found</span></td></tr></tbody>
                                        ) : (
                                            <>
                                                <TableBody />
                                            </>
                                        )}
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end">
                                    {totalRecord > 0 &&
                                        <ReactPaginate
                                            pageCount={Math.ceil(totalRecord / perPage)}
                                            previousLabel={<img src={Previous} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                                            nextLabel={<img src={Next} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            disableInitialCallback={true}
                                            initialPage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                                        />
                                    }
                                </div>
                            </DatatableWrapper>
                        </div>
                    </div>
                </div>
                <DialogBox
                    show={showDelete}
                    clickOk={() => {
                        deleteUser(userDeleteID);
                        setShowDelete(false);
                    }}
                    clickCancel={() => { setShowDelete(false); setUserDeleteID('') }}
                    btncancel={"Cancel"}
                    btnyes={"Delete"}
                    question={`Are you sure you want to delete user ${currentUserfName}?`}
                />
                <UserCreateEditModal getUserDataCallback={getUserData} />
                <UserViewModal />
            </div>
        </div>
    );
};

export default UserDashboard;
