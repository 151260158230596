import { faArrowLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrandsHandler, getDealerWithoutBrandHandler, getMappedBrandsDealersHandler, mapBrandandDealerHandler } from '../Store/slices/dealerSlice';
import { RootState } from '../Store/store';
import { TailSpin } from 'react-loader-spinner';
import { Autocomplete, Checkbox, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ADMIN_DASHBOARD } from '../../common/routeConstants';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import Previous from "../../assets/images/previous-icon.svg";
import Next from "../../assets/images/next.svg";

const MapBrandAndDealer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Admin uuid
    const { admin } = useSelector((state: RootState) => state.auth)
    const adminUuId = admin.user.uuid;
    const [loading, setLoading] = useState(false);
    const [filterValue, setFilterValue] = useState<string>("");
    const [brands, setBrands] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [selectedBrand, setSelectedBrand]: any = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const limit = 10;
    const paginationSize = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [selectedDealers, setSelectedDealers] = useState<string[]>([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    // Fetch all brands
    const fetchAllBrands = () => {
        setLoading(true);
        try {
            dispatch(getAllBrandsHandler()).then((res: any) => {
                if (res?.payload?.statusCode === 200) {
                    setBrands(res.payload.data);
                }
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    useEffect(() => {
        fetchAllBrands();
    }, []);

    const fetchAllDealers = () => {
        setLoading(true);
        try {
            const trimmedFilterValue = filterValue.trim();
            if (trimmedFilterValue?.length === 0 && filterValue?.length > 0) {
                setLoading(false);
                return;
            }
            const searchVal = filterValue.replace(/\s+/g, ' ').trim();
            const data: any = {
                pageNumber: pageNumber,
                limit: limit,
                searchVal: searchVal
            }
            dispatch(getDealerWithoutBrandHandler(data)).then((res: any) => {
                if (res?.payload) {
                    const data = res?.payload;
                    setDealers(data.data);
                    setTotalRecords(data.totalItems);
                    setCurrentPage(data.currentPage);
                    if (data.currentPage == data?.totalPages) {
                        setIsLastPage(true)
                    } else {
                        setIsLastPage(false)
                    }
                    if (data.currentPage == 1) {
                        setIsFirstPage(true)
                    } else {
                        setIsFirstPage(false)
                    }
                }
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };
    useEffect(() => {
        if (brands.length > 0) {
            setSelectedBrand(brands[0]);
            fetchMappedDealerAndBrand(brands[0]);
        }
    }, [brands]);
    useEffect(() => {
        fetchAllDealers();
    }, [pageNumber, filterValue]);

    // Change brand handler
    const changeBrandHandler = (e: any, value: any) => {
        setIsAllSelected(false);
        if (!value) {
            setSelectedBrand('');
            setSelectedDealers([]);
            setSelectAllChecked(false);
            // setDisabledDealers([]);
        } else {
            setPageNumber(0);
            setCurrentPage(1);
            setSelectedBrand(value);
            fetchMappedDealerAndBrand(value);
        }
    };

    // Fetch mapped dealer and brand
    const fetchMappedDealerAndBrand = (brand: any) => {
        setLoading(true);
        try {
            const data = {
                userUuid: adminUuId,
                brandUuid: brand.uuid
            }

            dispatch(getMappedBrandsDealersHandler(data)).then((res: any) => {
                if (res?.payload) {
                    const dealers: any = res.payload.data[0].dealers.map((dealer: any) => dealer.uuid);
                    setSelectedDealers(dealers);
                    setSelectAllChecked(false);
                    // setDisabledDealers(dealers);
                }
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    // Handler for page change
    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        if (dealers) {
            setPageNumber(newPageNumber);
            setCurrentPage(newPageNumber);
        }
    };

    // Handle form submit to assign brand and dealer
    const submitHandler = () => {
        setLoading(true);
        if (selectedBrand == "") {
            setLoading(false);
            toast.error("Please select brand", {
                position: "top-right",
                autoClose: 2000
            });
            return;
        }
        const dealers = Object.values(selectedDealers).flat();
        const payload = {
            uuid: adminUuId,
            data: [
                {
                    brand: selectedBrand.uuid,
                    dealers: dealers.map((id: any) => ({ value: id })),
                },
            ],
        };
        dispatch(mapBrandandDealerHandler(payload)).then((res: any) => {
            setLoading(false);
            if (res?.payload.statusCode == 200) {
                toast.success("Brand and Dealers mapped successfully", {
                    position: 'top-right',
                    autoClose: 2000,
                });
                setPageNumber(0);
                setCurrentPage(1);
                fetchMappedDealerAndBrand(selectedBrand);
            }
            else {
                toast.error(res?.payload.message, {
                    position: 'top-right',
                    autoClose: 2500,
                });
            }
        })
    };

    const checkAllHandler = (checked: boolean, currentPageDealers: any[]) => {
        setSelectAllChecked(checked);
        if (checked) {
            const newSelected = currentPageDealers
                .map((dealer: any) => dealer.uuid)
                .filter((uuid) => !selectedDealers.includes(uuid));
            setSelectedDealers((prev) => [...prev, ...newSelected]);
        } else {
            const remaining = selectedDealers.filter(
                (uuid) => !currentPageDealers.map((dealer: any) => dealer.uuid).includes(uuid)
            );
            setSelectedDealers(remaining);
        }
    };

    const checkBoxHandler = (checked: boolean, uuid: string) => {
        if (checked) {
            setSelectedDealers((prev) => [...prev, uuid]);
        } else {
            setSelectedDealers((prev) => prev.filter((id) => id !== uuid));
            setSelectAllChecked(false);
        }
    };
    const handleSelectAll = () => {

        const data: any = {
            pageNumber: -1,
            searchVal: "",
        };

        dispatch(getDealerWithoutBrandHandler(data)).then((res: any) => {
            if (res?.type == 'getDealers/fulfilled') {
                const dealers: any = res.payload.data.map((dealer: any) => dealer.uuid);
                if (dealers.length === selectedDealers.length) {
                    setSelectedDealers([]);
                    setIsAllSelected(false);
                } else {
                    setSelectedDealers(dealers);
                    setIsAllSelected(true);
                }
            }
        });
    };

    useEffect(() => {
        const allSelected =
            dealers.length > 0 &&
            dealers.every((dealer: any) => selectedDealers.includes(dealer.uuid));
        setSelectAllChecked(allSelected);
    }, [dealers, selectedDealers]);

    return (
        <div className="table-container">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader-container">
                        <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                </div>
            )}
            <h2>Manage Brands and Dealers</h2>
            <div className="buttons-container d-flex  align-items-center justify-content-between">
                <div className="d-flex gap-3">
                    <button
                        className='btn btn-primary px-3 mw-120 d-flex align-items-center gap-3'
                        onClick={() => navigate(ADMIN_DASHBOARD)}
                    >
                        <span><FontAwesomeIcon icon={faArrowLeft} /></span>
                        <span className='fw-semibold'>Back</span>
                    </button>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <FormControl style={{ width: '300px', marginRight: '10px' }}>
                        <Autocomplete
                            options={brands}
                            getOptionLabel={(option: any) => option.name || ''}
                            value={selectedBrand}
                            onChange={changeBrandHandler}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Brand" variant="outlined" />
                            )}
                        />
                    </FormControl>
                    <div className="d-flex align-items-center">
                        <input
                            placeholder="Search dealer"
                            className="form-control search-height"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                                height: '55px'
                            }}
                        />
                        <button
                            type="button"
                            className="btn btn-primary search-height"
                            style={{
                                height: '55px',
                                width: '50px',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => setFilterValue('')}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper} className="custom-table mb-3">
                <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", width: '30%' }}>
                                <span className="d-flex align-items-center gap-2">
                                    <span className="checkbox-custom">
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={(e) => checkAllHandler(e.target.checked, dealers)}
                                        />
                                        <span>Select</span>
                                    </span>
                                    <span className="checkbox-custom">
                                        <Checkbox
                                            checked={isAllSelected}
                                            onClick={handleSelectAll}
                                        />
                                        <span> Select All {isAllSelected}</span>
                                    </span>
                                </span>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold" }}>Dealer Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dealers?.length > 0 ? (dealers.map((dealer: any) => {
                            return (
                                <TableRow key={dealer.uuid}>
                                    <TableCell sx={{
                                        verticalAlign: "middle",
                                    }}>
                                        <Checkbox
                                            checked={selectedDealers.includes(dealer.uuid)}
                                            onChange={(e) =>
                                                checkBoxHandler(e.target.checked, dealer.uuid)
                                            }
                                        // disabled={isDisabled}
                                        />
                                    </TableCell>
                                    <TableCell sx={{
                                        verticalAlign: "middle",
                                    }}>{dealer.name}</TableCell>
                                </TableRow>
                            )
                        })) : (
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    No records found.
                                </TableCell>
                            </TableRow>
                        )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="mapBrandDealer-buttons">
                <div className="buttons-container">
                    <button
                        className='btn btn-primary px-3 mw-120 d-flex align-items-center gap-3'
                        onClick={submitHandler}
                        disabled={
                            selectedDealers?.length === 0
                        }
                    >
                        Submit
                    </button>
                </div>
                <div className="pagination-container ">
                    {dealers && dealers?.length > 0 &&
                        <ReactPaginate
                            pageCount={Math.ceil(totalRecords / paginationSize)}
                            previousLabel={<img src={Previous} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                            nextLabel={<img src={Next} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                            onPageChange={({ selected }) => onHandleFetchMore(selected)}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default MapBrandAndDealer;
