
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEye } from '@fortawesome/free-solid-svg-icons';
import "./WebHistory.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import { closeWebhookHistoryModal, getWebhookHistoryHandler, showWebhookHistoryModal, webhookHistoryByIdHandler } from '../Store/slices/oauthSlice';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import * as routeConstant from "../../common/routeConstants";
import ModalComponent from '../../components/Modal';
import WebhookHistoryDetailModal from '../../components/Modal/WebhookHistoryDetailModal';


const WebhookHistory = () => {
    //single hooks starts here
    const paginationSize = 10;
    const dispatch = useDispatch();
    //single hooks ends here

    //useSelector starts here
    const { paginationData } = useSelector((state: RootState) => state.oauth);
    const { user } = useSelector((state: RootState) => state.auth);
    const showWebhookHisModal = useSelector((state: RootState) => state.oauth.showWebhookHistoryModal);
    const userId = user?.user?.uuid;
    //useSelector ends here

    //useStates starts here
    const [pageNumber, setPageNumber] = useState(1);
    const [webhookHistoryData, setWebhookHistoryData] = useState<any>();
    const [webhookHistoryDetails, setWebhookHistoryDetails] = useState<any>();
    const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
    //useStates ends here

    //functions starts here
    const getWebhookHistory = async () => {
        try {
            const data = {
                pageNumber: pageNumber,
                uuid: userId,
                paginationSize: paginationSize,
            };
            const clientData = await dispatch(getWebhookHistoryHandler(data));
            if (clientData.type == 'getAllWebhookHistory/fulfilled') {
                const allWebhistoryData = clientData.payload;
                setWebhookHistoryData(allWebhistoryData);
                setTotalRecords(allWebhistoryData.totalItems);
            }
        } catch (error) {
            console.error('Error fetching webhook history:', error);
        }
    };

    const handleViewDetails = async (uuid: any) => {
        try {
            const response = await dispatch(webhookHistoryByIdHandler(uuid));
            if (response.type === 'getWebhookHistoryById/fulfilled') {
                const webhookHistoryDetails = response.payload.data.data;
                setWebhookHistoryDetails(webhookHistoryDetails);
                handleWebhookHistoryModal();
            }
        } catch (error) {
            console.error("Error fetching webhook details:", error);
        }
    };

    const handleWebhookHistoryModal = () => {
        dispatch(showWebhookHistoryModal());
    };

    const handleCloseWebhookHistoryModal = () => {
        dispatch(closeWebhookHistoryModal());
    };

    const onHandleFetchMore = (selected: any) => {
        
        const newPageNumber = selected + 1;
        if (webhookHistoryData) {
            setPageNumber(newPageNumber);
        }
    };
    //functions ends here

    //useEffects starts here
    useEffect(() => {
        getWebhookHistory();
    }, [userId, pageNumber])
    //useEffects ends here

    return (
        <>
            <div className="min-vh-100 container-fluid">
                <h4>Webhook History</h4>
                <TableContainer component={Paper} className="custom-table mb-3">
                    <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>S.no</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Webhook Type</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Method</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>URL</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Status Code</TableCell>
                                <TableCell sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {webhookHistoryData && webhookHistoryData?.data?.length > 0 ? (
                                webhookHistoryData?.data?.map((row: any, index: any) => (
                                    <React.Fragment key={row.uuid}>
                                        <TableRow>
                                            <TableCell sx={{ padding: "8px", whiteSpace: "nowrap" }}>
                                                {(pageNumber - 1) * paginationSize + index + 1}
                                            </TableCell>
                                            <TableCell sx={{ padding: "8px", whiteSpace: "nowrap" }}>{row.webhook.webhookType}</TableCell>
                                            <TableCell sx={{ padding: "8px", whiteSpace: "nowrap" }}>{row.webhook.method}</TableCell>
                                            <TableCell sx={{ padding: "8px", whiteSpace: "nowrap" }}>{row.webhook.url}</TableCell>
                                            <TableCell sx={{ padding: "8px", whiteSpace: "nowrap" }}>{row.resStatusCode || "N/A"}</TableCell>
                                            <TableCell sx={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "start",
                                                whiteSpace: "nowrap"
                                            }}>
                                                <Tooltip placement="top" title={'View Detail'}>
                                                    <button
                                                        className="btn e-2"
                                                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                                        onClick={() => handleViewDetails(row.uuid)}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                                        No webhook history found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="btn btn-primary mx-2" to={routeConstant.OAUTH}>
                        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />Back
                    </Link>
                    {webhookHistoryData && (
                        <ReactPaginate
                            pageCount={Math.ceil(totalRecords / paginationSize)}
                            previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
                            nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
                            containerClassName={"pagination"}
                            onPageChange={({ selected }) => onHandleFetchMore(selected)}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            forcePage={pageNumber === 0 ? pageNumber : pageNumber - 1}
                        />
                    )}
                </div>
            </div>
            <ModalComponent isOpen={showWebhookHisModal} onClose={handleCloseWebhookHistoryModal} modalHeading={"Webhook History Details"} size='lg'>
                <WebhookHistoryDetailModal webhookHistoryDetails={webhookHistoryDetails} />
            </ModalComponent>
        </>
    );
};

export default WebhookHistory;
