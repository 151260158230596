import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../Store/store';
import BarChart from '../../components/BarChart';
import VideoScoreRatingChart from '../../components/VideoScoreRatingChart';
import BrandVideoScoreRatingChart from '../../components/BrandVideoRatingChart';
import RadialSemiGaugeChart from '../../components/RadialChart';
import ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { getDashboardDataHandler } from '../Store/slices/videoSlice';
import { FULFILLED, GET_CUSTOM_OBJECT_PERCENT } from '../Store/actions';
import { isRedirectedForReportHanlder } from '../Store/slices/commonSlice';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    //single hook start here
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const chartRef = useRef<HTMLDivElement>(null);
    const { user, isUser } = useSelector((state: RootState) => state.auth);
    const { selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.settings);
    const { isRedirectedForReport } = useSelector((state: RootState) => state.common);
    const userId = user?.user?.uuid ?? user?.uuid;
    //single hook ends here

    //useState start here
    const [chartData, setChartData] = useState<any[]>([])
    const [ratingPercent, setRatingPercent] = useState<any[]>([])
    const [brandVideoRatingPercent, setBrandVideoRatingPercent] = useState<any[]>([])
    const [dealerVideosStatusPercent, setDealerVideosStatusPercent] = useState<any>([])
    const [allVideosCount, setAllVideosCount] = useState<number>(0)
    //useState ends here

    //Functions starts here
    const getVideoObjectChart = async () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Bar Chart');
        const chartElement = chartRef.current as HTMLElement;
        if (chartElement) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            const canvas = await html2canvas(chartElement, { scale: 2 });
            const image = canvas.toDataURL('image/png');
            const imageId = workbook.addImage({
                base64: image.split(',')[1],
                extension: 'png',
            });
            const imageSize = {
                width: canvas.width / 2,
                height: canvas.height / 2
            };
            sheet.addImage(imageId, {
                tl: { col: 1, row: 1 },
                ext: imageSize,
            });
        }
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'bar_chart.xlsx');
        dispatch(isRedirectedForReportHanlder(false))
    }

    const dashboardPercentData = (brandUuid: string, dealerUuid: string) => {
        const data = {
            userid: userId,
            brand: brandUuid,
            dealerUuid: dealerUuid
        }
        if (brandUuid != '0') {
            dispatch(getDashboardDataHandler(data)).then((res: any) => {
                if (res.type === GET_CUSTOM_OBJECT_PERCENT + '/' + FULFILLED) {
                    setChartData(res.payload?.customObjectData);
                    setRatingPercent(res.payload?.videoScoreRatingByDealer);
                    setBrandVideoRatingPercent(res.payload?.videoScoreRatingByBrand);
                    setDealerVideosStatusPercent(res.payload?.videosStatus);
                    setAllVideosCount(res.payload?.videosStatus?.allVideosCount);
                }
            })
        }
    }
    //Functions end here

    //useEffects starts here
    useEffect(() => {
        if (!isUser) {
            navigate('/admin/dashboard')
        } else {
            navigate('/dashboard')
        }
    }, [])

    useEffect(() => {
        if (selectedBrandId && selectedDealerId) {
            dashboardPercentData(selectedBrandId, selectedDealerId)
        }
    }, [selectedBrandId, selectedDealerId])

    useEffect(() => {
        if (isRedirectedForReport) {
            getVideoObjectChart()
        }
    }, [isRedirectedForReport])
    //useEffects end here

    return (
        <>
            <div className="row gy-4 mb-4">
                <div className="col-lg-2">
                    <div className="dashboard-card all-number mt-5">
                        <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.successPercent || 0} title={'Success Rate'} color={'#28a745'} isPercent={true} />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="box mt-4">
                        <div className="mt-5">
                            <div id="progress2"></div>
                            <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.failedPercent || 0} title={'Failed Rate'} color={'#dc3545'} isPercent={true} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="box mt-4">
                        <div className="mt-5">
                            <div id="progress3"></div>
                            <RadialSemiGaugeChart avgRadialChartData={dealerVideosStatusPercent?.noisyVideoPercent || 0} title={'Noisy Rate'} color={'#fd7e14'} isPercent={true} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 total-videos">
                    <div className={`card dashboard-card all-number border-info`}>
                        <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                            <h5 className="card-title mb-0 text-truncate" title='Average words per minute'>Average WPM</h5>
                            <div className="fs-1 lh-sm">{dealerVideosStatusPercent?.avgWordsPerMinute || 0}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 total-videos">
                    <div className={`card dashboard-card all-number border-warning`}>
                        <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                            <h5 className="card-title mb-0 text-truncate">Average video length</h5>
                            <div className="fs-1 lh-sm">{dealerVideosStatusPercent?.avgVideoLength || 0}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 total-videos">
                    <div className={`card dashboard-card all-number border-success`}>
                        <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                            <h5 className="card-title mb-0 text-truncate">Total Videos</h5>
                            <div className="fs-1 lh-sm">{allVideosCount}</div>
                        </div>
                    </div>
                </div>
            </div>
            {chartData &&
                <div className="row mb-4">
                    <div className="col-lg-12 mb-3">
                        <div className="dashboard-card all-number dashboard-border-graph">
                            <BarChart brandsVideoObjectsPercent={chartData} chartRef={chartRef} />
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {ratingPercent &&
                    <div className="col-lg-6">
                        <div className="dashboard-card all-number mt-3 mb-5 dashboard-border-graph">
                            <VideoScoreRatingChart brandsVideoRatingPercent={ratingPercent} />
                        </div>
                    </div>
                }
                {brandVideoRatingPercent &&
                    <div className="col-lg-6">
                        <div className="dashboard-card all-number mt-3 mb-5 dashboard-border-graph">
                            <BrandVideoScoreRatingChart brandsVideoRatingPercent={brandVideoRatingPercent} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Dashboard;