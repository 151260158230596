import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import {getThumbnails} from '../../../services/thumbnails.service';

export interface ThumbnailState {
    thumbnails: any[];
}

const initialState: ThumbnailState = {
    thumbnails: [],
};

export const getThumbnailsHandler: any = createAsyncThunk(
    actions.FETCH_THUMBNAILS,
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await getThumbnails(uuid);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const thumbnailsSlice = createSlice({
    name: 'thumbnailsSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getThumbnailsHandler.pending, (state, action) => {
            })
            .addCase(getThumbnailsHandler.fulfilled, (state, action) => {
                
            })
            .addCase(getThumbnailsHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            });
    },
});

export const { } = thumbnailsSlice.actions;
export default thumbnailsSlice.reducer;

