
const WebhookHistoryDetailModal = ({ webhookHistoryDetails }: any) => {
    return (
        <>
            <div className="modal-body fixed-height-container">
                {webhookHistoryDetails ? (
                    webhookHistoryDetails.map((detail: any, index: number) => (
                        <div key={index} className="accordion-content mb-4">
                            <div className="accordion-inner-content">
                                <p><strong>Webhook Type:</strong> {detail.webhook.webhookType}</p>
                                <p><strong>Webhook URL:</strong> {detail.webhook.url}</p>
                                <p><strong>Method:</strong> {detail.webhook.method}</p>

                                <p><strong>Request Headers:</strong></p>
                                <pre className="scrollable-content">{JSON.stringify(detail.reqHeader, null, 2)}</pre>

                                <p><strong>Request Body:</strong></p>
                                <pre className="scrollable-content">{JSON.stringify(JSON.parse(detail.reqBody), null, 2)}</pre>

                                <p><strong>Response Headers:</strong></p>
                                <pre className="scrollable-content">{JSON.stringify(detail.resHeader, null, 2)}</pre>

                                <p><strong>Response Body:</strong></p>
                                <pre className="scrollable-content">{detail.resBody}</pre>

                                <p><strong>Response Status Code:</strong></p>
                                <p>{detail.resStatusCode}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No record found</p>
                )}
            </div>

        </>
    );
};

export default WebhookHistoryDetailModal;
