import { ThunkDispatch } from '@reduxjs/toolkit';
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../pages/Store/store';
import { createUserModalHandler } from '../../pages/Store/slices/modalSlice';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material'
import { ErrorMessage, Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from "yup"
import { defaultLanguage, languages, phoneRegExp, userStatus } from '../../common/common'
import PasswordField from '../../components/PasswordField'
import { ICreateUser, Pair } from '../../types/user.type'
import { getAllBrandsHandler, getAllDealersHandler, isDealerSelectedHandler, selectAllBrandDealerHandler } from '../../pages/Store/slices/dealerSlice';
import { createUserHandler, editUserByIdHandler, getUserByIdHandler, updateUserByUuidhandler } from '../../pages/Store/slices/userSlice';

//BrandDealerPair starts here
interface BrandDealerPair {
    brand: Pair;
    dealers: Pair[];
}
//BrandDealerPair ends here

//SelectFieldProps starts here
interface SelectFieldProps {
    name: string;
    label: string;
    options: Pair[];
}
//SelectFieldProps ends here

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options }) => {
    //single hooks starts here
    const { setFieldValue } = useFormikContext();
    //single hooks ends here

    //functions starts here
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption = options.find(option => option.value === event.target.value);
        if (selectedOption) {
            setFieldValue(name, selectedOption.value);
        }
    };
    //functions ends here
    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <div>
                    <TextField
                        select
                        label={label}
                        fullWidth
                        variant="standard"
                        value={field.value}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={`s1${option.value}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name={name} component="div" className="text-danger txt-error" />
                </div>
            )}
        </Field>
    );
};

//CreateUserModalProps starts here
interface CreateUserModalProps {
    getUserDataCallback: () => void
}
//CreateUserModalProps ends here

const UserCreateAndEditModal: FC<CreateUserModalProps> = ({ getUserDataCallback }) => {
    //single hooks starts here
    const { t } = useTranslation('common');
    const dispatch: ThunkDispatch<{}, {}, any> = useDispatch();
    const emailRegex = /^(?:\s*(?:(?!\s+$)([\w.-]+(?:\s+[\w.-]+)*)@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})?\s*)?$/;
    //single hooks ends here

    //useSelectors starts here
    const { createUserModal } = useSelector((state: RootState) => state.modals);
    const { editUserId } = useSelector((state: RootState) => state.users);
    //useSelectors ends here

    //useStates starts here
    const [brandDealerPairs, setBrandDealerPairs] = useState<BrandDealerPair[]>([]);
    const [createUserLoading, setCreateUserLoading] = useState(false);
    const isUpdate = !!editUserId && editUserId !== '' ? true : false
    //useStates ends here

    //useEffect starts here
    useEffect(() => {
        dispatch(getAllBrandsHandler()).then((res: any) => {
            if (res?.payload?.statusCode === 200) {
                const brandForUser = res.payload.data?.map((e: any) =>
                    ({ value: e.uuid, label: e.name })
                ).filter((item: Pair) => item.label === "TruVideo")
                if (editUserId === '') {
                    const defaultBrand = brandForUser.filter((item: Pair) => item.label === "TruVideo")[0]
                    dispatch(getAllDealersHandler(defaultBrand.value)).then((res: any) => {
                        const dealers = res.payload.data.data.map((dealer: any) => ({
                            value: dealer.uuid,
                            label: dealer.name
                        }));
                        const pair = [{ brand: defaultBrand, dealers: dealers }]
                        setBrandDealerPairs(pair)
                    });
                }
            }
        });
    }, [editUserId]);

    useEffect(() => {
        if (!isUpdate) {
            setEditUserData(initialValues)
            setBrandDealerPairs([]);
            dispatch(editUserByIdHandler(''))
        }
    }, [isUpdate])

    useEffect(() => {
        if (editUserId !== '') {
            getUserByUuids()
        }
    }, [editUserId]);
    //useEffect ends here


    //ICreateUser initialValues starts here
    const initialValues: ICreateUser = {
        firstName: "",
        lastName: "",
        email: "",
        isUpdate: isUpdate,
        password: "",
        status: userStatus[0].value,  //ACTIVE
        contact: "",
        lang: defaultLanguage.value,
    };
    //ICreateUser initialValues ends here

    const [editUserData, setEditUserData] = useState<ICreateUser>(initialValues);

    // Define a regex pattern for password validation
    const passwordRegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&\s]{6,}$/;
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value) => (typeof value === 'string' ? value.trim() : value))
            .test(
                "len",
                t('validation.firstnameLimit'),
                (val: any) => val && val.length >= 3 && val.length <= 50
            ),
        lastName: Yup.string()
            .nullable()
            .max(50)
            .test(
                'valid-last-name',
                t('validation.invalidLastName'),
                (val) => {
                    if (!val) return true;
                    const trimmedValue = val.toString().trim();
                    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
                    return regex.test(trimmedValue);
                }
            ),
        email: Yup.string()
            .required(t('validation.requiredEmail'))
            .matches(emailRegex, t('oauth.enterValidEmail')),
        isUpdate: Yup.boolean(),
        password: Yup.string()
            .when("isUpdate", {
                is: false,
                then: (schema) => schema
                    .required(t('validation.requiredField'))
                    .min(6, t('validation.PasswordLimit')).test('trimmed', t('validation.PasswordTrailingSpace'), (val) => {
                        const trimmedValue = val && val.toString().trim();
                        return trimmedValue === val;
                    })
                    .test('has-lowercase', t('validation.passwordContainLowercase'), (val) => {
                        return /[a-z]/.test(val);
                    })
                    .test('has-uppercase', t('validation.passwordContainUppercase'), (val) => {
                        return /[A-Z]/.test(val);
                    })
                    .test('has-special-char', t('validation.passwordContainSpecial'), (val) => {
                        return /[@$!%*?&]/.test(val);
                    })
                    .test('has-digit', t('validation.passwordContainOnedigit'), (val) => {
                        return /\d/.test(val);
                    })
                    .matches(passwordRegExp, {
                        message: t('validation.PasswordSecurity'),
                        excludeEmptyString: true
                    }),
                otherwise: (schema) => schema.notRequired().nullable()
            }),
        contact: Yup.string().required(t('validation.requiredField')).max(16, t('validation.notValidContact')).matches(phoneRegExp, t('validation.notValidContact')),
    });

    //functions starts here
    const clearForm = () => {
        setEditUserData(initialValues)
        setBrandDealerPairs([]);
        dispatch(editUserByIdHandler(''))
        dispatch(createUserModalHandler(false))
        getUserDataCallback()
    };

    const handleUserCreate = async (formValue: ICreateUser) => {
        if (createUserLoading) return;
        const { firstName, lastName, email, password, contact, status, lang } = formValue;
        const userName = `${firstName.trim()} ${lastName?.trim()}`;

        if (isUpdate) {
            const data = { uuid: editUserId, userName, firstName, lastName, email, status, lang, password };
            if (lastName && lastName?.trim().length > 0) {
                data.lastName = lastName?.trim();
            } else {
                data.lastName = null;
            }
            if (firstName && firstName?.trim().length > 0) {
                data.firstName = firstName?.trim();
            }
            await dispatch(updateUserByUuidhandler(data)).then((response: any) => {
                if (response.type === "updateUserByUuid/fulfilled") {
                    if (response.payload.data.statusCode === 200) {
                        toast.success(response.payload.data.message, { position: 'top-right', autoClose: 2500 });
                        clearForm();
                    }
                }
            })
        } else {
            let data = { userName, firstName, lastName, email, password, contact, status, lang, brandDealerPairs: brandDealerPairs };
            if (lastName && lastName?.trim().length > 0) {
                data.lastName = lastName?.trim()
            } else {
                data.lastName = null
            }
            dispatch(createUserHandler(data)).then((res: any) => {
                if (res?.type === 'createUser/fulfilled') {
                    toast.success(res.payload.message, { position: 'top-right', autoClose: 2500 });
                    clearForm();
                    setCreateUserLoading(false);
                    dispatch(selectAllBrandDealerHandler({ SelectAll: '' }))
                } else {
                    toast.error(res.payload.response.data.message, { position: 'top-right', autoClose: 2500 });
                }
            });
        }

    };

    const getUserByUuids = async () => {
        await dispatch(getUserByIdHandler(editUserId)).then(async (response: any) => {
            if (response.type === 'getUserById/fulfilled') {
                const { fName, lName, email, lang, contact, status, assignedBrands, } = response.payload.data.data;
                var brandDealerObj: any = {}
                const userBrandDealerPair =
                    assignedBrands.map((brand: any) => {
                        brandDealerObj = {
                            brand: { value: brand.uuid, label: brand.name },
                            dealers: []
                        };

                        brand.assignedDealers?.map(async (dealer: any, index: number) => {
                            const dealerObj = {
                                value: dealer.uuid,
                                label: dealer.name
                            };
                            brandDealerObj['dealers'].push(dealerObj);
                        })

                        return brandDealerObj;
                    })

                setBrandDealerPairs(userBrandDealerPair)

                const initialValues: ICreateUser = {
                    firstName: fName,
                    lastName: lName,
                    email: email,
                    isUpdate: isUpdate,
                    password: '',
                    status: status,
                    contact: contact,
                    lang: lang
                };
                setEditUserData(initialValues);
            }
        })

    }
    //functions ends here

    return (
        <Modal centered backdrop="static" show={createUserModal} onHide={clearForm} size="lg" className='custom-modal'>
            <Modal.Header closeButton>
                <Modal.Title className='fw-semibold modal-title'>{!isUpdate ? t('common.userDetails') : t('common.editUser')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Formik enableReinitialize={true}
                        initialValues={editUserData}
                        validationSchema={validationSchema}
                        onSubmit={handleUserCreate}>
                        <Form id='userForm'>
                            <Row>
                                <Col xs={12} lg={6} className='mb-4'>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="firstName"
                                        label="First Name"
                                        variant="standard"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="text-danger txt-error" />
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="lastName"
                                        label="Last Name"
                                        variant="standard"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="text-danger txt-error" />
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        variant="standard"
                                        disabled={isUpdate}
                                    />
                                    <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    {
                                        isUpdate &&
                                        <div>
                                            <SelectField
                                                name="status"
                                                label="Status"
                                                options={userStatus}
                                            />
                                            <ErrorMessage name="status" component="div" className="text-danger txt-error" />
                                        </div>
                                    }
                                    {
                                        !isUpdate &&
                                        <PasswordField name="password" label='Password' />
                                    }
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="contact"
                                        label="Contact"
                                        variant="standard"
                                        disabled={isUpdate}
                                    />
                                    <ErrorMessage name="contact" component="div" className="text-danger txt-error" />
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    <SelectField
                                        name="lang"
                                        label="Select Language"
                                        options={languages}
                                    />
                                    <ErrorMessage name="lang" component="div" className="text-danger txt-error" />
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    {
                                        !isUpdate &&
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            placeholder="brand"
                                            label="Brand"
                                            variant="standard"
                                            value="TrueVideo"
                                            disabled={true}
                                            readOnly={true}
                                        />
                                    }
                                </Col>
                                <Col xs={12} lg={6} className='mb-4'>
                                    {
                                        !isUpdate &&
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            placeholder="brand"
                                            label="Dealer"
                                            variant="standard"
                                            value="Dealer 1"
                                            disabled={true}
                                            readOnly={true}
                                        />
                                    }
                                </Col>
                            </Row>
                            <div className='footer-btn'>
                                <button type='submit' className="btn btn-primary modal-btn" disabled={createUserLoading}>{!isUpdate ? "Create User" : "Save"}</button>
                            </div>
                        </Form>
                    </Formik>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
interface DealerFieldProps {
    dealerOptions: Pair[];
    index: number;
    brandDealerPairsCallback: (data: { index: number, dealers: Pair[] }) => void;
    selectedValues: string[];
    setSelectedValues: (value: string[]) => void;
}

const DealerField: React.FC<DealerFieldProps> = ({ dealerOptions, index, brandDealerPairsCallback, selectedValues, setSelectedValues }) => {
    const { isBrandSelected, isDealerSelected } = useSelector((state: RootState) => state.dealers);

    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    //useStates starts here
    const [open, setOpen] = useState(false);
    //useStates ends here

    //functions starts here
    const handleChange = (event: any) => {
        const value: any = event.target.value;
        if (value.includes('')) {
            return; // Do nothing if an empty string is detected
        }
        if (value.length === dealerOptions.length) {
            dispatch(isDealerSelectedHandler(true))
        } else {
            dispatch(isDealerSelectedHandler(false))
        }

        setSelectedValues(value);
        const data = { index: index, dealers: value.map((item: string) => ({ value: item, label: getLabelFromValue(item) })) };
        brandDealerPairsCallback(data);

        // If there's only one dealer option, close the dropdown
        if (dealerOptions.length === 1) {
            setOpen(false);
        }
    };

    const handleSelectAll = () => {
        if (isDealerSelected) {
            setSelectedValues([]);
            const data = { index: index, dealers: [] };
            brandDealerPairsCallback(data);
            dispatch(isDealerSelectedHandler(false))
        } else {
            setSelectedValues(dealerOptions.map((option: Pair) => option.value));
            const data = { index: index, dealers: dealerOptions };
            brandDealerPairsCallback(data);
            dispatch(isDealerSelectedHandler(true))
        }
    };

    const getLabelFromValue = (value: string) => {
        const label = dealerOptions.find(option => option.value === value)?.label;
        return label || value;
    };
    //functions ends here

    //useEffect starts here
    useEffect(() => {
        if (dealerOptions.length === 1) {
            setOpen(false);
        }
    }, [dealerOptions]);
    //useEffect ends here

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id={`dealer-label-${index}`} shrink={selectedValues.length > 0 ? true : false}>{t('common.selectDealers')}</InputLabel>
            <Select
                labelId={`dealer-label-${index}`}
                multiple
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                name='dealers'
                value={selectedValues}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, idx) => (
                            <Chip key={`selectedValues${value}${idx}`} label={getLabelFromValue(value)} />
                        ))}
                    </Box>
                )}
                onChange={handleChange}
            >
                {isBrandSelected && dealerOptions.length > 1 &&
                    <MenuItem key={`dealerOptionsSelectAll`} value={''}>
                        <ListItemIcon>
                            <Checkbox
                                checked={isDealerSelected}
                                indeterminate={selectedValues.length > 0 && selectedValues.length < dealerOptions.length}
                                onChange={handleSelectAll}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </MenuItem>
                }
                {dealerOptions?.map((option: Pair) => (
                    <MenuItem key={`dealerOptions${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default UserCreateAndEditModal
