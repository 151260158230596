import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Signin from "./pages/Auth/Signin/Signin";
import Signup from "./pages/Auth/Signup/Signup";
import Videos from "./pages/Dashboard/Videos";
import * as routeConstant from "./common/routeConstants";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserDashboard from "./pages/Users/UserDashboard";
import ChangePassword from "./pages/Auth/ChangePassword/ChangePassword";
import { useSelector } from "react-redux";
import { RootState } from "./pages/Store/store";
import AuthVerify from "./common/AuthVerify";
import { TailSpin } from "react-loader-spinner";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import AsignedBrandAndDealers from "./pages/Users/AsignedBrandAndDealers";
import ScratchDetect from "./pages/Dashboard/ScratchDetect";
import VideoScoring from "./pages/Dashboard/VideoScoring";
import BulkVideoUpload from "./components/BulkVideoUpload";
import Layout from "./components/Layout";
import OAuth from "./pages/OAuth/OAuth";
import WebhookHistory from "./pages/OAuth/WebhookHistory";
import MapBrandAndDealer from "./pages/Users/MapBrandAndDealer";
import ViewUserBrandsDealers from "./pages/Users/ViewUserBrandsDealers";
import TireDetect from "./pages/Dashboard/TireDetect";
import ScoringDashboard from "./pages/Dashboard/ScoringDashboard";
import DataByDealer from "./pages/Dashboard/DataByDealer";
import DataByVideo from "./pages/Dashboard/DataByVideo";
import VideoDetail from "./pages/Dashboard/VideoDetail";

const App = () => {
  const { user, admin } = useSelector((state: RootState) => state.auth);
  let token = user?.token || admin?.token;
  const loaderState: any = useSelector((state: RootState) => state.common.showLoader);
  return (
    <div className="App">
      <ToastContainer />
      {loaderState && <div className="loader-overlay">
        <div className="loader-container">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      </div>}
      <Router>
        <Routes>
          <Route path="*" caseSensitive={false} element={<Navigate to={routeConstant.SIGNIN} />} />
          <Route path={routeConstant.SIGNIN} caseSensitive={false} element={<Signin />} />
          <Route path={routeConstant.SIGNUP} caseSensitive={false} element={<Signup />} />
          <Route path={routeConstant.FORGOT_PASSWORD} caseSensitive={false} element={<ForgotPassword />} />
          <Route path={routeConstant.RESET_PASSWORD} element={<ResetPassword />} />

          {token &&
            <>
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate to={routeConstant.DASHBOARD} />} />
                <Route path={routeConstant.VIDEOS} element={<Videos />} />
                {/* <Route path={routeConstant.SELECT_BRAND_DEALERS} element={<SelectBrandAndDealers />} /> */}
                <Route path={routeConstant.SELECT_BRAND_DEALERS} element={<AsignedBrandAndDealers />} />
                <Route path={routeConstant.VIEW_BRAND_DEALERS} element={<ViewUserBrandsDealers />} />
                <Route path={routeConstant.DASHBOARD_GRAPH} element={<Dashboard />} />
                <Route path={routeConstant.DASHBOARD} element={<ScoringDashboard />} />
                <Route path={routeConstant.VIDEO_DETAIL} element={<VideoDetail />} />
                <Route path={routeConstant.ADMIN_DASHBOARD} element={<UserDashboard />} />
                <Route path={routeConstant.CHANGE_PASSWORD + '/:uuid'} element={<ChangePassword />} />
                <Route path={routeConstant.SCRATCHDETECT} element={<ScratchDetect />} />
                <Route path={routeConstant.VIDEO_SCORING} element={<VideoScoring />} />
                <Route path={routeConstant.OAUTH} element={<OAuth />} />
                <Route path={routeConstant.WEBHOOKHISTORY} element={<WebhookHistory />} />
                <Route path={routeConstant.TIREDETECT} element={<TireDetect />} />
                <Route path={routeConstant.BULK_VIDEOS_UPLOAD} element={<BulkVideoUpload />} />
                <Route path={routeConstant.BRAND_DELAER_MAPPING} element={<MapBrandAndDealer/>}/>
                <Route path={routeConstant.DATA_BY_LOCATION} element={<DataByDealer/>}/>
                <Route path={routeConstant.DATA_BY_VIDEO} element={<DataByVideo/>}/>
              </Route>
            </>
          }
        </Routes>
        <AuthVerify />
      </Router>
    </div>
  );
};

export default App;
