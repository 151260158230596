import React, { useState } from 'react';
import ModalComponent from './Modal';
import { useTranslation } from 'react-i18next';

// video player modal props starts here
interface VideoPlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
  video: any;
  isProcessedLink?: boolean;
  videoId?: any
}
// video player modal props ends here

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isOpen, onClose, video, isProcessedLink, videoId }) => {
  //useState starts here
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { t } = useTranslation("common");
  //useState ends here

  //single hook start here
  let NewUrl = ''
  if (video) {
    if (isProcessedLink) {
      NewUrl = video.processedLink
    } else {
      NewUrl = video.storageLink
    }
  }
  //single hook ends here

  //functions starts here
  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsDownloading(true);
    fetch(NewUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${videoId}.mp4` || 'video.mp4';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Download failed:', error))
      .finally(() => setIsDownloading(false));
  };
  //functions starts here

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      {video && (
        <div className="video-player-modal mt-4 mb-4">
          <video controls width="100%">
            <source src={NewUrl} type="video/mp4" />
            {t('VideoProcess.browserNotSupport')}
          </video>
          <div className="download-button-container">
            <a
              href={NewUrl}
              onClick={handleDownload}
              className={`btn btn-primary ${isDownloading ? 'disabled' : ''}`}
            >
              {isDownloading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Downloading...
                </>
              ) : (
                'Download Video'
              )}
            </a>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default VideoPlayerModal;