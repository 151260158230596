import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { submitOauthClient, updateOauthClient } from '../pages/Store/slices/oauthSlice';
import { FULFILLED, OAUTH_ADD, OAUTH_UPDATE } from '../pages/Store/actions';
import { RootState } from '../pages/Store/store';

const AuthForm = ({
    initialData, mode }: { closeModal: any; initialData?: any; mode: 'add' | 'edit' }) => {
    //single hooks starts here
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const isEditMode = mode === 'edit';
    //single hooks ends here

    //useStates starts here
    const [loader, setLoader] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<string | null>(null);
    //useStates ends here

    //useSelector starts here
    const { user } = useSelector((state: RootState) => state.auth);
    const userId = user?.user?.uuid;
    //useSelector ends here

    //formik starts here
    const validationSchema: any = Yup.object().shape({
        clientName: Yup.string().required(t('validation.clientNameRequired'))
            .test('no-leading-space', t('validation.spaceNotAllowed'), value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }),
    })

    const formik = useFormik({
        initialValues: {
            clientName: initialData?.clientName || '',
            clientType: initialData?.clientType || 'Confidential',
            grantType: initialData?.grantType || 'Client Credentials',
            description: initialData?.description || '',
            uuid: initialData?.uuid || '',
            clientId: initialData?.clientId || '',
            userId: userId
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            setLoader(true);
            if (isEditMode) {
                const updatedValues = {
                    ...values,
                    clientName: values.clientName === initialData.clientName ? null : values.clientName,
                    description: values.description === initialData.description ? null : values.description,
                };

                dispatch(updateOauthClient(updatedValues)).then((res: any) => {
                    setLoader(false);
                    if (res?.type === `${OAUTH_UPDATE}/${FULFILLED}`) {
                        toast.success(t("oauth.oauthUpdated"), { position: "top-right", autoClose: 2000 });
                    } else {
                        toast.error(res?.payload?.response?.data?.message, { position: "top-right", autoClose: 2000 });
                    }
                });
            } else {
                dispatch(submitOauthClient(values)).then((res: any) => {
                    setLoader(false);
                    if (res?.type === `${OAUTH_ADD}/${FULFILLED}`) {
                        toast.success(t("oauth.oauthCreated"), { position: "top-right", autoClose: 2000 });
                    } else {
                        toast.error(res?.payload?.response?.data?.message, { position: "top-right", autoClose: 2000 });
                    }
                });
            }
        },
    });
    //formik ends here

    //function starts here
    const handleCopy = (value: string, type: string) => {
        navigator.clipboard.writeText(value).then(() => {
            setIsCopied(type);
            setTimeout(() => setIsCopied(null), 2000);
        });
    };
    //functions ends here

    return (
        <div className="modal-body">
            <form onSubmit={formik.handleSubmit}>
                <div className="row mb-3">
                    <label htmlFor="clientName" className="col-sm-2 col-form-label">
                        <span className="text-danger">*</span> {t('constant.ClientName')}:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            id="clientName"
                            name="clientName"
                            value={formik.values.clientName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.clientName && formik.errors.clientName && (
                            <div className="text-danger">{String(formik.errors.clientName)}</div>
                        )}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="clientType" className="col-sm-2 col-form-label">
                        {t('constants.ClientType')}:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            id="clientType"
                            name="clientType"
                            value={formik.values.clientType}
                            disabled
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="grantType" className="col-sm-2 col-form-label">
                        {t('constants.GrantType')}:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            id="grantType"
                            name="grantType"
                            value={formik.values.grantType}
                            disabled
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="description" className="col-sm-2 col-form-label">
                        {t('constants.Description')}:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                {isEditMode && (
                    <>
                        <div className="row mb-3">
                            <label htmlFor="clientId" className="col-sm-2 col-form-label">
                                {t('constants.ClientId')}:
                            </label>
                            <Tooltip title={isCopied === "clientId" ? "Copied!" : "Copy to clipboard"} placement="top">
                                <button
                                    className="btn col-sm-2 col-form-label d-flex align-items-center"
                                    type="button"
                                    onClick={() => handleCopy(initialData.clientId, "clientId")}
                                >
                                    {initialData?.clientId}
                                    <FontAwesomeIcon
                                        icon={isCopied === "clientId" ? faCheck : faCopy}
                                        className={`ms-2 fs-6 ${isCopied === "clientId" ? "text-primary" : "text-primary"}`}
                                    />
                                </button>
                            </Tooltip>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="clientSecret" className="col-sm-2 col-form-label">
                                {t('constants.ClientSecret')}:
                            </label>
                            <Tooltip title={isCopied === "clientSecret" ? "Copied!" : "Copy to clipboard"} placement="top">
                                <button
                                    className=" btn col-sm-2 col-form-label d-flex align-items-center"
                                    type='button'
                                    onClick={() =>
                                        handleCopy(initialData?.clientCredentials?.clientSecret, "clientSecret")
                                    }
                                >
                                    {initialData?.clientCredentials?.clientSecret}
                                    <FontAwesomeIcon
                                        icon={isCopied === "clientSecret" ? faCheck : faCopy}
                                        className={`ms-2 fs-6 ${isCopied === "clientSecret" ? "text-primary" : "text-primary"
                                            }`}
                                    />
                                </button>
                            </Tooltip>
                        </div>
                    </>
                )}
                <div className="row mb-3">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        <button type="submit" className="btn btn-primary" disabled={loader}>
                            {loader ? "Processing..." : "Submit"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AuthForm;
