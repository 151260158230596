import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getAllMapBrandsHandler, getAllMapDealersHandler, getBrandsForUserHandler, getFilterDealersForUserHandler } from '../pages/Store/slices/dealerSlice';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BrandDealerFilterProps {
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
    selectedBrand: string | null;
    selectedDealer: string | null;
    setSelectedDealer: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedBrand: React.Dispatch<React.SetStateAction<string | null>>;
    filterValue: string;
    handleReset: () => void;
    handleApply: () => void;
    setFilterValue: any
}
const BrandDealerFilter: React.FC<BrandDealerFilterProps> = ({
    setShowFilter,
    selectedBrand,
    selectedDealer,
    setSelectedDealer,
    setSelectedBrand,
    filterValue,
    handleReset,
    handleApply,
    setFilterValue
}) => {
    //single hooks starts here
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const userId: any = useParams();
    const location = useLocation();
    const source = location.state?.source;
    const isBrandEmpty = !selectedBrand || Object.keys(selectedBrand).length === 0;
    const isDealerEmpty = !selectedDealer || Object.keys(selectedDealer).length === 0;
    const isFilterEmpty = !filterValue || filterValue.trim() === "";
    //single hooks ends here

    //useStates starts here
    const [dealers, setDealers] = useState<any>([]);
    const [brands, setBrands] = useState<any>([]);
    //useStates ends here

    //function starts here
    const fetchData = async () => {
        if (source == 'assign') {
            try {
                const brandsResponse = await dispatch(getAllMapBrandsHandler());
                if (brandsResponse?.type === 'getAllMapBrands/fulfilled') {
                    const brands = brandsResponse?.payload?.data?.data || [];
                    const updatedBrands = brands.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    setBrands(updatedBrands);
                }

                const dealersResponse = await dispatch(getAllMapDealersHandler());
                if (dealersResponse?.type === 'getAllMapDealers/fulfilled') {
                    const dealers = dealersResponse?.payload?.data?.data || [];
                    const updatedDealers = dealers.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    setDealers(updatedDealers);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        else {
            try {
                const brandsResponse = await dispatch(getBrandsForUserHandler(userId));
                if (brandsResponse?.type === 'getBrandsForUser/fulfilled') {
                    const brands = brandsResponse?.payload?.data?.data || [];
                    const updatedBrands = brands.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    setBrands(updatedBrands);
                }
                const dealersResponse = await dispatch(getFilterDealersForUserHandler(userId));
                if (dealersResponse?.type === 'getFilterDealer/fulfilled') {
                    const dealers = dealersResponse?.payload?.data?.data?.dealers || [];
                    const updatedDealers = dealers.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    setDealers(updatedDealers);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };
    //function ends here

    //useEffect starts here
    useEffect(() => {
        fetchData();
    }, []);
    //useEffect ends here

    return (
        <div
            className="mt-2 filter-dropdown"
        >
            <div className="text-end">
                <button
                    className="btn p-0 text-end"
                    onClick={() => setShowFilter(false)}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className="mb-3">
                <FormControl style={{ width: "100%" }}>
                    <Autocomplete
                        options={brands}
                        getOptionLabel={(option: any) => option.name || ""}
                        value={selectedBrand}
                        onChange={(event, newValue) => setSelectedBrand(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Brand" variant="outlined" />
                        )}
                    />
                </FormControl>
            </div>
            <div className="mb-3">
                <FormControl style={{ width: "100%" }}>
                    <Autocomplete
                        options={dealers}
                        getOptionLabel={(option: any) => option.name || ""}
                        value={selectedDealer}
                        onChange={(event, newValue) => setSelectedDealer(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Dealer" variant="outlined" />
                        )}
                    />
                </FormControl>
            </div>
            <div className="form-outline" style={{ position: 'relative', width: "100%" }}>
                <span
                    style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#0085CA",
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} />
                </span>
                <input
                    type="search"
                    id="form1"
                    placeholder="Search"
                    className="form-control search-height"
                    value={filterValue}
                    onChange={(e: any) => setFilterValue(e.target.value)}
                    style={{ paddingLeft: "35px" }}
                />
            </div>
            <div className="filter-buttons-container">
                <button className="btn btn-danger" onClick={handleReset}>
                    {t('common.reset')}
                </button>
                <button className="btn btn-primary" onClick={handleApply} disabled={isBrandEmpty && isDealerEmpty && isFilterEmpty}>
                    {t('common.apply')}                </button>
            </div>
        </div>
    )
}

export default BrandDealerFilter