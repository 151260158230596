import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer'
import videoReducer from './slices/videoSlice';
import authReducer, { logoutHandler } from './slices/authSlice';
import dealersReducer from './slices/dealerSlice';
import userReducer from './slices/userSlice'
import loaderSlice from './slices/commonSlice';
import tireSlice from './slices/tireScanSlice';
import oauthReducer from './slices/oauthSlice';
import settingsReducer from './slices/settingsSlice';
import modalsReducer from './slices/modalSlice';
import { SIGNIN } from '../../common/routeConstants';
import dashboardReducer from './slices/dashboardSlice';

// Config for persisting only settings-related data
const settingsPersistConfig = {
  key: 'settings',
  storage,
};

//Reducers 
const appReducer = combineReducers({
  video: videoReducer,
  dealers: dealersReducer,
  auth: authReducer,
  users: userReducer,
  common: loaderSlice,
  tire: tireSlice,
  oauth: oauthReducer,
  modals: modalsReducer,
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  dashboard: dashboardReducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === logoutHandler.type) {
    // Clear everything except the settings slice
    storage.removeItem('persist:root');
    window.location.href = process.env.REACT_APP_URL + SIGNIN
    return appReducer({ settings: state.settings }, action);
  }

  return appReducer(state, action);
};

//persist configuration
const persistConfig: any = {
  key: 'root',
  storage: storage,
  blacklist: ['common'],
}


const persistedReducer = persistReducer(persistConfig, rootReducer)

//store configuration
export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // immutableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

