export const PENDING = "pending";
export const FULFILLED = "fulfilled";
export const REJECTED = "rejected";

/// dispatch auth actions ///
export const SIGNUP = "signUp";
export const LOGIN = "signIn";
export const FORGOTPASSWORD = "forgotPassword";
export const RESETPASSWORD = "resetPassword";

export const VIDEODATA = "videodata";
export const SCRATCH_DETECT_VIDEO = "ScratchDetectVideo";

export const UPLOAD_VIDEO ='uploadVideo';
export const UPLOAD_EXCEL_VIDEO ='upload-excel-video';
export const GET_VIDEO_URL ='getVideoUrl';
export const GET_VIDEO_BATCH ='getUploadBatch';
export const GET_VIDEO_DETAILS = 'getVideoDetails';
export const GET_SCRATCH_VIDEO_DETAILS = 'getScratchVideoDetails';
export const SUBMIT_VIDEO_URLS = 'submitVideoUrls';
export const SUBMIT_SCRATCH_VIDEO_URLS = 'submitScratchVideoUrls';
export const VIDEO_UPLOAD_S3 = 'uploadVideoS3';
export const SCRATCH_VIDEO_UPLOAD_S3 = 'scratchUploadVideoS3';
export const ADD_VIDEO = 'addVideo';
export const ADD_SCRATCH_VIDEO = 'add-scratch-video';
export const FETCH_THUMBNAILS = 'getThumbnails';
export const RETRY_VIDEO_ANALYSIS = 'retryVideoAnalysis';
export const RETRY_SCRATCHED_VIDEO_ANALYSIS = 'retryScratchedVideoAnalysis';
export const GET_TRANSCRIPTION_TEXT = 'getTranscriptionText'
export const DELETE_VIDEO = 'deleteVideo'
export const DELETE_SCRATCH_VIDEO = 'deleteScratchVideo'
export const GET_ALL_DEALERS = 'getAllDealers';
export const GET_All_BRANDS_DEALERS = 'getAllBrandsAndDealers'
export const GET_DEALERS_VIDEOS = 'getDealersVideos';
export const GET_CUSTOM_OBJECT_PERCENT = 'getCustomObjectPercent'
export const GET_BRANDS = 'getAllBrands';
export const GET_VIDEO_RATING_PERCENT = 'getVideoScorePercent';
export const GET_BRAND_VIDEO_RATING_PERCENT = 'getBrandVideoRatingPercent'
export const GET_USER_BRANDS = 'getUserBrands'
export const GET_USER_DEALERS = 'getUserDealers'
export const GET_BRANDS_TOTAL_VIDEO_COUNT = 'getBrandsTotalVideoCount'
export const GET_DEALERS = 'getDealers';
export const MAP_BRAND_DEALER = 'map_brand_dealer'
export const GET_BRANDS_TOTAL_VIDEO_AVG_LENGTH = 'getBrandsTotalVideoAvgLength'
export const GET_BRANDS_TOTAL_VIDEO_NOISY_RATE = 'getBrandVideosNoisyRate'
export const GET_BRANDS_TOTAL_VIDEO_SUCCESS_RATE = 'getBrandsVideoSuccessRate'
export const GET_BRANDS_TOTAL_VIDEO_FAILURE_RATE = 'getBrandVideoFailureRate'
export const ADD_VIDEO_DETAIL_IDS = 'addVideoDetailIds'
export const GET_VIDEO_SCORING_ALGORITHM = 'getVideoScoringAlgorithm'
export const UPDATE_VIDEO_SCORING = 'updateVideoScoring'
export const GETTYRESCAN = "getTireScan"
export const GETTIRESCANTYPEDETAIL = "getTireScanTypeDetail"
export const UPDATEMANUALTREADDEPTH = "updatemanualtreaddepth"
export const DELETETIRE = "deletetire"
export const OAUTH_ADD = "register"
export const OAUTH_EDIT = "getCredentials"
export const OAUTH_UPDATE = "updateCredentials"
export const OAUTH_DELETE = "deleteCredentials"
export const CREATEWEBHOOK ='registerWebhook'
export const GETWEBHOOKBYID = 'getWebhookById'
export const UPDATEWEBHOOK = 'updateWebhook'
export const BATCHCSVBYEMAIL = 'batchCsvByEmail'
export const GETWEBHOOKHISTORY = 'getAllWebhookHistory'
export const GETWEBHOOKHISTORYBYID = 'getWebhookHistoryById'
export const GET_DASHBOARD_DATA = 'getDashboardData'
export const GET_MAPPED_BRANDS_DEALERS = 'getMappedBrandsDealers'
export const GET_USER_BRAND_DEALER = 'getUserBrandDealer'
export const SAVE_USER_BRAND_DEALER = 'saveUserBrandDealer'
export const GET_BRANDS_FOR_USER = 'getBrandsForUser'
export const GET_DEALERS_FOR_USER = 'getDealersForUser'
export const GET_FILTER_DEALERS_FOR_USER = 'getFilterDealer'
export const GET_ALL_MAPPED_BRANDS = 'getAllMapBrands'
export const GET_ALL_MAPPED_DEALERS = 'getAllMapDealers'
export const GET_ALL_MAPPED_BRAND_DEALER = 'getAllMappedBrandDealer'
export const DELETE_ASSIGNED_BRANDS_DEALERS = 'deleteAssignBrandsDealers'
export const GET_DATA_BY_VIDEO = 'getDataByVideo'
export const GET_DATA_BY_DEALER = 'getDataByDealer'