import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { persistor, store } from './pages/Store/store'
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import "./styles/main.scss";


i18next.init({
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.language,
  fallbackLng: "en",
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
