import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { deleteUserAssignedBrandDealer, getAllDealers, getAllMapBrands, getAllMapDealers, getAllMappedBrandDealer, getBrands, getBrandsforUser, getDealersforUser, getDealersVideos, getDealerWithoutBrandId, getFilterDealersforUser, getMapDealerForUser, getMappedBrandsDealers, getMappedBrandsforUser, getUserAssignedBrandDealer, getUserBrandDealer, getUserBrands, getUserDealers, mapBrandAndDealer, saveUserBrandDealer } from "../../../services/dealers.service";

export interface DealersState {
    dealers: any[];
    selectedDealer: any;
    brands: any;
    isBrandSelected: boolean;
    isDealerSelected: boolean;
    selectAllBrandDealerValue: any
}

const initialState: DealersState = {
    dealers: [],
    selectedDealer: {},
    brands: [],
    isBrandSelected: false,
    isDealerSelected: false,
    selectAllBrandDealerValue: []
};

export const getAllDealersHandler: any = createAsyncThunk(
    actions.GET_ALL_DEALERS,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getAllDealers(data);
            return response;
        } catch (error) {
            return (error);
        }
    }
);

export const getDealersVideoHandler: any = createAsyncThunk(
    actions.GET_DEALERS_VIDEOS,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getDealersVideos(data);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllBrandsHandler: any = createAsyncThunk(
    actions.GET_BRANDS,
    async () => {
        try {
            const response = await getBrands();
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);

export const getAllUserBrandsHandler: any = createAsyncThunk(
    actions.GET_USER_BRANDS,
    async (userId: string) => {
        try {
            const response = await getUserBrands(userId);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);

export const getUserDealersHandler: any = createAsyncThunk(
    actions.GET_USER_DEALERS,
    async (data: any) => {
        try {
            const response = await getUserDealers(data);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);

export const getDealerWithoutBrandHandler: any = createAsyncThunk(
    actions.GET_DEALERS,
    async (data: any) => {
        try {
            const response = await getDealerWithoutBrandId(data);
            return response;
        } catch (error) {
            return error;
        }
    }
)

export const mapBrandandDealerHandler: any = createAsyncThunk(actions.MAP_BRAND_DEALER, (data: any, { rejectWithValue }) =>
    mapBrandAndDealer(data).catch((error: any) => error && rejectWithValue(error))
);

export const getMappedBrandsDealersHandler: any = createAsyncThunk(
    actions.GET_MAPPED_BRANDS_DEALERS,
    async (data: any) => {
        try {
            const response = await getMappedBrandsDealers(data);
            return response;
        } catch (error) {
            return error;
        }
    }
)

export const getMappedBrandForUser: any = createAsyncThunk(actions.GET_MAPPED_BRANDS_DEALERS,
    async () => {
        try {
            const response = await getMappedBrandsforUser();
            return response;
        } catch (error) {
            return error;
        }
    });


export const getMappedDealerForUser: any = createAsyncThunk(actions.GET_MAPPED_BRANDS_DEALERS,
    async (data: any) => {
        try {
            const response = await getMapDealerForUser(data);
            return response;
        } catch (error) {
            return error;
        }
    });
export const getUserBrandDealerHandler: any = createAsyncThunk(actions.GET_USER_BRAND_DEALER,
    async (data: any) => {
        try {
            const response = await getUserBrandDealer(data);
            return response;
        } catch (error) {
            return error;
        }
    });
export const saveUserBrandDealerHandler: any = createAsyncThunk(actions.SAVE_USER_BRAND_DEALER,
    async (data: any) => {
        try {
            const response = await saveUserBrandDealer(data);
            return response;
        } catch (error) {
            return error;
        }
    });

export const getBrandsForUserHandler: any = createAsyncThunk(actions.GET_BRANDS_FOR_USER,
    async (data: any) => {
        try {
            const response = await getBrandsforUser(data);
            return response;
        } catch (error) {
            return error;
        }
    });
export const getFilterDealersForUserHandler: any = createAsyncThunk(actions.GET_FILTER_DEALERS_FOR_USER,
    async (data: any) => {
        try {
            const response = await getFilterDealersforUser(data);
            return response;
        } catch (error) {
            return error;
        }
    });


export const getDealersForUserHandler: any = createAsyncThunk(actions.GET_DEALERS_FOR_USER,
    async (data: any) => {
        try {
            const response = await getDealersforUser(data);
            return response;
        } catch (error) {
            return error;
        }
    });
export const getUserAssignedBrandDealerHandler: any = createAsyncThunk(actions.GET_DEALERS_FOR_USER,
    async (data: any) => {
        try {
            const response = await getUserAssignedBrandDealer(data);
            return response;
        } catch (error) {
            return error;
        }
    });

// new slices 

export const getAllMapBrandsHandler: any = createAsyncThunk(actions.GET_ALL_MAPPED_BRANDS,
    async () => {
        try {
            const response = await getAllMapBrands();
            return response
        }
        catch (error: any) {

        }
    }
)
export const getAllMapDealersHandler: any = createAsyncThunk(actions.GET_ALL_MAPPED_DEALERS,
    async () => {
        try {
            const response = await getAllMapDealers();
            return response
        }
        catch (error: any) {

        }
    }
)
export const getAllMappedBrandDealerHandler: any = createAsyncThunk(actions.GET_ALL_MAPPED_BRAND_DEALER,
    async (data: any) => {
        try {
            const response = await getAllMappedBrandDealer(data);
            return response;
        }
        catch (error) {
            return error;
        }
    }
)
export const deleteUserAssignedBrandDealerHandler: any = createAsyncThunk(actions.DELETE_ASSIGNED_BRANDS_DEALERS,
    async (data: any) => {
        try {
            const response = await deleteUserAssignedBrandDealer(data);
            return response;
        } catch (error) {
            return error;
        }
    });


export const dealersSlice = createSlice({
    name: 'dealersSlice',
    initialState,
    reducers: {
        dealersHandler: (state, { payload }: PayloadAction<any>) => {
            state.dealers = payload;
        },
        isBrandSelectedHandler: (state, { payload }: PayloadAction<any>) => {
            state.isBrandSelected = payload;
        },
        isDealerSelectedHandler: (state, { payload }: PayloadAction<any>) => {
            state.isDealerSelected = payload;
        },
        selectAllBrandDealerHandler: (state, { payload }: PayloadAction<any>) => {
            state.selectAllBrandDealerValue = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDealersHandler.pending, (state, action) => {
            })
            .addCase(getAllDealersHandler.fulfilled, (state, action) => {
                state.dealers = action.payload
            })
            .addCase(getAllDealersHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(getAllBrandsHandler.pending, (state, action) => {
            })
            .addCase(getAllBrandsHandler.fulfilled, (state, action) => {
                state.brands = action.payload.data
            })
            .addCase(getAllBrandsHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            });
    },
});

export const { dealersHandler, isBrandSelectedHandler, isDealerSelectedHandler, selectAllBrandDealerHandler } = dealersSlice.actions;
export default dealersSlice.reducer;

