
import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

export const getAllDealers = async (data: any) => {
  if (data != '') {
    const newHeader = {
      'Content-Type': 'application/json'
    };
    return await api.get(`${API_URL}dealers`, {
      headers: newHeader,
      params: {
        brand: data
      },
    });
  }
};

export const getDealersVideos = async (data: any) => {
  const { userid, pageNumber, selectedDealer } = data;
  if (selectedDealer != undefined && selectedDealer != '' && selectedDealer != '0') {
    const newHeader = {
      'Content-Type': 'application/json'
    };
    try {
      const response = await api.get(`${API_URL}videos`, {
        headers: newHeader,
        params: {
          pageNumber,
          userid,
          uuid: selectedDealer
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching dealer videos:', error);
      throw error;
    }
  }
};

export const getBrands = async () => {
  return api.get(`${API_URL}dealers/brands`).then((response: any) => {
    return response.data;
  });
};

export const getUserBrands = async (uuid: string) => {
  return api.get(`${API_URL}dealers/user-brands?userId=${uuid}`).then((response: any) => {
    return response.data;
  });
};

export const getUserDealers = async (data: any) => {
  return api.get(`${API_URL}dealers/user-dealers?userUuid=${data?.userId}&brandUuid=${data.brand}`).then((response: any) => {
    return response.data;
  });
};

export const getDealerWithoutBrandId = async (data: any) => {
  return api.get(`${API_URL}dealers/dealers?searchVal=${data.searchVal}&pageNumber=${data.pageNumber}&limit=${data.limit}&sortColumn=null&sortType=null`).then((response: any) => {
    return response.data;
  })
}

export const getMappedBrandsDealers = async (data: any) => {
  try {
    const response = await api.get(`${API_URL}dealers/get-map-brand-dealer?pageNumber=-1`, {
      params: {
        userUuid: data?.userUuid,
        brandUuid: data.brandUuid
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dealer videos:', error);
    throw error;
  }

}

export const mapBrandAndDealer = async (data: any) => {
  const uuid = data.uuid;
  try {
    const response = await api.post(API_URL + `dealers/map-brand-dealer/${uuid}`,
      data.data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error in mapBrandAndDealer:", error.response || error.message);
    throw error;
  }
};

export const getMappedBrandsforUser = async () => {
  try {
    const response = await api.get(API_URL + `users/get-all-map-brands-dealers`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
}

export const getMapDealerForUser = async (data: any) => {
  const brandUuid = data.brandUuid;
  const adminUuid = data.adminUuid;
  const searchVal = data.searchVal
  const pageNumber = data.pageNumber;
  const limit = data.limit;
  try {
    const response = await api.get(API_URL + `dealers/get-map-brand-dealer?userUuid=${adminUuid}&brandUuid=${brandUuid}&searchVal=${searchVal}&pageNumber=${pageNumber}&limit=${limit}&sortColumn=null&sortType=null`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
}

export const getUserBrandDealer = async (data: any) => {
  const uuid = data.uuid;
  try {
    const response = await api.get(API_URL + `users/get-user-brand-dealers/${uuid}`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
}

export const saveUserBrandDealer = async (data: any) => {
  const userId = data[0]?.userId
  try {
    const response = await api.post(API_URL + `users/assignee/brands-dealers-user/${userId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }catch (error: any) {
    console.error("Error in mapBrandAndDealer:", error.response || error.message);
    throw error;
  }
};

export const getBrandsforUser = async (data: any) => {
  const uuid = data.uuid;
  try {
    const response = await api.get(API_URL + `users/get-user-brand?uuid=${uuid}`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
};

export const getFilterDealersforUser = async (data: any) => {
  const uuid = data.uuid;
  try {
    const response = await api.get(API_URL + `users/get-user-assigned-dealers?userUuid=${uuid}`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
};

export const getUserAssignedBrandDealer = async (data: any) => {
  try {
    return api.get(`${API_URL}users/user-assigned-brands-delears?userUuid=${data?.userUuid}&brandUuid=${data?.brandUuid}&dealerUuid=${data?.dealerUuid}&searchVal=${data?.searchVal}&pageNumber=${data?.pageNumber}&limit=${data?.limit}&sortColumn=null&sortType=null`).then((response: any) => {
      return response.data;
    })
  }
  catch (error: any) {
    throw error;
  }
};

export const getDealersforUser = async (data: any) => {
  const brandUuid = data.brandUuid;
  const userUuid = data.userUuid;
  const searchVal = data.searchVal
  const pageNumber = data.pageNumber;
  const limit = data.limit;
  try {
    const response = await api.get(API_URL + `users/get-user-dealers?userUuid=${userUuid}&brandUuid=${brandUuid}&searchVal=${searchVal}&pageNumber=${pageNumber}&limit=${limit}&sortColumn=null&sortType=null`);
    return response;
  }
  catch (error: any) {
    throw error;
  }
};

export const getAllMapBrands = async () => {
  try {
    const response = await api.get(API_URL + `users/get-all-map-brands`)
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const getAllMapDealers = async () => {
  try {
    const response = await api.get(API_URL + `users/get-all-map-dealers`)
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const getAllMappedBrandDealer = async (data: any) => {
  const brandUuid: any = data.brandUuid ?? '';
  const dealerUuid: any = data.dealerUuid ?? '';
  const searchVal: any = data.searchVal ?? ''
  const pageNumber = data.pageNumber;
  const limit = data.limit;
  try {
    const response = await api.get(API_URL + `users/get-all-mapped-brand-dealer?pageNumber=${pageNumber}&limit=${limit}&searchVal=${searchVal}&brandId=${brandUuid}&dealerId=${dealerUuid}`)
    return response;
  }
  catch (error: any) {
    throw error;
  }
}

export const deleteUserAssignedBrandDealer = async (data: any) => {
  try {
    const response = await api.post(API_URL + `users/delete-user-assigned-brands-dealers`,
      data,
    );
    return response.data;
  } catch (error: any) {
    console.error("Error in mapBrandAndDealer:", error.response || error.message);
    throw error;
  }
}
