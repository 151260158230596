import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;


export const getDataByDealer = async (data: any) => {
    try {
      const response = await api.get(`${API_URL}videos/getDataByDealer?userUuid=${data.userUuid}&brandUuid=${data.brandUuid}&dealerUuid=${data.dealerUuid}&region=${data.region}&searchVal=${data.searchVal}&pageNumber=${data.pageNumber}&limit=${data.limit}&sortColumn=null&sortType=null`, {
        data
      });
      return response;
    } catch (error) {
      throw error
    }
  }
  
  export const getDataByVideo = async (data:any) => {
    if(
    data?.pageNumber != undefined &&
    data.pageNumber != "" &&
    data.pageNumber != "0" &&
    data?.limit != undefined &&
    data.limit != "" &&
    data.limit != "0" &&
    data?.userId != undefined &&
    data?.userId != "" &&
    data?.userId != "0" &&
    data?.brandUuid != undefined &&
    data?.brandUuid != "" &&
    data?.brandUuid != "0"
    ){
    try {
      const response = await api.get(`${API_URL}videos/get-data-by-video?pageNumber=${data?.pageNumber}&limit=${data?.limit}&brandUuid=${data?.brandUuid}&userId=${data?.userId}&searchByRoNumber=${data?.searchByRoNumber}&searchByDealerName=${data?.searchByDealerName}&searchByTechnicianName=${data?.searchByTechnicianName}&region=${data?.region}`);
      return response?.data;
    } catch (error) {
      throw error
    }
  }
  }

  export const getVideoDetails = async (uuid: any) => {
    try {
        return api.get(`${API_URL}videos/getVideoDetail?uuid=${uuid}`).then((response: any) => {
          return response.data;
        });
    } catch (error) {
      throw error
    }
  }
  