import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap'
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVideoDetailsHandler } from '../Store/slices/dashboardSlice';
import VideoPlayerModal from '../../components/VideoPlayerModal';
import RadialSemiGaugeChart from '../../components/RadialChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { addVideoCustomerIdHandler } from '../Store/slices/videoSlice';

const VideoDetail = () => {
  //single hook starts here
  const params = useParams();
  const dispatch = useDispatch();
  //single hook ends here

  //useState starts here
  const [loader, setLoading] = useState<boolean>(false);
  const [data, setData]: any = useState<any>(null);
  const [showVideoModal, setShowVideoModal] = useState<any>(false);
  const [videoScoringAlgorithmPoints, setVideoScoringAlgorithmPoints] = useState<any>([]);
  const [videoScoringAlgorithm, setVideoScoringAlgorithm] = useState<any>([]);
  const [showEditModal, setShowEditModal] = useState<any>(false);
  const [modalData, setModalData] = useState<any>({ title: '', field: '', value: 'N/A' });
  const [isTouched, setIsTouched] = useState<any>(false);
  //useState ends here

  //functions starts here
  const getVideoDetails = async () => {
    setLoading(true);
    try {
      const result = await dispatch(getVideoDetailsHandler(params.uuid));
      if (result.type === "getDataByVideo/fulfilled") {
        setData(result.payload);
        setVideoScoringAlgorithmPoints(result?.payload?.videoScoringAlgorithmPoints)
        setVideoScoringAlgorithm(result?.payload?.videoScoringAlgorithm)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  }

  const allVideoObjects = [
    'car', 'license plate', 'brakes', 'tires', 'air filters',
    'indicators', 'wiper blades', 'brake pad gauge', 'tire gauge',
    'dirty air filters', 'car interior'
  ];
  const allMonitoredObjects = ['battery', 'brake', 'car', 'filter', 'indicator', 'license plate', 'tire', 'wiper', 'fluids', 'air filter', 'oil filter'];

  const splitFormulaMap = (): { [key: string]: (formula: string) => string[] } => ({
    'split': (formula: string) => {
      const regex = /([a-zA-Z_][a-zA-Z0-9_]*|\d+|\+|\-|\*|\/|\(|\))/g;
      return formula.match(regex) || [];
    },
  });

  const replaceVariablesMap = (): { [key: string]: (components: string[], data: number) => string[] } => ({
    'replace': (components: string[], data: number) => {
      return components.map(item => {
        if (/^[a-zA-Z_]\w*$/.test(item)) {
          return typeof item === 'string' ? String(data) : item; // Convert data to string if it's a number
        }
        return item;
      });
    },
  });


  const parseFormula = (formula: any) => {
    const match = formula.match(/^\(?\{?(\w+)\}?\)?(.*)$/);
    if (match) {
      const variable = match[1];
      return variable;
    }
  };
  const videoAlgorithmConditions = [
    { value: '', label: 'Select a video algorithm Condition' },
    { value: 'between', label: 'Between' },
    { value: 'between_proportionality', label: 'Between' },
    { value: 'not_between_proportionality', label: 'Not between' },
    { value: 'not_between', label: 'Not between' },
    { value: 'gte', label: 'Greater Than or Equal To' },
    { value: 'lte', label: 'Less Than or Equal To' },
    { value: 'eq', label: 'Equal To' },
    { value: 'neq', label: 'Not Equal To' },
    { value: 'gt', label: 'Greater Than' },
    { value: 'lt', label: 'Less Than' },
    { value: 'in', label: 'In' },
    { value: 'calculate_gt', label: 'Greater Than' },
    { value: 'calculate_lt', label: 'Less Than' },
    { value: 'calculate_eq', label: 'Equal To' },
    { value: 'calculate_neq', label: 'Not Equal To' },
    { value: 'calculate_btw', label: 'Between' },
    { value: 'calculate_gte', label: 'Greater Than or Equal To' },
    { value: 'calculate_lte', label: 'Less Than or Equal To' },
  ];
  const videoAlgorithmConditionsWhenMaximum = [
    { value: '', label: 'Select a video algorithm Condition' },
    { value: 'between', label: 'Not Between' },
    { value: 'between_proportionality', label: 'Not Between' },
    { value: 'not_between_proportionality', label: 'Between' },
    { value: 'not_between', label: 'Between' },
    { value: 'gte', label: 'Less Than or Equal To' },
    { value: 'lte', label: 'Greater Than or Equal To' },
    { value: 'eq', label: 'Not Equal To' },
    { value: 'neq', label: 'Equal To' },
    { value: 'gt', label: 'Less Than' },
    { value: 'lt', label: 'Greater Than' },
    { value: 'in', label: 'Not In' },
    { value: 'not_in', label: 'In' },
    { value: 'calculate_gt', label: 'Less Than' },
    { value: 'calculate_lt', label: 'Greater Than' },
    { value: 'calculate_eq', label: 'Not Equal To' },
    { value: 'calculate_neq', label: 'Equal To' },
    { value: 'calculate_btw', label: 'Not Between' },
    { value: 'calculate_gte', label: 'Less Than or Equal To' },
    { value: 'calculate_lte', label: 'Greater Than or Equal To' },
  ];
  const videoAlgoCalculated = [
    "calculate_btw",
    "calculate_gt",
    "calculate_lt",
    "calculate_neq",
    "calculate_gte",
    "calculate_lte",
  ]
  const calculateMetric = (videoScoringAlgorithm: any, calculateCondition: any, metricType: "words_per_minute" | "longest_silence") => {
    if (!videoScoringAlgorithm?.[metricType]) return metricType.replace("_", " ");

    const { videoAlgorithmCondition, videoAlgorithmCustomCalc } = videoScoringAlgorithm[metricType];
    if (videoAlgoCalculated.includes(videoAlgorithmCondition) && calculateCondition.includes(videoAlgorithmCondition)) {
      type UpdatedData = {
        video_length: string,
        voice_volume: string,
        loud_noices: string,
        fps: string,
        words_per_minute: string;
        total_words: string;
        longest_silence: string;
      };

      const splitMethod = splitFormulaMap()["split"];

      const replaceMethod = replaceVariablesMap()["replace"];
      let calculation = videoAlgorithmCustomCalc;

      const variable = parseFormula(calculation) as keyof UpdatedData;
      const result = splitMethod(calculation);

      const updatedData: UpdatedData = {
        video_length: "length",
        voice_volume: "voiceVolume",
        loud_noices: "loudNoices",
        fps: "fps",
        words_per_minute: "wordsPerMinute",
        total_words: "totalWords",
        longest_silence: "longestSilence",
      };

      if (data?.videoDetails?.videoDetail?.[updatedData[variable]]) {
        const updatedFormula = replaceMethod(result, data?.videoDetails?.videoDetail[updatedData[variable]]);
        const formulaString = updatedFormula.join(" ");
        const calcValue = eval(formulaString);
        const roundedValue = parseFloat(calcValue.toFixed(2));
        return roundedValue || 0;
      }
    }
    return 0;
  };

  const getCardTable = (title: string, foundItems: any, mappingData: any, key: string, itemsList: string[], baseFormula: any) => {
    return (
      <div className="inner-card">
        <div className="title">{title}</div>
        <Table borderless responsive>
          <thead>
            <tr>
              <td className="text-nowrap" width='33%'>Item</td>
              <td className="text-nowrap text-center" width='33%'>Detected in video</td>
              <td className="text-nowrap text-center" width='33%'>
                {baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}
              </td>
            </tr>
          </thead>
          <tbody>
            {itemsList.map((item, index) => {
              const normalizedItem = item?.toLowerCase()?.trim();
              const foundItem = mappingData?.items?.find((dataItem: any) => {
                const normalizedKeyword = dataItem?.keyword?.toLowerCase()?.trim();
                return normalizedKeyword === normalizedItem;
              });

              const isDetected = title === "Video Objects"
                ? Array.isArray(foundItems) && foundItems.some((obj) =>
                  obj?.object?.toLowerCase()?.trim() === normalizedItem
                )
                : title === "Monitored Objects"
                  ? Array.isArray(foundItems) && foundItems.some((obj) =>
                    obj?.keyword?.toLowerCase()?.trim() === normalizedItem
                  )
                  : false;

              return (
                <tr key={index}>
                  <td>
                    <span className="text-nowrap">{item}</span>
                  </td>
                  <td className="text-center">
                    <span>{isDetected ? "Yes" : "No"}</span>
                  </td>
                  <td className="text-center">
                    <span>
                      {foundItem
                        ? baseFormula === "ZERO"
                          ? foundItem?.rewardPoint
                          : foundItem?.deductedPoint
                        : 0
                      }
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const getConditionLabel = (condition: any, baseFormula: any) => {
    console.log(baseFormula,"afbassfrefrefrebf")
    if (baseFormula === "ZERO") {
      const match = videoAlgorithmConditions?.find(item => item.value === condition);
      return match ? match.label : condition;
        } else if(baseFormula === "MAXIMUM") {
          const match = videoAlgorithmConditionsWhenMaximum?.find(item => item.value === condition);
          return match ? match.label : condition;
    }
    // const match = videoAlgorithmConditions?.find(item => item.value === condition);
    // return match ? match.label : condition;
  };
  const handleEditClick = (title: any, field: any, value: any) => {
    setIsTouched(false);
    setModalData({ title, field, value });
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setModalData({ title: '', field: '', value: 'N/A' });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    if (params?.uuid && modalData?.value != null) {
      const payload = {
        videoUuid: params?.uuid,
        [modalData.field]: modalData.value
      }
      await dispatch(addVideoCustomerIdHandler(payload)).then((res: any) => {
        setShowEditModal(false);
        getVideoDetails();
      }).catch((err: any) => {
        console.log(err, "err");
      })
    } else {
      e.preventDefault();
      setIsTouched(true);
    }
  }
  const DetailEdit = ({ title, field, value, handleEditClick }: any) => {
    return (
      <div className='col-4 title mb-1 text-center'>{title}:  {value || 'N/A'}
        <button className="btn p-0" onClick={() => handleEditClick(title, field, value)}>
          <FontAwesomeIcon icon={faEdit} className="fs-5 px-1" />
        </button>
      </div>
    );
  };
  //functions end here

  //useEffect starts here
  useEffect(() => {
    getVideoDetails();
  }, [])
  //useEffect ends here

  return (
    <div className='new-dasbhoard-wrap'>
      {loader && (
        <div className="loader-overlay">
          <div className="loader-container">
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-12 mb-4'>
          <Card className='dashboard-card'>
            <Card.Body>
              <div className='text-center'>
                <div className='title'>Dealer: {data?.dealerBrandInfo?.dealerName || 'N/A'}</div>
              </div>
              <div className='row'>
                <DetailEdit
                  title="Technician"
                  field="technician"
                  value={data?.videoDetails?.videoDetail?.technician}
                  handleEditClick={handleEditClick}
                />
                <DetailEdit
                  title="Repair Order"
                  field="roNumber"
                  value={data?.videoDetails?.videoDetail?.roNumber}
                  handleEditClick={handleEditClick}
                />
                <DetailEdit
                  title="Customer Id"
                  field="customerId"
                  value={data?.videoDetails?.videoDetail?.customerId}
                  handleEditClick={handleEditClick}
                />
                <DetailEdit
                  title="Partner Id"
                  field="partnerId"
                  value={data?.videoDetails?.videoDetail?.partnerId}
                  handleEditClick={handleEditClick}
                />
                <DetailEdit
                  title="Order Id"
                  field="orderId"
                  value={data?.videoDetails?.videoDetail?.orderId}
                  handleEditClick={handleEditClick}
                />
              </div>
              <div>
                <Button variant="primary" className='view-btn' onClick={() => setShowVideoModal(true)}>
                  CLICK TO VIEW VIDEO
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className="col-lg-4 mb-4">
          <Card className='dashboard-card'>
            <Card.Body>
              <div className='title'>Inspection Score</div>
              <div className='count text-danger'>{data?.videoDetails?.inspectionScore?.toFixed(2) ?? 0?.toFixed(2)}</div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card className='dashboard-card'>
            <Card.Body>
              <div className='title'>Total Score</div>
              <div className='count text-danger'>{((data?.videoDetails?.inspectionScore + data?.videoDetails?.qualityScore) / 2)?.toFixed(2) ?? 0?.toFixed(2)}
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card className='dashboard-card'>
            <Card.Body>
              <div className='title'>Quality Score</div>
              <div className='count text-success'>{data?.videoDetails?.qualityScore?.toFixed(2) ?? 0?.toFixed(2)}</div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='dashboard-table'>
            <Card>
              <Card.Body>
                {getCardTable('Video Objects', data?.videoDetails?.object, videoScoringAlgorithmPoints?.custom_objects, 'object', allVideoObjects, videoScoringAlgorithmPoints?.baseFormulaForKeywords)}
                {getCardTable('Monitored Objects', data?.videoDetails?.monitoredKeywords, videoScoringAlgorithmPoints?.monitored_keywords_found, 'keyword', allMonitoredObjects, videoScoringAlgorithmPoints?.baseFormulaForKeywords)}
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='dashboard-table'>
            <Card>
              <Card.Body className='p-3'>
                <div className="title mt-0">Quality Metrics</div>
                <div className='row'>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {
                            videoScoringAlgorithm?.video_length ?
                              `Video Length (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.video_length?.videoAlgorithmCondition)
                                ? (() => {
                                  const [firstValue, secondValue] = videoScoringAlgorithm?.video_length?.videoAlgorithmParams.split(",").map(Number);
                                  return `${getConditionLabel(videoScoringAlgorithm?.video_length?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue} seconds`;
                                })()
                                : `${getConditionLabel(videoScoringAlgorithm?.video_length?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.video_length?.videoAlgorithmParams} seconds`
                              })`
                              : "Video Length"
                          }
                        </div>
                        <RadialSemiGaugeChart videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula} avgRadialChartData={data?.videoDetails?.videoDetail?.length} title={''} color={'#fd7e14'} isPercent={false} videoScoringAlgorithmCondition={videoScoringAlgorithm?.video_length?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.video_length?.videoAlgorithmParams} minRange={videoScoringAlgorithm?.video_length?.minRange}
                          maxRange={videoScoringAlgorithm?.video_length?.maxRange} />
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {
                            videoScoringAlgorithm?.fps ?
                              `Frames Per Second (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.fps?.videoAlgorithmCondition)
                                ? (() => {
                                  const [firstValue, secondValue] = videoScoringAlgorithm?.fps?.videoAlgorithmParams.split(",").map(Number);
                                  return `${getConditionLabel(videoScoringAlgorithm?.fps?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue}`;
                                })()
                                : `${getConditionLabel(videoScoringAlgorithm?.fps?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.fps?.videoAlgorithmParams}`
                              })`
                              : 'Frames Per Second'
                          }
                        </div>
                        <RadialSemiGaugeChart videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula} avgRadialChartData={data?.videoDetails?.videoDetail?.fps} title={''} color={'#fd7e14'} isPercent={false} videoScoringAlgorithmCondition={videoScoringAlgorithm?.fps?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.fps?.videoAlgorithmParams} minRange={videoScoringAlgorithm?.fps?.minRange}
                          maxRange={videoScoringAlgorithm?.fps?.maxRange} />
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {
                            videoScoringAlgorithm?.voice_volume ?
                              `Average Voice Volume (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.voice_volume?.videoAlgorithmCondition)
                                ? (() => {
                                  const [firstValue, secondValue] = videoScoringAlgorithm?.voice_volume?.videoAlgorithmParams.split(",").map(Number);
                                  return `${getConditionLabel(videoScoringAlgorithm?.voice_volume?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue}db`;
                                })()
                                : `${getConditionLabel(videoScoringAlgorithm?.voice_volume?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.voice_volume?.videoAlgorithmParams}db`
                              })`
                              : "Average Voice Volume"
                          }
                        </div>
                        <RadialSemiGaugeChart videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula} avgRadialChartData={data?.videoDetails?.videoDetail?.voiceVolume} title={''} color={'#fd7e14'} isPercent={false} videoScoringAlgorithmCondition={videoScoringAlgorithm?.voice_volume?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.voice_volume?.videoAlgorithmParams}
                          minRange={videoScoringAlgorithm?.voice_volume?.minRange}
                          maxRange={videoScoringAlgorithm?.voice_volume?.maxRange}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {videoScoringAlgorithm?.words_per_minute ? `Words per Minute (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition)
                            ? (() => {
                              const [firstValue, secondValue] = videoScoringAlgorithm?.words_per_minute?.videoAlgorithmParams.split(",").map(Number);
                              return `${getConditionLabel(videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue}`;
                            })()
                            : `${getConditionLabel(videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.words_per_minute?.videoAlgorithmParams}`
                            })` : "Words per Minute"}
                        </div>
                        <RadialSemiGaugeChart
                          videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula}
                          //avgRadialChartData={data?.videoDetails?.videoDetail?.wordsPerMinute}
                          avgRadialChartData={
                            videoAlgoCalculated.includes(videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition)
                              ? calculateMetric(videoScoringAlgorithm, videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition, "words_per_minute")
                              : data?.videoDetails?.videoDetail?.wordsPerMinute
                          }
                          title={''}
                          color={'#fd7e14'}
                          isPercent={false}
                          videoScoringAlgorithmCondition={videoScoringAlgorithm?.words_per_minute?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.words_per_minute?.videoAlgorithmParams}
                          minRange={videoScoringAlgorithm?.words_per_minute?.minRange}
                          maxRange={videoScoringAlgorithm?.words_per_minute?.maxRange}
                        />
                      </Card.Body>

                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {videoScoringAlgorithm?.loud_noises ? `Loud Noises (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.loud_noises?.videoAlgorithmCondition)
                            ? (() => {
                              const [firstValue, secondValue] = videoScoringAlgorithm?.loud_noises?.videoAlgorithmParams.split(",").map(Number);
                              return `${getConditionLabel(videoScoringAlgorithm?.loud_noises?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue}db`;
                            })()
                            : `${getConditionLabel(videoScoringAlgorithm?.loud_noises?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.loud_noises?.videoAlgorithmParams}db`
                            })` : "Loud Noises"}
                        </div>
                        <RadialSemiGaugeChart
                          videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula}
                          avgRadialChartData={data?.videoDetails?.videoDetail?.loudVoice}
                          title={''}
                          color={'#fd7e14'}
                          isPercent={false}
                          videoScoringAlgorithmCondition={videoScoringAlgorithm?.loud_noises?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.loud_noises?.videoAlgorithmParams} minRange={videoScoringAlgorithm?.loud_noises?.minRange}
                          maxRange={videoScoringAlgorithm?.loud_noises?.maxRange}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <div className="sub-title">
                          {videoScoringAlgorithm?.longest_silence ? `Longest Silence (Acceptable Range ${['calculate_btw', 'between', 'not_between'].includes(videoScoringAlgorithm?.longest_silence?.videoAlgorithmCondition)
                            ? (() => {
                              const [firstValue, secondValue] = videoScoringAlgorithm?.longest_silence
                                ?.videoAlgorithmParams.split(",").map(Number);
                              return `${getConditionLabel(videoScoringAlgorithm?.longest_silence
                                ?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${firstValue} to ${secondValue}`;
                            })()
                            : `${getConditionLabel(videoScoringAlgorithm?.longest_silence
                              ?.videoAlgorithmCondition, videoScoringAlgorithmPoints?.baseFormula)} ${videoScoringAlgorithm?.longest_silence
                                ?.videoAlgorithmParams}`
                            })` : "Longest Silence"}
                        </div>
                        <RadialSemiGaugeChart
                          videoScoringFormula={videoScoringAlgorithmPoints?.baseFormula}
                          // avgRadialChartData={data?.videoDetails?.videoDetail?.longestSilence}
                          avgRadialChartData={
                            videoAlgoCalculated.includes(videoScoringAlgorithm?.longest_silence?.videoAlgorithmCondition)
                              ? calculateMetric(videoScoringAlgorithm, videoScoringAlgorithm?.longest_silence?.videoAlgorithmCondition, "longest_silence")
                              : data?.videoDetails?.videoDetail?.longestSilence
                          }
                          title={''}
                          color={'#fd7e14'}
                          isPercent={false}
                          videoScoringAlgorithmCondition={videoScoringAlgorithm?.longest_silence
                            ?.videoAlgorithmCondition} videoAlgorithmParams={videoScoringAlgorithm?.longest_silence
                              ?.videoAlgorithmParams}
                          minRange={videoScoringAlgorithm?.longest_silence
                            ?.minRange}
                          maxRange={videoScoringAlgorithm?.longest_silence
                            ?.maxRange}
                        />
                      </Card.Body>

                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <Table bordered responsive className='mb-4'>
                          <thead>
                            <tr>
                              <td className='text-dark fw-bold' align='center' width={'50%'}>Profanity</td>
                              <td className='text-dark fw-bold' align='center'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td align='center'>
                                <span className='count'>{data?.videoDetails?.videoDetail?.profanity ? 'Yes' : 'No'}</span>
                              </td>
                              <td align='center'>
                                <span className='count'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? videoScoringAlgorithmPoints?.profanity?.totalRewardedPoints ?? 0 : videoScoringAlgorithmPoints?.profanity?.totalDeductedPoint ?? 0}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table bordered responsive className='mb-4'>
                          <thead>
                            <tr>
                              <td className='text-dark fw-bold' align='center' width={'50%'}>Video</td>
                              <td className='text-dark fw-bold' align='center'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td align='center'>
                                <span className='count'>{data?.videoDetails?.videoDetail?.videoDisplay ? 'Yes' : 'No'}</span>
                              </td>
                              <td align='center'>
                                <span className='count'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? videoScoringAlgorithmPoints?.video?.totalRewardedPoints ?? 0 : videoScoringAlgorithmPoints?.video?.totalDeductedPoint ?? 0}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-6 mb-4'>
                    <Card>
                      <Card.Body className='p-3'>
                        <Table bordered responsive className='mb-4'>
                          <thead>
                            <tr>
                              <td className='text-dark fw-bold' align='center' width={'50%'}>Language(s)</td>
                              <td className='text-dark fw-bold' align='center'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td align='center'>
                                <span className='count'>{data?.videoDetails?.videoDetail?.lang ?? 'No'}</span>
                              </td>
                              <td align='center'>
                                <span className='count'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? videoScoringAlgorithmPoints?.languages?.totalRewardedPoints ?? 0 : videoScoringAlgorithmPoints?.languages?.totalDeductedPoint ?? 0}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table bordered responsive className='mb-4'>
                          <thead>
                            <tr>
                              <td className='text-dark fw-bold' align='center' width={'50%'}>Is Audio Muffled?</td>
                              <td className='text-dark fw-bold' align='center'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td align='center'>
                                <span className='count'>{data?.videoDetails?.videoDetail?.audioMuffled ? 'Yes' : 'No'}</span>
                              </td>
                              <td align='center'>
                                <span className='count'>{videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? videoScoringAlgorithmPoints?.audio_muffled?.totalRewardedPoints ?? 0 : videoScoringAlgorithmPoints?.audio_muffled?.totalDeductedPoint ?? 0}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-12 mb-4'>
                    <Card className='quality-summary-card'>
                      <Card.Body className='p-3'>
                        <div className="sub-title">Quality Scoring Summary</div>
                        <Table borderless responsive>
                          <tbody>
                            <tr>
                              <td className='me-5'>
                                <span>Video Length {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.video_length?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.video_length?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Frame Per Second {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.fps?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.fps?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Words Per Minute {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.words_per_minute?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.words_per_minute?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Average Volume {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.voice_volume?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.voice_volume?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Loud Noises {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.loud_noises?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.loud_noises?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Longest Silence {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.longest_silence?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.longest_silence?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Muffled Audio {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.audio_muffled?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.audio_muffled?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <span>Profanity {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.profanity?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.profanity?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <span>Language {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.languages?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.languages?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <span>Video {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.video?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.video?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Dealer Name {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.dealer_name?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.dealer_name?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Car Model Name {videoScoringAlgorithmPoints?.baseFormula === "ZERO" ? "Rewarded Points" : "Points Deducted"}</span>
                              </td>
                              <td align='right'>
                                <span>
                                  {videoScoringAlgorithmPoints?.baseFormula === "ZERO"
                                    ? Math.round((videoScoringAlgorithmPoints?.car_model?.totalRewardedPoints ?? 0) * 100) / 100
                                    : Math.round((videoScoringAlgorithmPoints?.car_model?.totalDeductedPoint ?? 0) * 100) / 100}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {showVideoModal &&
        <VideoPlayerModal
          isOpen={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          video={data?.videoDetails}
          isProcessedLink={false}
          videoId={params.uuid}
        />}
      {showEditModal && (
        <Modal show={showEditModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit {modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="fieldValue" className="form-label">
                  {modalData.title}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fieldValue"
                  defaultValue={modalData.value}
                  onChange={(e: any) => setModalData({ ...modalData, value: e.target.value })}
                />
                {(modalData?.value?.trim() == '' || isTouched) && <span className="text-danger">{modalData.title} is required</span>}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" style={{ padding: '7px 20px' }} onClick={(e) => handleSubmit(e)} disabled={modalData?.value == undefined || modalData?.value?.trim() == ''}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default VideoDetail